// import { cloneDeep } from 'lodash';
import { CMABreakcrumb } from 'root/components';
import { stripHtml } from 'root/helpers';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import 'vue-googlemaps/dist/vue-googlemaps.css';
import { mapState } from 'vuex';
// import { ArticlesType } from '../Home/Store/mutations';
import { ActionTypeHome } from '../Home/Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'cma-breakcumb': CMABreakcrumb
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.home.articles,
      loading: (state: IState) => state.home.articlesLoading,
    })
  }
})

export class Blog extends Vue {
  public data: any[];
  public loading: boolean;

  private isMore: boolean = true;
  private pagination: any = {
    page: 1,
    // per_page: 1
  };

  public get type() {
    return this.$route.params.type;
  }

  public getpostExcerpt(excerpt: string, content: string) {
    if (excerpt) {
      return excerpt;
    }
    const text = stripHtml(content);
    let textArr = text.split(' ');
    textArr.length = 55;
    textArr = textArr.filter((e) => e);

    return textArr.length === 55 ? `${textArr.join(' ')}...` : textArr.join(' ');
  }

  public onTouch(e: Element) {
    const id = e.getAttribute('data-id');
    this.$router.push(`/article/post/${id}`);
  }

  public handleWindowScroll() {
    const bottomOfWindow =
      (window.scrollY + window.innerHeight) >= document.body.scrollHeight - 500;

    if (bottomOfWindow &&
        (!this.loading && this.isMore)) {

      this.$store.dispatch(ActionTypeHome.GetArticles, {
        type: this.type,
        pagination: this.pagination,
        isList: true,
        onSuccess: (data: any[]) => {
          if (data.length === 0) {
            this.isMore = false;

            return;
          }
          this.pagination = {
            ...this.pagination,
            page: this.pagination.page + 1
          };
        }
      });
    }

    return;
  }

  protected mounted() {
    this.$nextTick(async () => {
      this.$store.dispatch(ActionTypeHome.GetArticles, {
        type: this.type,
        pagination: this.pagination,
        onSuccess: (data: any[]) => {
          if (data.length === 0) {
            this.isMore = false;

            return;
          }
          this.pagination = {
            ...this.pagination,
            page: this.pagination.page + 1
          };
        }
      });
      this.$store.commit(MutationType.BreakcumbChange, [{
        label: this.$t('home'),
        url: '/'
      }, {
        label: this.$t(this.type),
        url: '',
        currently: true
      }]);
      window.addEventListener('scroll', this.handleWindowScroll, false);
    });
  }
  protected beforeDestroy() {
    window.removeEventListener('scroll', this.handleWindowScroll, false);
  }
}
