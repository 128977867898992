import { cloneDeep, isNil } from 'lodash';
import { GameType, IAssignmentGame, MethodType } from 'root/models';
import { MutationTypeAssignment } from 'root/pages/Assignment/Store/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { GamePlay } from '../../Components/GamePlay';

@Component({
  template: require('./view.html'),
  components: {
    'game-play': GamePlay
  },
  computed: {
    ...mapState({
      assignmentGame: (state: IState) => state.assignment.game
    })
  }
})
export class GameExtraBonus extends Vue {
  public methodType: MethodType = MethodType.Empty;
  public gameType = GameType.ExtraBonus;
  public methodTypeAA = MethodType.AbacusArithmetic;
  public methodTypeMA = MethodType.MentalArithmetic;
  public methodTypeAM = MethodType.AuralMental;
  public methodFlashBonus = MethodType.FlashBonus;
  public assignmentGame: IAssignmentGame;

  public clickBtnBack() {
    this.$store.commit(MutationTypeAssignment.ResetAssignmentGame);
    this.$router.push('/game');
  }
  public click(model: MethodType) {
    this.methodType = model;
  }
  public handleEndGame() {
    this.$router.push('/game');
    this.$store.commit(MutationTypeAssignment.ResetAssignmentGame);
  }
  public closeGame() {
    this.$router.push('/game');
    this.$store.commit(MutationTypeAssignment.ResetAssignmentGame);
  }

  public mounted() {
    this.$nextTick(() => {
      const assignmentGame = cloneDeep(this.assignmentGame);

      if (isNil(assignmentGame.id)) {
        this.gameType = GameType.ExtraBonus;

        return;
      }

      this.click(assignmentGame.type);
      this.gameType = GameType.Assignment;
    });
  }

  public beforeDestroy() {
    this.$store.commit(MutationTypeAssignment.ResetAssignmentGame);
  }

}
