import { IArticles } from 'root/pages/Home/Store/mutations';
import { MutationTypeArticle } from './types';

export interface IArticleState {
  detail: IArticles;
  detailLoading: boolean;
}

export const defaultState: IArticleState = {
  detail: {
    ID: null,
    author: {
      ID: null,
      avatar: '',
      display_name: '',
      post_modified: '',
      user_email: ''
    },
    post_content: '',
    post_title: ''
  },
  detailLoading: false
};

export const mutations = {
  [MutationTypeArticle.SetArticleDetail](state: IArticleState, data: IArticles) {
    state.detail = data;
  },
  [MutationTypeArticle.SetArticleDetailLoading](state: IArticleState) {
    state.detailLoading = true;
  },
  [MutationTypeArticle.SetArticleDetailLoaded](state: IArticleState) {
    state.detailLoading = false;
  }
};
