export interface INotificationData {
  id: number;
  title: string;
  type: NotificationType;
  referenceId: number;
}
export interface INotification {
  id: string;
  heading?: string;
  title: string;
  type: NotificationType;
  referenceId: number;
  content: string;
  icon?: string;
  data?: INotificationData;
}
export enum NotificationType {
  Game = 'game',
  Announcement = 'announcement',
  NewAssignment = 'new_assignment',
  CompletedAssignment = 'completed_assignment',
  RemindAssignment = 'remind_assignment',
  Redemption = 'redemption',
  RedemptionProcessing = 'redemption_processing',
  RedemptionReady = 'redemption_ready',
  RedemptionCompleted = 'redemption_completed',
  redemptionRejected = 'redemption_rejected'
}

export const notificationDefault = (): INotification => {
  return {
    id: null,
    content: '',
    icon: '',
    referenceId: null,
    title: '',
    type: null
  };
};

export interface INotificationFilter {
  page?: number;
  nextPageToken?: string;
}
