import { IProduct, MethodType } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IProductHttpService
  extends ICRUDHttpService<IProduct> {
  getProductList(filter: any): Promise<any>;
}

export function ProductHttpService(options: IHttpServiceClientOptions): IProductHttpService {
  const basePath = '/products';
  const baseUrl = options.httpService.getUrl(basePath);

  function getProductList(filter: any = {
    method: MethodType.Empty,
  }) {
    return options.httpService.get(baseUrl, {
      params: filter
    });
  }

  return {
    ...CRUDHttpService<IProduct>({
      ...options,
      basePath
    }),
    getProductList,
  };
}
