import { HttpService, IHttpServiceOptions } from '../Core';

import { AssignmentHttpService, IAssignmentHttpService } from './Assignment';
import { BoardHttpService, IBoardHttpService } from './Board';
import { GameHttpService, IGameHttpService } from './Game';
import { GlobalHttpService, IGlobalHttpService } from './Global';
import { INotificationHttpService, NotificationHttpService } from './Notification';
import { IProductHttpService, ProductHttpService } from './Product';
import { IProfileHttpService, ProfileHttpService } from './Profile';
import { IRedeemedHttpService, RedeemedHttpService } from './Redeemed';
import { ISubscriptionHttpService, SubscriptionHttpService } from './Subscription';
import { IUserHttpService, UserHttpService } from './User';
import { IWordpressHttpService, WordpressHttpService } from './Wordpress';

export interface ICMAHttpClient {
  global: IGlobalHttpService;
  user: IUserHttpService;
  profile: IProfileHttpService;
  wordpress: IWordpressHttpService;
  games: IGameHttpService;
  assignment: IAssignmentHttpService;
  redeemed: IRedeemedHttpService;
  board: IBoardHttpService;
  product: IProductHttpService;
  notification: INotificationHttpService;
  subscription: ISubscriptionHttpService;
}

export function CMAHttpClient(options: IHttpServiceOptions): ICMAHttpClient {

  const httpService = HttpService(options);

  return {
    global: GlobalHttpService({ httpService }),
    user: UserHttpService({ httpService }),
    profile: ProfileHttpService({ httpService }),
    wordpress: WordpressHttpService({ httpService }),
    games: GameHttpService({ httpService }),
    assignment: AssignmentHttpService({ httpService }),
    redeemed: RedeemedHttpService({ httpService }),
    board: BoardHttpService({ httpService }),
    product: ProductHttpService({ httpService }),
    notification: NotificationHttpService({ httpService }),
    subscription: SubscriptionHttpService({ httpService })
  };
}
