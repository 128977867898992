import { cloneDeep, omit } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';

// import { stripObject } from '@hgiasac/helper';
import { stripObject } from '@hgiasac/helper';
import { AuthenticationDetails } from 'amazon-cognito-identity-js';
import { Form } from 'element-ui';
import * as moment from 'moment';
import { ruleCompare, ruleEmail, ruleMinLength, ruleRequired } from 'root/helpers';
import { userRegisterFormDefault, Gender, IListCountry, IUserRegisterForm, RouterDictionaryName } from 'root/models';
import { CMAAuth } from 'root/services';
import { ActionType, IState, MutationType } from 'root/store';
import { Route } from 'vue-router';
import { mapState } from 'vuex';
import { ActionTypeProfile } from '../Profile/Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    isDialog: Boolean
  },
  computed: {
    ...mapState({
      listCountry: (state: IState) => state.global.globalConfig.listCountry,
      // listCentre: (state: IState) => state.global.globalConfig.listCentre
    }),
    phoneCodeOptions() {
      const listCountry = cloneDeep(this.listCountry);

      return listCountry.map((e: IListCountry) => {
        return {
          value: `+${e.phoneCode}`,
          label: `+${e.phoneCode}`
        };
      });
    }
  },
  beforeRouteLeave: async (to: Route, _, next) => {
    const isRequired = !to.meta.isGuest
      && (to.name !== RouterDictionaryName.SignUp.toString()
          && to.name !== RouterDictionaryName.ForgotPassword.toString()),
      isAuth = await CMAAuth.currentUser();

    if (isRequired && !isAuth) {
      return next('/register');
    }

    return next();
  }
})

export class Register extends Vue {
  public $refs: {
    form: Form
  };
  public loading: boolean = false;
  public form: IUserRegisterForm = userRegisterFormDefault();
  public valid: boolean = false;
  public agreed: boolean = false;

  public get rules() {
    return {
      required: ruleRequired(),
      email: ruleEmail(),
      min: ruleMinLength(8),
      compare: ruleCompare(this.form.password)
    };
  }
  public genderOptions: any = [
    {
      value: Gender.Male,
      label: Gender.Male
    },
    {
      value: Gender.Female,
      label: Gender.Female
    }
  ];
  public pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    }
  };

  public handleKeydownPostalCode(e: any) {
    if (e.which === 32) {
      return false;
    }
    setTimeout(() => {
      const _value = e.target.value.replace(/[a-zA-Z]/g, '');
      this.form.postalCode = _value;
    }, 0);
  }

  public submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        this.loading = true;
        let form = cloneDeep(this.form);
        if (!form.phone.number) {
          form = omit(form, 'phone');
        }
        if (form.birthday) {
          form.birthday = <any> moment(form.birthday).format('YYYY-MM-DD');
        }
        this.$store.commit(MutationType.SetRegisting);
        try {
          const userName = `${form.email.replace(/@.*?$/g, '')}_${new Date().getTime()}`;
          const dataSignUp = await CMAAuth.signUpWithEmailAndPassword({
            userName,
            email: form.email,
            password: form.password
          });
          const userData = {
            Username: form.email,
            Password: form.password
          };
          const userDetail = new AuthenticationDetails(userData);
          dataSignUp.user.authenticateUser(userDetail, {
            onSuccess: () => {
              this.loading = false;
              const _form = {
                ...stripObject(omit(form, ['password', 'retypePassword', 'email'])),
                centre: 'public_user'
              };

              this.$store.dispatch(ActionTypeProfile.Register, _form)
                .then((user) => {
                  this.$store.commit(MutationType.SetRegisted);
                  this.$store.commit(MutationType.SetFirstLogin);
                  this.$store.dispatch(ActionType.ChangeStateAuth, {
                    user,
                    router: this.$router,
                    cb: () => {
                      if (this.$props.isDialog) {
                        // this.$emit('loginSuccess');
                        location.reload();
                      }
                    }
                  });
                })
                .catch((error) => {
                  dataSignUp.user.deleteUser(() => {
                    this.$store.dispatch(ActionType.CatchException, error);
                  });
                });
            },
            onFailure: (error) => {
              this.loading = false;
              this.$store.dispatch(ActionType.CatchException, error);
            }
          });
        } catch (error) {
          this.loading = false;
          this.$store.dispatch(ActionType.CatchException, error);
        }
      }

      return;
    });
  }

  public clickLogin(e: Event) {
    if (this.$props.isDialog) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('clickLogin');

      return;
    }

    this.$router.push('/login');
  }

  public mounted() {
    this.$nextTick(async () => {
      // const user = await CMAAuth.currentUser();

      // console.log('currentUser', user);
    });
  }
}
