import { INotification, INotificationFilter } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface INotificationHttpService
  extends ICRUDHttpService<INotification> {
  getNotificationList(filter: INotificationFilter): Promise<any>;
}

export function NotificationHttpService(options: IHttpServiceClientOptions): INotificationHttpService {
  const basePath = '/notifications';
  const baseUrl = options.httpService.getUrl(basePath);

  function getNotificationList(filter: INotificationFilter = {
    nextPageToken: null
  }) {
    return options.httpService.get(baseUrl, {
      params: filter
    });
  }

  return {
    ...CRUDHttpService<INotification>({
      ...options,
      basePath
    }),
    getNotificationList,
  };
}
