import { Form } from 'element-ui';
import { cloneDeep, find, omit, pick } from 'lodash';
import * as moment from 'moment';
import { CMAAvatar, CMABreakcrumb } from 'root/components';
import { ruleCompare, ruleEmail, ruleMinLength, ruleNumber, ruleRequired } from 'root/helpers';
import { phoneCodeDefault, userRegisterFormDefault, IListCountry, IUser, IUserRegisterForm } from 'root/models';
import { uploadToAWS } from 'root/services';
import { ActionType, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeProfile, MutationTypeProfile } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-breakcumb': CMABreakcrumb,
    'cma-avatar': CMAAvatar
  },
  computed: {
    ...mapGetters(['genderOptions']),
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      loading: (state: IState) => state.profile.loading,
      listCountry: (state: IState) => state.global.globalConfig.listCountry,
      listCentre: (state: IState) => state.global.globalConfig.listCentre
    }),
    phoneCodeOptions() {
      const listCountry = cloneDeep(this.listCountry);

      return listCountry.map((e: IListCountry) => {
        return {
          value: `+${e.phoneCode}`,
          label: `+${e.phoneCode}`
        };
      });
    }
  }
})

export class ProfileEdit extends Vue {
  public $refs: {
    form: Form
  };
  public listCentre: any[];
  public authUser: IUser;
  public file: any = null;

  public form: IUserRegisterForm = userRegisterFormDefault();
  public pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    }
  };

  public get rules() {
    return {
      required: ruleRequired(),
      email: ruleEmail(),
      min: ruleMinLength(8),
      compare: ruleCompare(this.form.password),
      number: ruleNumber()
    };
  }

  public handlePreviewAvatar(file: any) {
    this.form.avatar = window.URL.createObjectURL(file.raw);
    this.file = file;
  }

  public handleKeydownPostalCode(e: any) {
    if (e.which === 32) {
      return false;
    }
    setTimeout(() => {
      const _value = e.target.value.replace(/[-.]/g, '');
      this.form.postalCode = _value;
    }, 0);
  }

  public submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        const file = cloneDeep(this.file);
        let avatar: string = this.form.avatar;
        let form = cloneDeep(this.form);

        if (file) {
          this.$store.commit(MutationTypeProfile.ProfileLoading);
          avatar = await uploadToAWS('avatar', file.raw,
            {maxHeight: 500, maxWidth: 5000, quality: .5});
          form.avatar = avatar;
        } else {
          form = {
            ...omit(form, 'avatar'),
            postalCode: form.postalCode ? form.postalCode : null
          };
        }
        form = <any> omit(cloneDeep(form), 'id');
        form.birthday = <any> moment(form.birthday).format('YYYY-MM-DD');
        if (!form.address) {
          form.address = '';
        }

        this.$store.dispatch(ActionTypeProfile.ProfileUpdate, form);
      }

      return;
    });
  }

  public cancel() {
    this.$router.push('/account');
  }

  public changePhoneCode(v) {
    this.form.phone.code = v;
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.BreakcumbChange, [{
        label: this.$t('account'),
        url: '/account'
      }, {
        label: this.$t('profile'),
        url: '',
        currently: true
      }]);

      this.$store.dispatch(ActionType.ReloadProfile, {
        onSuccess: () => {
          let authUser = cloneDeep(this.authUser);
          const listCentre = cloneDeep(this.listCentre),
            centre = find(listCentre, (e) => e.name === authUser.centre);

          authUser = <any> pick(authUser, ['fullName', 'gender', 'birthday',
            'avatar', 'address', 'postalCode', 'phone']);

          this.form = <any> {
            ...authUser,
            centre: centre ? centre.value : '',
            phone: authUser.phone ? authUser.phone : {
              code: phoneCodeDefault
            }
          };
        }
      });
    });
  }
}
