import { CMABreakcrumb } from 'root/components';
import { cmaFormatDateTime } from 'root/helpers';
import { INotification } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeNotification, GetterTypeNotification, MutationTypeNotification } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-breakcumb': CMABreakcrumb
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.notification.data
    })
  },
  watch: {
    notifyId(newValue) {
      if (newValue) {
        this.$store.commit(MutationTypeNotification.ClearNotificationList);
        this.$store.dispatch(ActionTypeNotification.NotificationFindById, {id: newValue});
      }
    }
  }
})

export class NotificationView extends Vue {
  public cmaFormatDateTime = cmaFormatDateTime;
  public get notifyId() {
    return this.$route.params.notifyId;
  }
  public get notification(): INotification {
    return this.$store.getters[GetterTypeNotification.GetNotificationById](this.notifyId);
  }
  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.BreakcumbChange, [{
        label: this.$t('acount'),
        url: '/account'
      }, {
        label: this.$t('notification'),
        url: '/notifications',
      }, {
        label: this.$t('announcement'),
        url: '',
        currently: true
      }]);
      this.$store.commit(MutationTypeNotification.ClearNotificationList);
      this.$store.dispatch(ActionTypeNotification.NotificationFindById, {id: this.notifyId});
    });
  }
}
