import { GameType, MethodType } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { GamePlay } from '../../Components/GamePlay';

@Component({
  template: require('./view.html'),
  components: {
    'game-play': GamePlay
  }
})
export class GameDailyBonus extends Vue {
  public methodType: MethodType = MethodType.DailyBonus;
  public gameType = GameType.DailyBonus;

  public handleEndGame() {
    this.$router.push('/game');
  }
  public closeGame() {
    this.$router.push('/game');
  }

}
