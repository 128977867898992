export enum MutationTypeAssignment {
  AssignmentLoading = 'assignmentLoading',
  AssignmentLoaded = 'assignmentLoaded',
  AssignmentLoadingMore = 'assignmentLoadingMore',
  AssignmentLoadedMore = 'assignmentLoadedMore',
  SetAssignmentList = 'setAssignmentList',
  clearAssignmentList = 'clearAssignmentList',
  UpdateAssignmentList = 'updateAssignmentList',
  SetAssignmentGame = 'SetAssignmentGame',
  ResetAssignmentGame = 'ResetAssignmentGame'
}

export enum ActionTypeAssignment {
  UpdateAssignment = 'updateAssignment',
  LoadingAssignment = 'loadingAssignment',
  AssignmentFilterNoCache = 'assignmentFilterNoCache',
  FilterAssignment = 'FilterAssignment',
  GetAssignmentGame = 'GetAssignmentGame',
  AssignmentFindById = 'assignmentFindById'
}

export enum GetterTypeAssignment {
  GetAssignmentById = 'getAssignmentById'
}
