import { FooterGuest } from 'root/components/FooterGuest';
import Vue from 'vue';
import Component from 'vue-class-component';
import { HomeAbout } from './Components/About';
import { HomeArticle } from './Components/HomeArticle';
import { HomeQuote } from './Components/HomeQuote';
import './home.scss';

@Component({
  template: require('./view.html'),
  components: {
    'home-about': HomeAbout,
    'home-quote': HomeQuote,
    'home-article': HomeArticle,
    'footer-guest': FooterGuest
  }
})

export class HomeContainer extends Vue {

}
