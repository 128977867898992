import { IAudioConfigGlobal, IBreakcumb, IFlashGameConfigGlobal,
  IGameStarsConfigGlobal, IListCountry, IUser } from 'root/models';
import { IArticleState } from 'root/pages/Article/Store';
import { IAssignmentState } from 'root/pages/Assignment/Store';
import { IBoardState } from 'root/pages/Board/Store';
import { IContactState } from 'root/pages/ContactUs/Store';
import { IGameState } from 'root/pages/Game/Store';
import { IHomeState } from 'root/pages/Home/Store';
import { INotificationState } from 'root/pages/Notification/Store';
import { IProductState } from 'root/pages/Product/Store/mutations';
import { IProfileState } from 'root/pages/Profile/Store';
import { IRedeemedState } from 'root/pages/Redeemed/Store/mutations';
import { ISubscriptionState } from 'root/pages/Subcription/Store';

// import { User } from 'firebase';
// import { IProfile } from 'root/models';
// tslint:disable max-file-line-count

// define interface
export interface IGlobalState {
  drawer: boolean;
  isLoading: boolean;
  lastRoutePath: string;
  currentLocale: string;
  authUser: IUser;
  topAlert: IAlertState;
  signInErrorMessage: string;
  layout: Layout;
  globalConfig: IGlobalConfiguration;
  isRegisting: boolean;
  breakcumb: IBreakcumb[];
  firstLogin: boolean;
}

export enum TypeAlert {
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  Error = 'error'
}

export interface IAlertState {
  opened: boolean;
  message: string;
  type: TypeAlert;
  icon: string;
}

export interface IRouteState {
  path: string;
  params: object;
  query: object;
}

export interface IPaginationState {
  page?: number;
  size?: number;
}

export interface IState {
  global: IGlobalState;
  route: IRouteState;
  home: IHomeState;
  profile: IProfileState;
  article: IArticleState;
  game: IGameState;
  redemption: IRedeemedState;
  product: IProductState;
  assignment: IAssignmentState;
  board: IBoardState;
  contact: IContactState;
  notification: INotificationState;
  subscription: ISubscriptionState;
}

export interface IFormState<T> {
  payload: T;
  valid: boolean;
  errorMessage: string;
}

export interface IBreadcrumbItem {
  text: string;
  link?: string;
}

export interface IGlobalConfiguration {
  game: IGameGlobalConfig;
  listCentre: any[];
  listCountry: IListCountry[];
}

export interface IGameGlobalConfig {
  flash: IFlashGameConfigGlobal;
  stars: IGameStarsConfigGlobal;
  audio: IAudioConfigGlobal;
}

export enum Layout {
  Home = 'home-layout',
  Login = 'login-layout'
}

export interface IAvatarDefault {
  user: string;
  image: string;
  video: string;
  project: string;
  plan: string;
  message: string;
}

export interface ICustomLazy {
  src: string;
  loading?: string;
  error?: string;
}

// function
export function PaginationState(params?: IPaginationState): IPaginationState {
  return {
    page: 1,
    size: 20,
    ...params,
  };
}

// define type screen

export enum ViewPortSize {
  Mobile = 768,
  Tablet = 1023,
  Desktop = 1215,
  Widescreen = 1407,
}

// define type mutation and action

export enum MutationType {
  // global
  SetCurrentLocale = 'setCurrentLocale',
  ClosePlashScreen = 'closePlashScreen',
  WindowResized = 'windowResized',
  OpenPlashScreen = 'openPlashScreen',
  SetLayout = 'setLayout',
  SetGlobalConfig = 'setGlobalConfig',
  SetMenuCollapse = 'setMenuCollapse',
  OpenTopAlert = 'openTopAlert',
  CloseTopAlert = 'closeTopAlert',
  // users
  SetListUsers = 'setListUsers',
  CreateUser = 'createUser',
  UpdateUser = 'updateUser',
  // login
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  SetProfile = 'setProfile',
  SetFirstLogin = 'SetFirstLogin',
  ClearFirstLogin = 'ClearFirstLogin',

  // profile
  LoadingProfile = 'loadingProfile',
  ClearStore = 'clearStore',
  SetRegisting = 'SetRegisting',
  SetRegisted = 'SetRegisted',

  BreakcumbChange = 'BreakcumbChange'
}

export enum ActionType {
  // global
  InitializeSettings = 'initializeSettings',
  CatchException = 'catchException',
  SetLayout = 'setLayout',
  SetGlobalConfig = 'setGlobalConfig',
  GoBack = 'goBack',
  GoToRoute = 'goToRoute',
  CollapseMenu = 'collapseMenu',
  OpenTopAlert = 'openTopAlert',
  CloseTopAlert = 'closeTopAlert',

  // navigator
  DirectNavigator = 'directNavigator',

  // login
  ChangeStateAuth = 'changeStateAuth',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',

  // profile
  UpdateProfile = 'updateProfile',
  LoadingProfile = 'loadingProfile',
  ReloadProfile = 'reloadProfile',

  // user-notification-setting
  UserNotificationSettingUpdate = 'userNotificationSettingUpdate'
}

export enum GetterTypeAuthUser {
  UserCurrentLevel = 'userCurrentLevel'
}
export enum GetterTypeGlobal {
  GetCentreValue = 'getCentreValue'
}
