import { CMAAvatar } from 'root/components';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-avatar': CMAAvatar
  },
  computed: {
    ...mapState({
      currentUser: (state: IState) => state.board.user.currentUser,
    })
  }
})

export class MyPosition extends Vue {}
