export enum ActionTypeContact {
  GetCentre = 'GetCentre'
}

export enum MutationTypeContact {
  SetCentreList = 'SetCentreList',
  contactLoading = 'contactLoading',
  contactLoaded = 'contactLoaded',
  contactLoadingMore = 'contactLoadingMore',
  contactLoadedMore = 'contactLoadedMore'
}
