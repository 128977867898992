import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

// const _moment = momentDurationFormatSetup(moment);
momentDurationFormatSetup(moment);
// import { i18n } from 'root/locales';
// import { IUser } from 'root/models';

// export function fullNameUser(model: IUser) {
//   return `${model.firstName} ${model.lastName}`;
// }

export function formatterTimeBySeconds(seconds: number = 0): string {
  const format = seconds >= 60 ? 'hh:mm:ss' : 'mm:ss',
    isTrim = seconds >= 60 ? 'large' : false;

  return moment.duration(seconds, 'seconds').format(format, {trim: isTrim});
}
export function cmaFormatDateTime(time: Date, locale: string = 'en') {
  if (!time) {
    return '';
  }

  return moment(time).locale(locale).format('DD/MM/YYYY');
}
export function cmaFormatDateFullTime(time: Date, locale: string = 'en') {
  if (!time) {
    return '';
  }

  return moment(time).locale(locale).format('HH:mm DD/MM/YYYY');
}

export function formatTimeDuration(time: Date, locale?: string) {
  if (!time) {
    return '';
  }
  moment.relativeTimeRounding(Math.floor);
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past:   '%s ago',
      s  : 'Just now',
      ss : '%d seconds',
      m:  '1 minute ago',
      mm: '%d minutes ago',
      h: '1 hour ago',
      hh: '%d hours ago',
      d:  '1 day ago',
      // dd: '%d days ago',
      dd: (_number: number) => {
        if (_number < 7) {
          return _number + ' days ago'; // Moment uses "d" when it's just 1 day.
        }
        const weeks = Math.floor(_number / 7);

        return `${weeks} ${weeks > 1 ? 'weeks' : 'week'} ago`;
      },
      M:  '1 month ago',
      MM: '%d months ago',
      y:  '1 year ago',
      yy: '%d years ago'
    }
  });

  moment.relativeTimeThreshold('s', 60);
  moment.relativeTimeThreshold('m', 60);
  moment.relativeTimeThreshold('h', 24);
  moment.relativeTimeThreshold('d', 30);

  return moment(time).locale(locale || 'us').fromNow(true);
}

export function stripHtml(html: string) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;

  return tmp.textContent || tmp.innerText || '';
}
