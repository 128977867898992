import { stripObject } from '@hgiasac/helper';
import { Form, Input } from 'element-ui';
import { cloneDeep, isNull, isNumber, omit } from 'lodash';
import { countLine, countOperator, formatterTimeBySeconds,
  formatCurrency, getStar, multiLineText, textToSpeech } from 'root/helpers';
import { gameConfigLocal, gameHistoryForm, showAnswerTimeDefault, BonusTypes,
  GameType, IAssignmentGame, IAudioConfigGlobal, IAuralConfig,
  IFlashConfig, IFlashGameConfigGlobal, IFlashSmarterGameConfig, IGameHistory,
  IGameResultInfo, IProgressive, IProgressiveConfig,
  IProgressiveForm, ISpeedConfig, IUser, MethodType } from 'root/models';
import { ActionTypeAssignment } from 'root/pages/Assignment/Store/types';
import { ActionType, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Scrolly, ScrollyBar, ScrollyViewport } from 'vue-scrolly';
import { mapState } from 'vuex';
import { ActionTypeGame, MutationTypeGame } from '../../Store/types';
import { GameConfig } from '../GameConfig';
import { GameResultInfo } from '../GameResultInfo';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    Scrolly,
    ScrollyViewport,
    ScrollyBar,
    'game-config': GameConfig,
    'game-result-info': GameResultInfo
  },
  props: {
    gameType: {
      type: String,
      required: true
    },
    config: {
      type: Boolean,
      default: true
    },
    level: Number,
    methodType: {
      type: String,
      default: MethodType.Progressive,
      validator: (e: string) => {
        const methodType: string[] = [
          MethodType.Progressive,
          MethodType.Abacus,
          MethodType.SpeedChallenge,
          MethodType.Flash,
          MethodType.FM,
          MethodType.DailyBonus,
          MethodType.FlashBonus,
          MethodType.AbacusArithmetic,
          MethodType.AuralMental,
          MethodType.MentalArithmetic,
          MethodType.Empty
        ];

        return methodType.indexOf(e) > -1;
      }
    }
  },
  computed: {
    ...mapState({
      progressiveConfig: (state: IState) => state.game.progressiveConfig,
      progressiveQuestion: (state: IState) => {
        if (isNull(state.game.progressiveQuestion)) {
          return 1;
        }

        return state.game.progressiveQuestion;
      },
      speedConfig: (state: IState) => state.game.speedConfig,
      flashConfig: (state: IState) => state.game.flashConfig,
      auralConfig: (state: IState) => state.game.auralConfig,
      flashGlobalConfig: (state: IState) => state.global.globalConfig.game.flash,
      audioGlobalConfig: (state: IState) => state.global.globalConfig.game.audio,
      data: (state: IState) => state.game.data,
      timing: (state: IState) => state.game.timing,
      result: (state: IState) => state.game.result,
      methodTypeStore: (state: IState) => state.game.methodType,
      stars: (state: IState) => state.game.stars,
      authUser: (state: IState) => state.global.authUser,
      assignmentGame: (state: IState) => state.assignment.game,
      description: (state: IState) => state.game.description,
      flashSmarterGameConfig: (state: IState) => state.game.flashSmarterGameConfig,
      typeDisplayGame: (state: IState) => state.game.typeDisplayGame,
      allowMusic: (state: IState) => state.game.allowMusic,
    })
  },
  watch: {
    async showAnswer(newValue) {
      if (newValue) {
        if (!this.getIsCorrectResult && this.isOpenMusic && this.$refs.incorrectAudio) {
          this.$refs.incorrectAudio.play();
        }
        if (this.isAuralPractice) {
          await textToSpeech(this.currentProgressive.result.toString());
        }
        setTimeout(() => {
          this.nextQuestion();
        }, showAnswerTimeDefault);
      }

      return;
    }
  }
})

export class GamePlay extends Vue {
  public get isDevelopment() {
    return this.authUser
      && (this.authUser.email === 'ss011@yopmail.com'
        || this.authUser.email === 'lp001@yopmail.com'
        || this.authUser.email === 'peheo.lp1991@gmail.com');
  }
  public get currentProgressive(): IProgressive {
    const progressiveQuestion = this.progressiveQuestion;
    const progressive = cloneDeep(this.data);

    return progressive[progressiveQuestion - 1];
  }

  public get getMethodTypeTitle(): MethodType {
    if (this.methodTypeStore) {
      return this.methodTypeStore;
    }

    return this.methodType;
  }

  public get getProgressStatus() {
    return `${Math.floor((this.progressiveQuestion / this.data.length) * 278)}px`;
  }
  public get getQuestionOrAnswerText(): string {
    if (this.showAnswer) {
      return formatCurrency(this.currentProgressive.result.toString());
    }
    const expression = this.currentProgressive ? this.currentProgressive.expression : '';

    if (this.methodType === MethodType.Flash
        || this.methodType === MethodType.FlashBonus
        || this.methodType === MethodType.AuralMental
        || this.getMethodTypeTitle === MethodType.FM
        || this.getMethodTypeTitle === MethodType.AMA
        || this.typeDisplayGame === 'flash') {
      let txtArray: string[] = [];
      const removePlus: boolean = (this.methodType === MethodType.Flash
        || this.methodType === MethodType.FlashBonus
        || this.getMethodTypeTitle === MethodType.AMA
        || this.getMethodTypeTitle === MethodType.FM
        || this.methodType === MethodType.AuralMental);
      const _expression = `${expression}?`.replace(/\+/g, `${removePlus ? '\n' : '+ '}`);

      txtArray = multiLineText(_expression)
        .replace('÷ \n', '÷ ')
        .replace('-', '- ')
        .replace('× \n', '× ')
        .split('\n');

      return this.isShowQuestionInFlashGame ? formatCurrency(txtArray[this.flashGameIndex]) : '';
    }

    // return multiLineText(expression);
    // format currency for question in + and -
    let _multiLine: any = multiLineText(expression);
    _multiLine = _multiLine.split('\n').map((e) => {
      return formatCurrency(e);
    }).join('\n');

    return _multiLine;
  }

  public get isLongText(): boolean {
    if (this.showAnswer && this.currentProgressive.result.toString().length >= 9) {
      return true;
    }

    return false;
  }
  public get isFontTiny(): boolean {
    if (this.showAnswer && this.currentProgressive.result.toString().length >= 14) {
      return true;
    }

    return false;
  }
  public get isFontSuperTiny(): boolean {
    if (this.showAnswer && this.currentProgressive.result.toString().length >= 17) {
      return true;
    }
    const expression = this.currentProgressive ? this.currentProgressive.expression : null;
    if (!this.showAnswer && expression && expression.toString().length >= 20) {
      return true;
    }

    return false;
  }

  public get getQuestionOrAnswerLine(): number {
    const text: string = this.getQuestionOrAnswerText ? this.getQuestionOrAnswerText : '';

    return countLine(text);
  }

  public get getNumbers(): number {
    if (
      this.methodType === MethodType.FlashBonus
      || this.methodType === MethodType.AuralMental
      || this.getMethodTypeTitle === MethodType.AMA
      || this.getMethodTypeTitle === MethodType.FM) {
      const expression = this.currentProgressive ? this.currentProgressive.expression : '';

      return countOperator(expression);
    }

    return this.getConfig.numbers;
  }

  public get getConfig() {
    switch (this.methodType) {
    case MethodType.Progressive:
      return this.progressiveConfig;
    case MethodType.SpeedChallenge:
      return this.speedConfig;
    case MethodType.Flash:
      return this.flashConfig;
    case MethodType.FlashBonus:
      return this.flashGlobalConfig;
    default:
      break;
    }
  }

  public get getStar(): number {
    const stars = cloneDeep(this.stars),
      correctNumber = cloneDeep(this.result.correct);

    return getStar(correctNumber, stars);
  }

  public get getShowFooter(): boolean {
    return this.methodType === MethodType.Abacus
      || this.methodType === MethodType.DailyBonus
      || this.gameType === GameType.ExtraBonus
      || this.gameType === GameType.SmarterGame
      || this.gameType === GameType.Assignment;
  }
  public get getShowLevel(): boolean {
    return this.methodType === MethodType.Abacus || this.gameType === GameType.SmarterGame;
  }

  public get isShowIconSpeech(): boolean {
    return (this.methodType === MethodType.AuralMental
      || this.getMethodTypeTitle === MethodType.AMA) && !this.showAnswer;
  }

  public get getIsShowNumbers(): boolean {
    return this.methodType === MethodType.Flash
      || this.methodType === MethodType.FlashBonus
      || this.getMethodTypeTitle === MethodType.FM
      || this.methodType === MethodType.AuralMental
      || this.getMethodTypeTitle === MethodType.AMA;
  }

  public get getIsCorrectResult(): boolean {
    const result = this.form ? this.form.result : '';

    return formatCurrency(result) === this.getQuestionOrAnswerText;
  }

  public get formatterTiming() {
    const seconds = this.timing ? this.timing : 0;

    return formatterTimeBySeconds(seconds);
  }

  public get isShowStarOnProgress(): boolean {
    return this.gameType === GameType.SmarterGame;
  }

  public get isMethodMental(): boolean {
    return this.methodType === MethodType.AuralMental;
  }

  public get isCurrency(): boolean {
    return !!(this.currentProgressive && this.currentProgressive.isCurrency);
  }

  public get isAuralPractice(): boolean {
    return this.gameType === GameType.Practices && this.methodType === MethodType.AuralMental;
  }
  public get isOpenMusic(): boolean {
    return this.methodType !== MethodType.AuralMental && this.getMethodTypeTitle !== MethodType.AuralMental;
  }

  public get isMultiOrDiv(): boolean {
    const expression = this.currentProgressive ? this.currentProgressive.expression : null;

    return expression && (expression.includes('*') || expression.includes('/'));
  }
  public get isShowCountdown(): boolean {
    return !(this.methodType === MethodType.AMA && this.gameType === GameType.ExtraBonus);
  }

  public assignmentGame: IAssignmentGame;
  public timing: number;
  public visibleConfig: boolean = false;
  public methodType: MethodType;
  public methodTypeStore: MethodType;
  public gameType: GameType;
  public level: number;
  public $refs: {
    form: Form,
    input: Input,
    resultComponent: any,
    incorrectAudio: HTMLAudioElement,
    audioEror: HTMLAudioElement
  };
  public progressiveConfig: IProgressiveConfig;
  public data: IProgressive[];
  public progressiveQuestion: number;
  public showAnswer: boolean = false;
  public showPlaceholder: boolean = true;
  public speedConfig: ISpeedConfig;
  public flashConfig: IFlashConfig;
  public auralConfig: IAuralConfig;
  public methodTypeSpeed: MethodType = MethodType.SpeedChallenge;
  public isShowQuestionInFlashGame: boolean = false;
  public flashGlobalConfig: IFlashGameConfigGlobal;
  public audioGlobalConfig: IAudioConfigGlobal;
  public stars: number[];
  public form: IProgressiveForm = {
    result: ''
  };
  public authUser: IUser;

  public resultForm: IGameHistory = gameHistoryForm();

  public timingInterval: any = null;
  public dialogResult: boolean = false;
  public countDownSpeech: number = 0;
  public typeDisplayGame: string;

  private result: IGameResultInfo;
  private flashGameIndex: number = 0;
  private isPaused: boolean = false;
  private flashSmarterGameConfig: IFlashSmarterGameConfig;

  public resultKeyup(event: any) {
    // if (this.getQuestionOrAnswerText !== '?' && !this.showAnswer) {
    //   this.form.result = '';

    //   return;
    // }
    const target = event.target;
    const value = target.value.replace(/[a-zA-Z\,\s]/g, '');
    this.form.result = formatCurrency(value);
  }
  public resultKeydown(e: Event) {
    if (this.getQuestionOrAnswerText !== '?' && !this.showAnswer
      && (this.methodType === MethodType.FM
        || this.methodType === MethodType.Flash
        || this.methodType === MethodType.FlashBonus
        || this.getMethodTypeTitle === MethodType.FM
        || this.methodType === MethodType.AuralMental)) {
      e.preventDefault();
      this.form.result = '';

    }

    return;
  }

  public resultFocus() {
    this.showPlaceholder = false;
  }

  public placeholderClick() {
    this.$refs.input.focus();
  }

  public submit() {
    const result = this.form.result;
    if (!result) {
      return;
    }

    if (this.showAnswer) {
      return;
    }

    this.isPaused = true;
    this.showAnswer = true;
  }

  public nextQuestion() {
    const question = cloneDeep(this.data[this.progressiveQuestion - 1]),
      answer = cloneDeep(this.form).result;
    this.$store.commit(MutationTypeGame.UpdateGameResult, {
      question,
      answer
    });
    if (this.progressiveQuestion === this.data.length) {
      this.handleShowResult();

      return;
    }
    this.$store.commit(MutationTypeGame.SetProgressiveNextQuestion);
    this.showAnswer = false;
    this.isPaused = false;
    this.form.result = '';
    setTimeout(() => {
      this.$refs.input.focus();
    }, 0);

  }

  public openConfigDialog() {
    this.visibleConfig = true;
    this.isPaused = true;
    this.$refs.input.blur();
  }
  public closeConfigDialog() {
    this.visibleConfig = false;
    if (this.showAnswer) {
      return;
    }
    this.isPaused = false;
  }

  public applyConfig() {
    this.componentReset();
    setTimeout(this.getGame, 0);
    // this.getGame();
  }

  public clickCloseGame() {
    if (this.gameType === GameType.Assignment) {
      this.$router.push('/account/assignment');
    } else {
      this.$emit('closeGame');
    }
  }
  public cancleConfig() {
    this.$emit('closeGame');
  }

  public handlePrimaryResultClick() {
    this.componentReset();
    this.$store.commit(MutationTypeGame.ResetGameState);
    setTimeout(() => {
      this.getGame();
    }, 0);
  }
  public handleClickGoNext() { // when level complete, play the next level
    this.$store.commit(MutationTypeGame.SetGameLevelPlaying, this.level + 1);
    this.handlePrimaryResultClick();
  }
  public handleSecondaryResultClick() {
    switch (this.gameType) {
    case GameType.Assignment:
      this.$router.push('/account/assignment');
      break;
    case GameType.SmarterGame:
      this.$emit('closeGame', true);
      break;
    default:
      this.$router.push('/game');
      break;
    }
  }

  public mounted() {
    this.$nextTick(() => {
      try {
        if (this.$refs.audioEror) {
          this.$refs.audioEror.addEventListener('canplay', () => {
            this.$refs.audioEror.play();
          });
        }
        // tslint:disable:no-unused
        const text = '';
        const msg = new SpeechSynthesisUtterance();
        const voices = window.speechSynthesis.getVoices();
        msg.voice = voices[0];
        msg.text = text;
        speechSynthesis.speak(msg);
      } catch (error) {
        console.error(error);
      }
      window.onbeforeunload = () => {
        return '';
      };

      if (this.gameType === GameType.SmarterGame) {
        this.handleSmarterGame();

        return;
      }
      switch (this.methodType) {
      case MethodType.Progressive:
        const progressiveConfigLocal = localStorage.getItem(gameConfigLocal.progressiveConfigLocal);
        let progressiveConfig: IProgressiveConfig = null;
        if (progressiveConfigLocal) {
          progressiveConfig = JSON.parse(progressiveConfigLocal);
          progressiveConfig = omit(progressiveConfig, 'allowDecimal');
          progressiveConfig.enableCurrency = progressiveConfig.enableCurrency || false;
          this.$store.commit(MutationTypeGame.ProgressiveSetConfig, progressiveConfig);

        }

        this.openConfigDialog();
        break;
      case MethodType.SpeedChallenge:
        const speedConfigLocal = localStorage.getItem(gameConfigLocal.speedConfigLocal);
        let speedConfig: ISpeedConfig = null;
        if (speedConfigLocal) {
          speedConfig = JSON.parse(speedConfigLocal);
          this.$store.commit(MutationTypeGame.SpeedSetConfig, speedConfig);
        }

        this.openConfigDialog();
        break;
      case MethodType.Flash:
        const flashConfigLocal = localStorage.getItem(gameConfigLocal.flashConfigLocal);
        let flashConfig: ISpeedConfig = null;
        if (flashConfigLocal) {
          flashConfig = JSON.parse(flashConfigLocal);
          this.$store.commit(MutationTypeGame.FlashSetConfig, flashConfig);
        }

        this.openConfigDialog();
        break;
      case MethodType.AuralMental:
        if (this.gameType === GameType.Practices) {
          const auralConfigLocal = localStorage.getItem(gameConfigLocal.auralMentalConfigLocal);
          let auralConfig: ISpeedConfig = null;
          if (auralConfigLocal) {
            auralConfig = JSON.parse(auralConfigLocal);
            auralConfig = {
              ...auralConfig,
              speed: auralConfig.speed || 1
            };
            this.$store.commit(MutationTypeGame.AuralSetConfig, auralConfig);
          }
          this.openConfigDialog();

          return;
        }

        this.getGame();
        break;

      default:
        this.getGame();
        break;
      }

    });
  }

  public beforeDestroy() {
    this.$store.commit(MutationTypeGame.ResetGameState);
    this.isPaused = false;
    clearInterval(this.timingInterval);
    window.onbeforeunload = null;
  }

  public skip() {
    if (this.isDevelopment) {
      this.form.result = this.currentProgressive.result.toString();
      this.submit();
    }
  }

  private componentReset() {
    this.dialogResult = false;
    clearInterval(this.timingInterval);
    this.$store.commit(MutationTypeGame.StopTiming);
    this.$store.commit(MutationTypeGame.ResetGameState);
    this.visibleConfig = false;
    this.showAnswer = false;
    this.form.result = '';
    setTimeout(() => {
      this.$refs.input.focus();
    }, 0);
  }
  private handleShowResult() {
    this.isPaused = false;
    this.$refs.input.blur();
    this.$store.commit(MutationTypeGame.SetResultTime, {
      time: this.isAuralPractice ? -this.timing : this.timing,
      methodType: this.getMethodTypeTitle
    });
    clearInterval(this.timingInterval);
    this.$store.commit(MutationTypeGame.StopTiming);
    this.dialogResult = true;
    this.saveHistory();
  }

  private saveHistory() {
    const result = cloneDeep(this.result),
      totalQuestion = cloneDeep(this.$refs.resultComponent.totalQuestion);

    let typeMethod: any = this.getMethodTypeTitle;
    switch (this.getMethodTypeTitle) {
    case MethodType.FlashBonus:
    case MethodType.Flash:
      typeMethod = 'fm';
      break;

    default:
      typeMethod = this.getMethodTypeTitle;
      break;
    }
    const results = result.results.length > 0 ? result.results.map((e) => omit(e, ['isCurrency', 'desc']))
      : this.data.map((e) => {
        return {
          ...omit(e, ['isCurrency', 'point', 'desc']),
          answer: '',
          expression: e.expression ? e.expression.replace(/\,/g, '') : '',
          result: e.result ? e.result.toString().replace(/\,/g, '') : ''
        };
      });
    let metadata = <any> {
      totalQuestion,
      results,
      correctQuestion: result.correct || 0,
      totalTime: result.totalTime,
      // results: result.results.map((e) => omit(e, 'isCurrency'))
    };
    if (this.level) {
      metadata = {
        ...metadata,
        level: this.level,
        star: this.getStar
      };
    }
    if (this.assignmentGame && this.assignmentGame.id) {
      metadata.assignmentId = this.assignmentGame.id;
      metadata = omit(metadata, 'totalTime');
    }
    this.resultForm = {
      typeMethod,
      metadata,
      score: this.result.totalPoint,
      typeGame: this.gameType
    };

    this.$store.dispatch(ActionTypeGame.CreateGameHistory, stripObject(this.resultForm))
      .then(() => {
        if (isNumber(this.result.totalPoint) && this.result.totalPoint >= 0) {
          const user = cloneDeep(this.authUser),
            _user: IUser = {
              ...user,
              point: user.point + this.result.totalPoint
            };

          this.$store.commit(MutationType.Authenticated, _user);
        }

        return;
      })
      .catch((error) => {
        this.$store.dispatch(ActionType.CatchException, error);
      });
  }

  private abacusGameMethod() {
    this.timingInterval = setInterval(() => {
      if (!this.isPaused) {
        this.$store.commit(MutationTypeGame.StartTiming, true);
      }
      if (this.timing === 0) {
        this.handleShowResult();
      }
    }, 1000);
  }

  private async flashGameMethod(firstTime: boolean, timeTemp: number, type: MethodType, config?: IFlashConfig) {
    const { onScreen,
          delayNumber,
          delayQuestion,
          answerTime,
          numberQuestion
          } = cloneDeep(config),
      _flashconfig = omit(config,
      ['numberQuestion', 'onScreen', 'answerTime', 'operatorTab', 'delayNumber', 'delayQuestion']);
    let _fetchApi: Promise<any> = null;

    switch (type) {
    case MethodType.Flash:
      _fetchApi = this.$store.dispatch(ActionTypeGame.GetProgressive, {
        numberQuestion,
        config: _flashconfig
      });
      break;
    case MethodType.FlashBonus:
      _fetchApi = this.$store.dispatch(ActionTypeGame.GetBonusGame, BonusTypes.Flash);
      break;
    case MethodType.FM:
      _fetchApi = this.$store.dispatch(ActionTypeAssignment.GetAssignmentGame, this.assignmentGame.id);
      break;
    default:
      break;
    }
    const countDown: boolean = this.getMethodTypeTitle === MethodType.FM
      || this.getMethodTypeTitle === MethodType.FlashBonus
      || (this.typeDisplayGame === 'flash' && this.gameType !== GameType.Practices);
    await _fetchApi;
    this.$refs.input.focus();
    this.timingInterval = setInterval(() => {
      if (!this.isPaused) {
        this.$store.commit(MutationTypeGame.StartTiming, countDown);
        if (this.flashGameIndex === 0 && firstTime) {
          timeTemp++;
          if (timeTemp === delayQuestion) {
            timeTemp = 0;
            firstTime = false;
            this.isShowQuestionInFlashGame = true;
          } else {
            this.isShowQuestionInFlashGame = false;
          }

          return;
        }
        timeTemp++;

        if (this.isShowQuestionInFlashGame) { // when display number
          if (this.getQuestionOrAnswerText === '?') { // waiting user type answer
            if (answerTime === timeTemp) { // show answer screen
              this.flashGameIndex = 0;
              this.showAnswer = true;
              this.isPaused = true;
              this.isShowQuestionInFlashGame = false;
              firstTime = true;
              timeTemp = 0;
            }

            return;
          }
          if (onScreen === timeTemp || timeTemp > onScreen) { // hide number
          // if (onScreen === timeTemp) { // hide number
            this.flashGameIndex = this.flashGameIndex + 1;
            this.isShowQuestionInFlashGame = this.getQuestionOrAnswerText === '?';
            timeTemp = 0;

          }
        } else if (delayNumber === timeTemp) { // show number
          this.isShowQuestionInFlashGame = true;
          timeTemp = 0;
        }

      } else if (this.showAnswer) { // reset params
        this.flashGameIndex = 0;
        firstTime = true;
        timeTemp = 0;
        this.isShowQuestionInFlashGame = false;
      }

      return;
    }, 1000);
  }

  private handleSmarterGame() {
    this.$store.dispatch(ActionTypeGame.GetAbacus, {
      level: this.level,
      onSuccess: () => {
        switch (this.getMethodTypeTitle) {
        case MethodType.MA:
        case MethodType.AB:
          if (this.typeDisplayGame === 'flash') {
            const _flashSmarterGameConfig = cloneDeep(this.flashSmarterGameConfig);
            const _flashSmarterGameConfigTimeTemp: number = 0,
              _flashSmarterGameConfigFirstTime: boolean = true,
              cloneFlashSmarterGameConfig: IFlashConfig = {
                onScreen: _flashSmarterGameConfig.flashDuration,
                delayNumber: 0,
                delayQuestion: _flashSmarterGameConfig.questionDelay,
                answerTime: _flashSmarterGameConfig.answersDelay
              };
            this.flashGameMethod(_flashSmarterGameConfigFirstTime,
              _flashSmarterGameConfigTimeTemp, MethodType.AMA, cloneFlashSmarterGameConfig);
          } else {
            this.timingInterval = setInterval(() => {
              if (!this.isPaused) {
                this.$store.commit(MutationTypeGame.StartTiming, true);
              }

              if (this.timing === 0) {
                this.handleShowResult();
              }
            }, 1000);
          }
          break;
        case MethodType.AMA:
          const _timeAM: number = 0,
            _firstTimeAM: boolean = true;

          this.auralMentalGameMethod(_firstTimeAM, _timeAM);
          break;
        case MethodType.FM:
          const _flashConfig = cloneDeep(this.flashSmarterGameConfig);
            //  : cloneDeep(this.flashGlobalConfig);
          const _timeTemp: number = 0,
            _firstTime: boolean = true,
            cloneFlashConfig: IFlashConfig = {
              onScreen: _flashConfig.flashDuration,
              delayNumber: 1,
              delayQuestion: _flashConfig.questionDelay,
              answerTime: _flashConfig.answersDelay
            };
          this.flashGameMethod(_firstTime, _timeTemp, MethodType.AMA, cloneFlashConfig);
          break;
        default:
          break;
        }

      }
    });
  }
  private getGame() {
    if (this.gameType === GameType.SmarterGame) {
      this.handleSmarterGame();
    }
    if (this.gameType === GameType.Assignment) {
      switch (this.methodType) {
      case MethodType.AMA:
        const _timeAM: number = 0,
          _firstTimeAM: boolean = true;

        this.auralMentalGameMethod(_firstTimeAM, _timeAM);
        break;
      case MethodType.FM: // when click from assignment list type flash
        const _flashConfig = cloneDeep(this.flashGlobalConfig);
        const _timeTemp: number = 0,
          _firstTime: boolean = true,
          cloneFlashConfig: IFlashConfig = {
            onScreen: _flashConfig.onScreen,
            delayNumber: _flashConfig.timeBetweenNumber,
            delayQuestion: _flashConfig.timeBetweenQuestion,
            answerTime: _flashConfig.answerDelay
          };

        this.flashGameMethod(_firstTime, _timeTemp, this.methodType, cloneFlashConfig);
        break;
      case MethodType.AB:
        this.$store.dispatch(ActionTypeAssignment.GetAssignmentGame, this.assignmentGame.id)
          .then(() => {
            this.abacusGameMethod();
          });

        break;
      case MethodType.MA:
        this.$store.dispatch(ActionTypeAssignment.GetAssignmentGame, this.assignmentGame.id)
        .then(() => {
          this.timingInterval = setInterval(() => {
            if (!this.isPaused) {
              this.$store.commit(MutationTypeGame.StartTiming, true);
            }
            if (this.timing === 0) {
              this.handleShowResult();
            }
          }, 1000);
        });
        break;
      }

      return;
    }
    switch (this.methodType) {
    case MethodType.Progressive:
      const progressiveConfig = cloneDeep(this.progressiveConfig),
        numberQuestion = progressiveConfig.numberQuestion,
        config = omit(progressiveConfig, ['numberQuestion', 'operatorTab']);
      this.$store.dispatch(ActionTypeGame.GetProgressive, {
        numberQuestion,
        config
      }).then(() => {
        this.timingInterval = setInterval(() => {
          if (!this.isPaused) {
            this.$store.commit(MutationTypeGame.StartTiming);
          }
        }, 1000);
      });
      break;
    case MethodType.Abacus:
      this.$store.dispatch(ActionTypeGame.GetAbacus, {
        level: this.level,
        onSuccess: () => {
          this.abacusGameMethod();
        }
      });
      break;
    case MethodType.SpeedChallenge:
      const speedConfig = cloneDeep(this.speedConfig),
        _config = omit(speedConfig, 'speed');
      this.$store.dispatch(ActionTypeGame.GetProgressive, {
        numberQuestion: 99,
        config: _config
      }).then(() => {
        this.$store.commit(MutationTypeGame.SetTotalTime, speedConfig.speed);
        this.timingInterval = setInterval(() => {
          if (!this.isPaused) {
            this.$store.commit(MutationTypeGame.StartTiming, true);
          }
          if (this.timing === 0) {
            this.handleShowResult();
          }
        }, 1000);
      });
      break;
    case MethodType.Flash:
      const flashConfig = cloneDeep(this.flashConfig);
      const timeTemp: number = 0,
        firstTime: boolean = true;

      this.flashGameMethod(firstTime, timeTemp, MethodType.Flash, flashConfig);
      break;
    case MethodType.FlashBonus:
      const _flashConfig = cloneDeep(this.flashGlobalConfig);
      const _timeTemp: number = 0,
        _firstTime: boolean = true,
        cloneFlashConfig: IFlashConfig = {
          onScreen: _flashConfig.onScreen,
          delayNumber: _flashConfig.timeBetweenNumber,
          delayQuestion: _flashConfig.timeBetweenQuestion,
          answerTime: _flashConfig.answerDelay
        };

      this.flashGameMethod(_firstTime, _timeTemp, this.methodType, cloneFlashConfig);
      break;
    case MethodType.DailyBonus:
    case MethodType.AbacusArithmetic:
    case MethodType.MentalArithmetic:
      let _type: BonusTypes = null;
      switch (this.methodType) {
      case MethodType.AbacusArithmetic:
        _type = BonusTypes.Abacus;
        break;
      case MethodType.DailyBonus:
      case MethodType.MentalArithmetic:
        _type = BonusTypes.Mental;
        break;
      default:
        break;
      }
      this.$store.dispatch(ActionTypeGame.GetBonusGame, _type)
          .then(() => {
            this.timingInterval = setInterval(() => {
              if (!this.isPaused) {
                this.$store.commit(MutationTypeGame.StartTiming, true);
              }
              if (this.timing === 0) {
                this.handleShowResult();
              }
            }, 1000);
          });
      break;
    case MethodType.AuralMental:
      const _timeAM: number = 0,
        _firstTimeAM: boolean = true;

      this.auralMentalGameMethod(_firstTimeAM, _timeAM);
      break;
    default:
      break;
    }
  }

  private async auralMentalGameMethod(firstTime: boolean, timeTemp: number) {
    let onScreen = 1;
    const config = cloneDeep(this.audioGlobalConfig),
      delayNumber = config.numberDelay,
      delayQuestion = config.questionDelay,
      answerTime = config.answersDelay;

    let isSpeeching: boolean = false,
      firstSpeechingEqual: boolean = true;
    let _fetchApi: Promise<any> = null;

    if (this.gameType === GameType.Assignment) {
      _fetchApi = this.$store.dispatch(ActionTypeAssignment.GetAssignmentGame, this.assignmentGame.id);
    } else if (this.methodType === MethodType.AuralMental) {
      if (this.gameType === GameType.Practices) {
        const _config = cloneDeep(this.auralConfig);
        _fetchApi = this.$store.dispatch(ActionTypeGame.GetProgressive, {
          config: omit(_config, ['operatorTab', 'numberQuestion', 'speed']),
          numberQuestion: _config.numberQuestion
        });
      } else if (this.gameType === GameType.ExtraBonus) {
        _fetchApi = this.$store.dispatch(ActionTypeGame.GetBonusGame, 'aural_mental');
      } else {
        _fetchApi = this.$store.dispatch(ActionTypeGame.GetBonusGame, BonusTypes.Mental);
      }
    }

    await _fetchApi;
    this.countDownSpeech = delayQuestion;
    const isCountDown = this.gameType !== GameType.Practices;

    if (this.isAuralPractice) {
      let globalTimeInterval = 0;
      onScreen = 0;
      this.flashGameIndex = 0;
      timeTemp = 0;
      this.timingInterval = setInterval(async () => {
        if (this.timing === 0 && isCountDown) {
          this.handleShowResult();
        }
        if (!this.isPaused) {
          globalTimeInterval += 100;
          if (globalTimeInterval % 1000 === 0) {
            this.$store.commit(MutationTypeGame.StartTiming, isCountDown);
          }
          if (this.flashGameIndex === 0 && firstTime) {
            timeTemp = timeTemp + 100;
            this.countDownSpeech = (this.countDownSpeech * 1000 - 100) / 1000;
            if (timeTemp === delayQuestion * 1000) {
              timeTemp = 0;
              firstTime = false;
              this.isShowQuestionInFlashGame = true;
            } else {
              this.isShowQuestionInFlashGame = false;
            }

            return;
          }
          timeTemp = timeTemp + 100;
          if (this.isShowQuestionInFlashGame) { // when display number
            if (isSpeeching) {
              //
            } else if (this.getQuestionOrAnswerText !== '?') {
              isSpeeching = true;
              const t1 = performance.now();
              await textToSpeech(this.getQuestionOrAnswerText);
              const t2 = performance.now();
              onScreen = Number((this.auralConfig.speed + Math.ceil((t2 - t1) / 1000)).toFixed(1));
            }

            if (this.getQuestionOrAnswerText === '?') { // waiting user type answer
              isSpeeching = false;
              if (firstSpeechingEqual) {
                firstSpeechingEqual = false;
                await textToSpeech('answer');
              }
              if (answerTime * 1000 === timeTemp) { // show answer screen
                this.flashGameIndex = 0;
                this.countDownSpeech = delayQuestion;
                firstSpeechingEqual = true;
                this.showAnswer = true;
                this.isPaused = true;
                this.isShowQuestionInFlashGame = false;
                firstTime = true;
                timeTemp = 0;
              }

              return;
            }
            if (onScreen * 1000 === timeTemp) { // hide number
              isSpeeching = false;
              this.flashGameIndex = this.flashGameIndex + 1;
              this.isShowQuestionInFlashGame = this.getQuestionOrAnswerText === '?';
              timeTemp = 0;
            }
          } else { // show number
            this.isShowQuestionInFlashGame = true;
            timeTemp = 0;
          }

        } else if (this.showAnswer) { // reset params
          this.flashGameIndex = 0;
          this.countDownSpeech = delayQuestion;
          firstTime = true;
          firstSpeechingEqual = true;
          timeTemp = 0;
          this.isShowQuestionInFlashGame = false;
        }
      }, 100);
    } else {
      this.timingInterval = setInterval(async () => {
        if (this.timing === 0 && isCountDown) {
          this.handleShowResult();
        }
        if (!this.isPaused) {
          this.$store.commit(MutationTypeGame.StartTiming, isCountDown);
          if (this.flashGameIndex === 0 && firstTime) {
            timeTemp++;
            this.countDownSpeech = this.countDownSpeech - 1;
            if (timeTemp === delayQuestion) {
              timeTemp = 0;
              firstTime = false;
              this.isShowQuestionInFlashGame = true;
            } else {
              this.isShowQuestionInFlashGame = false;
            }

            return;
          }
          timeTemp++;
          if (this.isShowQuestionInFlashGame) { // when display number
            if (isSpeeching) {
              //
            } else if (this.getQuestionOrAnswerText !== '?') {
              isSpeeching = true;
              const t1 = performance.now();
              await textToSpeech(this.getQuestionOrAnswerText);
              const t2 = performance.now();
              onScreen = 1 + Math.ceil((t2 - t1) / 1000);
            }

            if (this.getQuestionOrAnswerText === '?') { // waiting user type answer
              isSpeeching = false;
              if (firstSpeechingEqual) {
                firstSpeechingEqual = false;
                await textToSpeech('answer');
              }
              if (answerTime === timeTemp) { // show answer screen
                this.flashGameIndex = 0;
                this.countDownSpeech = delayQuestion;
                firstSpeechingEqual = true;
                this.showAnswer = true;
                this.isPaused = true;
                this.isShowQuestionInFlashGame = false;
                firstTime = true;
                timeTemp = 0;
              }

              return;
            }
            // console.log('timeTemp after', onScreen, timeTemp);
            if (onScreen === timeTemp) { // hide number
              isSpeeching = false;
              this.flashGameIndex = this.flashGameIndex + 1;
              this.isShowQuestionInFlashGame = this.getQuestionOrAnswerText === '?';
              timeTemp = 0;
            }
          // } else if (delayNumber === timeTemp) { // show number
          } else { // show number
            this.isShowQuestionInFlashGame = true;
            timeTemp = 0;
          }

        } else if (this.showAnswer) { // reset params
          this.flashGameIndex = 0;
          this.countDownSpeech = delayQuestion;
          firstTime = true;
          firstSpeechingEqual = true;
          timeTemp = 0;
          this.isShowQuestionInFlashGame = false;
        }
      }, 1000);
    }
  }
}
