import { cmaFormatDateTime } from 'root/helpers';
import { planDefault } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      current: (state: IState) => state.subscription.current
    }),
    currentPlan() {
      return this.current.subscription ? this.current.subscription.plan : planDefault();
    }
  }
})

export class CurrentlySubscription extends Vue {
  public cmaFormatDateTime = cmaFormatDateTime;
}
