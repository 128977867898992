import Amplify from '@aws-amplify/core';
import ElementUI, { Notification } from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import 'firebase/analytics';
import firebase from 'firebase/app';
import Vue from 'vue';
// import VueLogger from 'vuejs-logger';
import { mapState } from 'vuex';
import { sync } from 'vuex-router-sync';
import { i18n } from './locales';

// layouts
import {
  HomeLayout,
  LoginLayout
} from './layouts';

// components
import {
  SplashScreenComponent, TopAlertComponent,
} from './components';

// import { configLogger } from './helpers';
import router from './router';
import store, { ActionType, IState } from './store';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.AWS_IDENTITY_POOL_ID,
    region: process.env.AWS_REGION,
    userPoolId: process.env.USER_POOL_ID,
    userPoolWebClientId: process.env.CLIENT_ID
  },
  Storage: {
    bucket: process.env.AWS_BUCKET_NAME,
    region: process.env.AWS_REGION,
  },
});
// Initialize firebase if prod
if (process.env.ENV === 'production') {
  const firebaseConfig = {
    apiKey: process.env.FIREBASE_APIKEY,
    authDomain: process.env.FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.FIREBASE_DATABASEURL,
    projectId: process.env.FIREBASE_PROJECTID,
    storageBucket: process.env.FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
    appId: process.env.FIREBASE_APPID,
    measurementId: process.env.FIREBASE_MEASUREMENTID
  };
  firebase.initializeApp(firebaseConfig);
  firebase.app().analytics();
}

sync(store, router);

Vue.use(ElementUI, { locale });

import VueLazyload, { VueLazyloadOptions } from 'vue-lazyload';
const optionLazy: VueLazyloadOptions = {
  listenEvents: ['scroll'],
  observer: true,
  dispatchEvent: true,
  silent: true,
  preLoad: 0.1,
  error: '/assets/img/avatar-placeholder@svg.svg',
  loading: '/assets/img/avatar-placeholder@svg.svg'
};
Vue.use(VueLazyload, optionLazy);
import { cmaHttpClient } from './api/http';
import './main.scss';
import { AssignmentStatus, IAssignment, NotificationType } from './models';
import { ActionTypeAssignment, GetterTypeAssignment, MutationTypeAssignment } from './pages/Assignment/Store/types';
import { NotificationRouterName } from './pages/Notification';
import { MutationTypeProfile } from './pages/Profile/Store/types';
import { initNotification, CMAAuth } from './services';

// Vue.use(VueLogger, configLogger());

async function clickNotification(self, _data: any) {
  const referenceId = _data.data.referenceId;
  switch (_data.data.type) {
  case NotificationType.NewAssignment:
  case NotificationType.RemindAssignment:
  case NotificationType.CompletedAssignment:
    await self.$store.dispatch(ActionTypeAssignment.AssignmentFindById, {
      id: referenceId
    });
    const assignment: IAssignment = self.$store.getters[GetterTypeAssignment.GetAssignmentById](referenceId);
    if (assignment.statusAssignment === AssignmentStatus.New) {
      self.$store.commit(MutationTypeAssignment.SetAssignmentGame, {
        type: assignment.library.method,
        id: assignment.id
      });
      self.$router.push('/game/extra-bonus');

      return;
    }
    self.$router.push(`/account/assignment?assignmentId=${assignment.id}&showPopup=true`);

    return;
  case NotificationType.Announcement:
    self.$router.push({
      name:  NotificationRouterName.View,
      params: {
        notifyId: _data.data.referenceId
      }
    });
    break;
  case NotificationType.RedemptionReady:
  case NotificationType.redemptionRejected:
  case NotificationType.RedemptionCompleted:
    self.$router.push(`/account/redeemed?type=redeemed&redeemedId=${referenceId}&showPopup=${true}`);
    break;
  default:
    break;
  }
}

// tslint:disable
Vue.directive('touch', {
  bind: (el: Element, binding: any) => {
    let touchMove = false;
    el.addEventListener('touchmove', () => {
      touchMove = true;
    });
    el.addEventListener('touchend', () => {
      if (!touchMove) {
        binding.value(el);
      }
      touchMove = false;
    });
  }
})
new Vue({
  el: '#app',
  router,
  store,
  i18n: i18n(),
  created() {
    this.$store.dispatch(ActionType.InitializeSettings);
    this.$root._i18n.locale = 'en';

    CMAAuth.currentSession()
      .then((user) => {
        this.$store.dispatch(ActionType.ChangeStateAuth, { router: this.$router, user });
      })
      .catch(() => {
        // const _currentToken = localStorage.getItem(LocalStorageType.CurrentToken);
        // if (_currentToken) {
        //   this.$store.dispatch(ActionType.ChangeStateAuth, { router: this.$router, user: {} });
        // } else {
        // }
        this.$store.dispatch(ActionType.ChangeStateAuth, { router: this.$router, user: null });
      })

    cmaHttpClient.global.getConfiguration().then(data => {
      this.$store.dispatch(ActionType.SetGlobalConfig, data);
      initNotification(process.env.ONESIGNAL_APP_ID, process.env.ONESIGNAL_SAFARI_WEB_ID, {
        onReceiveNotification: (_data) => {
          this.$store.commit(MutationTypeProfile.SetNewNotify, true);
          if (!this.isGameRouter) {
            switch (_data.data.type) {
              case NotificationType.NewAssignment:
              case NotificationType.RemindAssignment:
              case NotificationType.Announcement:
              case NotificationType.RedemptionReady:
              case NotificationType.redemptionRejected:
              case NotificationType.RedemptionCompleted:
                const instanceNotification: any = Notification({
                  duration: 5000,
                  title: _data.heading,
                  dangerouslyUseHTMLString: true,
                  // message: `<p class="text"><span class="cma-icon cma-icon__${endClass}"></span>${data.content}</p>`,
                  message: `<p class="text">${_data.content}</p>`,
                  offset: 35,
                  showClose: true,
                  onClick: () => {
                    // options.onClickNotification(_data);
                    clickNotification(this, _data);
                    instanceNotification.close();
                  }
                });
            default:
              break;
            }
          }

          
        },
        onClickNotification: async (_data) => {
          clickNotification(this, _data);
        }
      });
    });


  },
  mounted() {
    var lastTouchEnd = 0;
    document.addEventListener('touchend', function (event) {
      var now = (new Date()).getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    }, false);
  },
  methods: {
    touch(ev) {
      console.log(ev);
    }
  },
  computed: {
    ...mapState({
      layout: (state: IState) => {
        return state.global.layout;
      },
      isLoading: (state: IState) => {
        return state.global.isLoading;
      },
      isGameRouter: (state: IState) => state.game.isGameRouter,
      topAlertOpened: (state: IState) => {
        const topAlert = state.global.topAlert;
        if (topAlert.opened) {
          return topAlert;
        }
        return false;
      }
    })
  },
  components: {
    'home-layout': HomeLayout,
    'login-layout': LoginLayout,
    'splash-screen': SplashScreenComponent,
    'top-alert': TopAlertComponent
  }
});

