import { cloneDeep, omit } from 'lodash';
import { cmaFormatDateFullTime } from 'root/helpers';
import { AssignmentStatus, IAssignment, MethodType } from 'root/models';
import { IState } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Route } from 'vue-router';
import { mapGetters, mapState } from 'vuex';
import { UserAssignmentDialog } from './Components/UserAssignmentDialog';
import { ActionTypeAssignment, GetterTypeAssignment, MutationTypeAssignment } from './Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'user-assignment-dialog': UserAssignmentDialog
  },
  computed: {
    ...mapState({
      pagination: (state: IState) => state.assignment.pagination,
      loading: (state: IState) => state.assignment.loading,
      loadingMore: (state: IState) => state.assignment.loadingMore,
      data: (state: IState) => state.assignment.data
    }),
    ...mapGetters(['levelOptions', 'methodOptions', 'isLimitedAccess']),
    cloneLevelOptions() {
      const levelOptions = cloneDeep(this.levelOptions);

      return [
        {
          label: 'All Levels',
          value: null
        },
        ...levelOptions
      ];
    }
  },
  watch: {
    visibleDetail(newValue) {
      if (newValue === false) {
        const route: Route = this.$route,
          query = {
            ...omit(route.query, ['assignmentId', 'showPopup']),
          };
        this.$router.push({ query });
      }

      return;
    }
  }
})

export class Assignment extends Vue {
  public $refs: {
    leftFilter: HTMLDivElement
  };
  public pagination: IPaginationResult;
  public loading: boolean;
  public loadingMore: boolean;
  public data: IAssignment[];

  public assignmentFilter: string = '';
  public searchText: string = '';
  public methodSeleted: MethodType = MethodType.Empty;
  public levelSeleted: number = null;
  public cmaFormatDateFullTime = cmaFormatDateFullTime;
  public visibleDetail: boolean = false;
  public assignmentDetail: IAssignment = null;
  public isLimitedAccess: boolean;

  public get assignmentId() {
    return this.$route.query.assignmentId;
  }

  public handleShowDetail(model: IAssignment) {
    this.assignmentDetail = model;
    this.visibleDetail = true;
  }

  public handleHoldLeftFilter() {
    const leftFilter = this.$refs.leftFilter,
      bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    if (bodyScrollTop >= 108) {
      leftFilter.classList.add('fixed');
    } else {
      leftFilter.classList.remove('fixed');
    }
  }

  public formatterName(model: IAssignment) {
    return model.owner ? `Assignment from ${model.owner.fullName} • ${model.owner.centre}` : '';
  }

  public handleWindowScroll() {
    const bottomOfWindow =
      (window.scrollY + window.innerHeight) >= document.body.scrollHeight - 500;

    if (bottomOfWindow &&
        (!this.loading && !this.loadingMore) &&
        this.pagination.nextPageToken) {
      const query = this.$route.query;

      this.$store.dispatch(ActionTypeAssignment.FilterAssignment, {
        ...query,
        nextPageToken: this.pagination.nextPageToken
      });

    }

    return;
  }

  public changeStatusFilter(newValue: AssignmentStatus) {
    this.assignmentFilter = newValue;
    const route: Route = this.$route,
      query = {
        ...route.query,
        status: newValue
      };
    this.$router.push({query});
    window.scrollTo({
      top: 0
    });
    this.$store.commit(MutationTypeAssignment.clearAssignmentList);
    this.fetchData(true);
  }

  public changeMethodSelected(newValue: MethodType) {
    this.methodSeleted = newValue;
    const route: Route = this.$route,
      query = {
        ...route.query,
        method: newValue
      };
    this.$router.push({query});
    window.scrollTo({
      top: 0
    });
    this.$store.commit(MutationTypeAssignment.clearAssignmentList);
    this.fetchData(true);
  }
  public changeLevelSelected(newValue: number) {
    this.levelSeleted = newValue;
    const route: Route = this.$route,
      query = {
        ...route.query,
        level: newValue.toString()
      };
    this.$router.push({query});
    window.scrollTo({
      top: 0
    });
    this.$store.commit(MutationTypeAssignment.clearAssignmentList);
    this.fetchData(true);
  }

  public getStatusFilter(route: Route) {
    const query = route.query,
      status = query.status;

    return status ? status.toString() : '';
  }
  public getMethodSelected(route: Route): any {
    const query = route.query,
      method = query.method;

    return method ? method.toString() : MethodType.Empty;
  }
  public getSearchText(route: Route): any {
    const query = route.query,
      search = query.search;

    return search ? search.toString() : '';
  }

  public goGamePlayer(model: IAssignment) {
    this.$store.commit(MutationTypeAssignment.SetAssignmentGame, {
      type: model.library.method,
      id: model.id
    });
    this.$router.push('/game/extra-bonus');
  }

  public handleSearch() {
    const text = cloneDeep(this.searchText);

    const route: Route = this.$route,
      query = {
        ...route.query,
        search: text
      };
    this.$router.push({query});
    window.scrollTo({
      top: 0
    });
    this.$store.commit(MutationTypeAssignment.clearAssignmentList);
    this.fetchData(true);
  }

  public clickBtnSubscribe() {
    this.$router.push('/subscription');
  }
  public mounted() {
    this.$nextTick(async () => {
      if (!this.isLimitedAccess) {
        await this.fetchData(true);
      }
      this.assignmentFilter = this.getStatusFilter(this.$route);
      this.methodSeleted = this.getMethodSelected(this.$route);
      this.searchText = this.getSearchText(this.$route);
      if (this.assignmentId && this.$route.query.showPopup === 'true') {
        const assignment = this.$store.getters[GetterTypeAssignment.GetAssignmentById](this.assignmentId);

        this.handleShowDetail(assignment);
      }

      this.handleHoldLeftFilter();
      window.addEventListener('scroll', this.handleHoldLeftFilter, false);
      window.addEventListener('scroll', this.handleWindowScroll, false);
    });
  }

  public beforeDestroy() {
    window.removeEventListener('scroll', this.handleHoldLeftFilter, false);
    window.removeEventListener('scroll', this.handleWindowScroll, false);
  }

  private fetchData(first: boolean = false) {
    const query = this.$route.query;

    return new Promise(async (resolve) => {
      if (first && !query.page) {
        await this.$store.dispatch(ActionTypeAssignment.FilterAssignment, {
          ...query,
          page: 1
        });
      } else {
        await this.$store.dispatch(ActionTypeAssignment.FilterAssignment, {
          ...query
        });
      }
      resolve();
    });
  }
}
