import { stripObject } from '@hgiasac/helper';
import { Message } from 'element-ui';
import { cloneDeep, find, omit } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { IRedeemedFilter } from 'root/models';
import { MutationTypeGame } from 'root/pages/Game/Store/types';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IRedeemedState } from './mutations';
import { ActionTypeRedeemed, MutationTypeRedeemed } from './types';

const { getRedeemedList, getRedeemedGame, redeemProduct, getById } = cmaHttpClient.redeemed;

export const actions = {
  ...crudActions('redemption', {}),
  async [ActionTypeRedeemed.FilterRedeemed](
    {commit, dispatch }: ActionContext<IRedeemedState, IState>, dataFilter: IRedeemedFilter
  ) {
    dataFilter = <any> stripObject(dataFilter);

    if (dataFilter.page && dataFilter.page === 1) {
      commit(MutationTypeRedeemed.RedeemedLoading);
    } else {
      commit(MutationTypeRedeemed.RedeemedLoadingMore);
    }

    try {
      const filter: any = omit(dataFilter, 'page');
      const data = await getRedeemedList(filter);

      if (dataFilter.page === 1) {
        commit(MutationTypeRedeemed.SetRedeemedList, data);
        commit(MutationTypeRedeemed.RedeemedLoaded);
      } else {
        commit(MutationTypeRedeemed.UpdateRedeemedList, data);
        commit(MutationTypeRedeemed.RedeemedLoadedMore);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRedeemed.GetRedeemedGame](
    { commit, dispatch}: ActionContext<IRedeemedState, IState>, id: number
  ) {
    commit(MutationTypeGame.SetBonusLoading);
    // console.log('state', rootState.global.authUser.point);
    try {
      const data = await getRedeemedGame(id);

      commit(MutationTypeGame.SetBonusLoaded);
      commit(MutationTypeGame.SetBonusGame, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRedeemed.RedeemProduct](
    { commit, dispatch, rootState}: ActionContext<IRedeemedState, IState>, {id, qty}
  ) {
    commit(MutationTypeRedeemed.RedeemProductLoading);
    try {
      const root = cloneDeep(rootState),
        authUser = root.global.authUser,
        data = root.product.data,
        product = find(data, (e) => e.id === id),
        points = Number(product.point) * qty;
      const _authUser = {
        ...authUser,
        point: authUser.point - points
      };
      await redeemProduct(id, qty);
      commit(MutationType.Authenticated, _authUser);
      Message.success('Redeemed product successfully!');
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeRedeemed.RedeemProductLoaded);
  },
  async [ActionTypeRedeemed.RedeemGetById](
    { dispatch }: ActionContext<IRedeemedState, IState>, id: number
  ) {
    try {
      return await getById(id);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  }
};
