import { isFunction } from 'lodash';
import VueRouter from 'vue-router';
import { ActionContext } from 'vuex';

import { cmaHttpClient } from 'root/api/http';
import { parseParamUrl } from 'root/helpers';
import { IErrorException, LocalStorageType } from 'root/models';
import { MutationTypeProfile } from 'root/pages/Profile/Store/types';
import { ActionTypeSubscription } from 'root/pages/Subcription/Store/types';
import {
  deleteTagForNotification,
  sendTagForNotification,
  CMAAuth,
  Logger
} from 'root/services';
import { i18n } from '../locales';
import { RouteDictionary } from '../router';
import { ActionType, GetterTypeGlobal, IState, Layout, MutationType, TypeAlert } from './types';

export enum ErrorCode {
  UserNotFound = 'user_not_found'
}

export const actions = {
  // global
  [ActionType.InitializeSettings](
    { commit }: ActionContext<IState, IState>
  ) {
    const locale = 'en';
    i18n().locale = locale;
    commit(MutationType.SetCurrentLocale, locale);
  },
  [ActionType.SetLayout](
    { commit }: ActionContext<IState, IState>
  ) {
    commit(MutationType.SetLayout);
  },
  [ActionType.SetGlobalConfig](
    { commit }: ActionContext<IState, IState>,
    globalConfig
  ) {
    commit(MutationType.SetGlobalConfig, globalConfig);
  },
  [ActionType.GoBack](
    _: ActionContext<any, any>,
    router: VueRouter,
  ) {
    router.back();
  },
  [ActionType.GoToRoute](
    _: ActionContext<any, any>,
    { router, route, paramUrl }
  ) {
    const method = typeof route === 'string' ? 'push'
      : (route.method || 'push');
    router[method](parseParamUrl(route, paramUrl));
  },
  [ActionType.CollapseMenu](
    { commit }: ActionContext<any, any>,
    isCollapse: boolean,
  ) {
    commit(MutationType.SetMenuCollapse, isCollapse);
  },
  [ActionType.CatchException](
    { commit }: ActionContext<any, any>,
    ex: IErrorException
  ) {
    console.error(ex);
    const message = ex.code
      ? (i18n().t(ex.code) !== ex.code ? i18n().t(ex.code) : ex.message)
      : ex.message;
    commit(MutationType.OpenTopAlert, {
      message,
      type: TypeAlert.Error
    });
  },
  [ActionType.CloseTopAlert](
    { commit }: ActionContext<IState, IState>
  ) {
    commit(MutationType.CloseTopAlert);
  },
  // login
  [ActionType.ChangeStateAuth](
    { dispatch, commit, state, getters }: ActionContext<IState, IState>,
    { router, user, cb }
  ) {
    if (state.global.isRegisting) {
      return;
    }
    if (user) {
      // get profile 
      commit(MutationType.OpenPlashScreen);
      cmaHttpClient.profile.getProfile()
        .then(async (profile) => {
          commit(MutationType.ClosePlashScreen);
              // can not get profile, go back to login page
          if (!profile) {
            Logger.warn('Can not get profile. This user doesn\'t exist in CMA System');

            commit(MutationType.SetLayout, Layout.Login);
            router.replace(RouteDictionary.Login);

            return CMAAuth.signOut();
          }
          commit(MutationType.Authenticated, profile);
          // const currentToken = localStorage.getItem(LocalStorageType.CurrentToken);
          let logUser: any = localStorage.getItem(LocalStorageType.LogUser);

          const userPoor = await CMAAuth.currentUser(),
            currentUserPoor = {},
            storage = userPoor ? userPoor.storage : {};
          for (const key in storage) {
            if (storage.hasOwnProperty(key)
              && key.includes('CognitoIdentityServiceProvider')
              && storage[key]) {
              currentUserPoor[key] = storage[key];
            }
          }
          logUser = logUser ? JSON.parse(logUser) : [];
          if (!Array.isArray(logUser)) {
            logUser = [];
          }
          logUser = logUser.filter((u) => u.id !== profile.id);
          logUser.push({
            token: currentUserPoor,
            id: profile.id,
            fullName: profile.fullName,
            avatar: profile.avatar
          });
          localStorage.setItem(LocalStorageType.LogUser, JSON.stringify(logUser));
          if (isFunction(cb)) {
            cb();
          }
          commit(MutationType.SetLayout, Layout.Home);
          /**
           * get current subscription
           */
          dispatch(ActionTypeSubscription.GetCurrentSubscription);
          const centre = getters[GetterTypeGlobal.GetCentreValue](profile.centre);
          sendTagForNotification(<any> {
            user: {
              ...profile,
              centre
            }
          });

          if (state.route.path === RouteDictionary.Login
            || state.route.path === RouteDictionary.Register
            || state.route.path === RouteDictionary.ForgotPassword) {
            router.replace(RouteDictionary.Home);
          }
        })
        .catch((error) => {
          if (error.code === ErrorCode.UserNotFound) {
            commit(MutationType.ClosePlashScreen);
            router.replace('/register');

            return;
          }
          dispatch(ActionType.CatchException, error);
          commit(MutationType.ClosePlashScreen);
        });
    } else {
      commit(MutationType.ClosePlashScreen);
      commit(MutationType.SetLayout, Layout.Login);
      if (state.route.path !== RouteDictionary.Register
        && state.route.path !== RouteDictionary.Login
        && state.route.path !== RouteDictionary.Home
        && state.route.path !== RouteDictionary.ForgotPassword) {
        router.replace(RouteDictionary.Login);
      }
    }
  },
  [ActionType.Unauthenticated](
    { commit, dispatch }: ActionContext<IState, IState>, router: VueRouter
  ) {
    commit(MutationType.OpenPlashScreen);
    CMAAuth.signOut()
      .then(() => {
        deleteTagForNotification();
        commit(MutationType.Unauthenticated);
        commit(MutationType.ClearStore);
        dispatch(ActionType.ChangeStateAuth, {router, user: null});
      })
      .catch((error) => {
        dispatch(ActionType.CatchException, error);
      });
  },
  async [ActionType.ReloadProfile](
    { commit, dispatch }: ActionContext<IState, IState>, {
      onSuccess,
      onFailure
    }
  ) {
    commit(MutationTypeProfile.LoadingProfile, true);
    try {
      const profile = await cmaHttpClient.profile.getProfile();
      commit(MutationType.Authenticated, profile);
      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      if (isFunction(onFailure)) {
        onFailure(error);
      }
    }
    commit(MutationTypeProfile.LoadingProfile, false);
  },
};
