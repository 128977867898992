import { stripObject } from '@hgiasac/helper';
import { omit } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { IBoardFilter } from 'root/models';
// import { IBoardFilter } from 'root/models';
import { ActionType, IState } from 'root/store';
// import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IBoardState } from './mutations';
import { ActionTypeBoard, MutationTypeBoard } from './types';

const { getBoardList, getCoolestKid } = cmaHttpClient.board;

export const actions = {
  // ...crudActions('board', {}),
  async [ActionTypeBoard.FilterBoard](
    {commit, dispatch }: ActionContext<IBoardState, IState>, dataFilter: IBoardFilter
  ) {
    dataFilter = <any> stripObject(dataFilter);

    if (dataFilter.page && dataFilter.page === 1) {
      commit(MutationTypeBoard.BoardLoading);
    } else {
      commit(MutationTypeBoard.BoardLoadingMore);
    }

    try {
      const filter: any = omit(dataFilter, 'page');
      const data = await getBoardList(filter);

      if (dataFilter.page === 1) {
        commit(MutationTypeBoard.SetBoardList, data);
        commit(MutationTypeBoard.BoardLoaded);
      } else {
        commit(MutationTypeBoard.UpdateBoardList, data);
        commit(MutationTypeBoard.BoardLoadedMore);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      commit(MutationTypeBoard.BoardLoaded);
      commit(MutationTypeBoard.BoardLoadedMore);
    }
  },
  async [ActionTypeBoard.GetCoolestKid](
    {commit, dispatch }: ActionContext<IBoardState, IState>
  ) {
    commit(MutationTypeBoard.SetCoolestKidLoading);

    try {
      const data = await getCoolestKid();
      commit(MutationTypeBoard.SetCoolestKid, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }

    commit(MutationTypeBoard.SetCoolestKidLoaded);
  }
};
