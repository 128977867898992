import { IUser, IUserRegisterForm } from 'root/models';
import { IHttpServiceClientOptions } from '../Core';

export interface IProfileHttpService {
  getProfile(): Promise<IUser>;
  updateProfile(form: IUserRegisterForm): Promise<IUser>;
  logOut(): Promise<any>;
  register(form: IUserRegisterForm): Promise<IUser>;
  getPointsHistory(nextPageToken?: string): Promise<any>;
  getPointsRedemption(nextPageToken?: string): Promise<any>;
  // updateUserNotificationSetting(form: IUserNotificationSettingForm): Promise<any>;
}

export function ProfileHttpService(options: IHttpServiceClientOptions): IProfileHttpService {

  const baseUrl = '/me',
    // url = options.httpService.getUrl(baseUrl),
    urlLogout = options.httpService.getUrl(`${baseUrl}/logout`, options.mock);

    // urlUserNotificationSetting = options.httpService.getUrl(`${baseUrl}/notification`, options.mock);

  function register(form: IUserRegisterForm): Promise<IUser> {
    const registerPath = options.httpService.getUrl('/profile');

    return options.httpService.post(registerPath, form);
  }

  function logOut(): Promise<any> {
    return options.httpService.put(urlLogout, null);
  }

  function getProfile(): Promise<IUser> {
    const profilePath = options.httpService.getUrl('/profile');

    return options.httpService.get(profilePath);
  }

  function updateProfile(form: IUserRegisterForm): Promise<IUser> {
    const updatePath = options.httpService.getUrl('/profile');

    return options.httpService.put(updatePath, form);
  }
  function getPointsHistory(nextPageToken?: string): Promise<any> {
    const filter = nextPageToken ? `nextPageToken=${nextPageToken}` : 'page=1';
    const pointHistoryPath = options.httpService.getUrl(`/point-histories?${filter}`);

    return options.httpService.get(pointHistoryPath);
  }
  function getPointsRedemption(nextPageToken?: string): Promise<any> {
    const filter = nextPageToken ? `nextPageToken=${nextPageToken}` : 'page=1';
    const pointHistoryPath = options.httpService.getUrl(`/point-redemptions?${filter}`);

    return options.httpService.get(pointHistoryPath);
  }

  // function updateUserNotificationSetting(form: IUserNotificationSettingForm): Promise<any> {
  //   return options.httpService.put(urlUserNotificationSetting, form);
  // }

  return {
    getProfile,
    updateProfile,
    logOut,
    register,
    getPointsHistory,
    getPointsRedemption
  };
}
