import { IRedeemed, IRedeemedFilter, MethodType, RedeemedStatus } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IRedeemedHttpService
  extends ICRUDHttpService<IRedeemed> {
  getRedeemedList(filter: IRedeemedFilter): Promise<any>;
  getRedeemedGame(id: number): Promise<any>;
  redeemProduct(id: number, qty: number): Promise<{message: string}>;
}

export function RedeemedHttpService(options: IHttpServiceClientOptions): IRedeemedHttpService {
  const basePath = '/redemptions';
  const baseUrl = options.httpService.getUrl(basePath);

  function getRedeemedList(filter: IRedeemedFilter = {
    method: MethodType.Empty,
    status: RedeemedStatus.Default
  }) {
    return options.httpService.get(baseUrl, {
      params: filter
    });
  }

  function getRedeemedGame(id: number) {
    const RedeemedUrl = options.httpService.getUrl(`${basePath}/${id}/games`);

    return options.httpService.get(RedeemedUrl);
  }

  function redeemProduct(id: number, qty: number): Promise<{message: string}> {
    const redeemUrl = options.httpService.getUrl(`${basePath}/products/${id}`);

    return options.httpService.post(redeemUrl, {
      qty
    });
  }

  return {
    ...CRUDHttpService<IRedeemed>({
      ...options,
      basePath
    }),
    getRedeemedList,
    getRedeemedGame,
    redeemProduct
  };
}
