import { cloneDeep, isNull } from 'lodash';
import { CMABreakcrumb } from 'root/components';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import VueGoogleMaps from 'vue-googlemaps';
import 'vue-googlemaps/dist/vue-googlemaps.css';
import { mapState } from 'vuex';
import { ActionTypeContact } from './Store/types';
import './styles.scss';
Vue.use(VueGoogleMaps, {
  load: {
    // Google API key
    apiKey: process.env.GOOGLE_API_KEY,
    // Enable more Google Maps libraries here
    // libraries: ['places'],
    // Use new renderer
    useBetaRenderer: false,
  },
});
@Component({
  template: require('./view.html'),
  components: {
    'cma-breakcumb': CMABreakcrumb
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.contact.data,
      loading: (state: IState) => state.contact.loading,
      loadingMore: (state: IState) => state.contact.loadingMore
    })
  }
})

export class ContactUs extends Vue {
  public data: any[];
  public centreActive: string = '';
  public center = {
    lat: 1.4346683,
    lng: 103.7867366

  };
  public zoom = 12;
  public mapOptions = {
  };
  public mapMarker: Array<{
    lat: number,
    lng: number
  }> = [];

  public userPosition: any = null;

  public setUserPosition(position) {
    this.userPosition = position;
  }

  public onClick(event) {
    event.stop();
    console.log(event.latLng.lat());
    console.log(event.latLng.lng());
    // this.placeLocation = null
    // this.placeId = event.placeId
    // this.$refs.results.$el.scrollTop = 0
  }

  protected mounted() {
    this.$nextTick(async () => {
      this.$store.commit(MutationType.BreakcumbChange, [{
        label: this.$t('home'),
        url: '/'
      }, {
        label: this.$t('contact'),
        url: '',
        currently: true
      }]);

      await this.$store.dispatch(ActionTypeContact.GetCentre);
      this.mapMarker = cloneDeep(this.data).map((e) => {
        const latLong = e.metadata.latLong ? e.metadata.latLong.split(',') : null;

        return latLong ? {
          lat: Number(latLong[0]),
          lng: Number(latLong[1])
        } : this.center;
      }).filter((e) => !isNull(e));
    });
  }
}
