export enum MutationTypeBoard {
  BoardLoading = 'BoardLoading',
  BoardLoaded = 'BoardLoaded',
  BoardLoadingMore = 'BoardLoadingMore',
  BoardLoadedMore = 'BoardLoadedMore',
  SetBoardList = 'setBoardList',
  clearBoardList = 'clearBoardList',
  UpdateBoardList = 'updateBoardList',
  SetBoardGame = 'SetBoardGame',
  ResetBoardGame = 'ResetBoardGame',
  SetCoolestKid = 'SetCoolestKid',
  SetCoolestKidLoading = 'SetCoolestKidLoading',
  SetCoolestKidLoaded = 'SetCoolestKidLoaded'
}

export enum ActionTypeBoard {
  UpdateBoard = 'updateBoard',
  LoadingBoard = 'loadingBoard',
  BoardFilterNoCache = 'boardFilterNoCache',
  FilterBoard = 'filterBoard',
  GetCoolestKid = 'getCoolestKid'
}
