import { CMAAvatar } from 'root/components';
import { formatCurrency } from 'root/helpers';
import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { MutationTypeGame } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-avatar': CMAAvatar
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      allowMusic: (state: IState) => state.game.allowMusic
    }),
    ...mapGetters(['isLimitedAccess'])
  }
})

export class FirstScreen extends Vue {
  public allowMusic: boolean;

  public getScore(score: number) {
    return formatCurrency(score);
  }

  public clickBtnSubscribe() {
    this.$router.push('/subscription');
  }

  public toggleAudio() {
    this.$store.commit(MutationTypeGame.AllowMusic, !this.allowMusic);
  }

  protected mounted() {
    this.$store.dispatch(ActionType.ReloadProfile, {});
  }
}
