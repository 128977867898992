import { MessageBox } from 'element-ui';
import { cloneDeep, isNil } from 'lodash';
import { CMAAvatar, CMABreakcrumb } from 'root/components';
import { IUser, IUserCacheLocal, LocalStorageType } from 'root/models';
import { ForgotPassword } from 'root/pages/ForgotPassword';
import { LoginContainer } from 'root/pages/Login';
import { Register } from 'root/pages/Register';
import { CMAAuth } from 'root/services';
import { ActionType, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-breakcumb': CMABreakcrumb,
    'cma-avatar': CMAAvatar,
    'login-page': LoginContainer,
    'register-page': Register,
    'forgot-password': ForgotPassword
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser
    })
  }
})

export class SwitchAccount extends Vue {
  public logUser: IUserCacheLocal[] = [];
  public authUser: IUser;
  public showRemoveButton: boolean = false;
  public visible: boolean = false;
  public dialogType: string = '';

  public beforeOpen() {
    //
  }
  public addNewAccount() {
    this.visible = true;
    this.dialogType = 'login';
  }
  public toogleRemoveButton() {
    this.showRemoveButton = !this.showRemoveButton;
  }
  public close() {
    this.visible = false;
    this.dialogType = '';
  }

  public changeUser(e: Event, id: string) {
    if (this.showRemoveButton || this.authUser.id === id) {
      e.preventDefault();
      e.stopPropagation();

      return;
    }

    const selectedUser: IUserCacheLocal = this.logUser.find((user) => user.id === id);
    const currentUser: IUserCacheLocal = this.logUser.find((user) => user.id === this.authUser.id);
    MessageBox({
      title: 'Do you really want to switch\n to this account?',
      showCancelButton: true,
      confirmButtonText: 'YES',
      cancelButtonText: 'NO',
      customClass: 'subscription-popup',
      beforeClose: (action, instance: any, done) => {
        switch (action) {
        case 'confirm':
          instance.confirmButtonLoading = true;
          CMAAuth.signOut().then(() => {
            const user = <any> selectedUser.token;
            for (const key in user) {
              if (user.hasOwnProperty(key) && !isNil(user[key])) {
                localStorage.setItem(key, user[key]);
              }
            }
            this.$store.dispatch(ActionType.ReloadProfile, {
              onSuccess: () => {
                done();
                location.reload();
              },
              onFailure: () => {
                done();
                const currentToken = <any> currentUser.token;
                for (const key in user) {
                  if (user.hasOwnProperty(key) && !isNil(user[key])) {
                    localStorage.removeItem(key);
                  }
                }
                for (const key in currentToken) {
                  if (currentToken.hasOwnProperty(key) && !isNil(currentToken[key])) {
                    localStorage.setItem(key, currentToken[key]);
                  }
                }
                let logUser = cloneDeep(this.logUser);
                logUser = logUser.filter((_logUser) => _logUser.id !== id);
                localStorage.setItem(LocalStorageType.LogUser, JSON.stringify(logUser));
                this.logUser = logUser;
              }
            });
          });
          instance.confirmButtonLoading = false;
          break;
        case 'cancel':
          instance.confirmButtonLoading = false;
          instance.close();
          break;
        case 'close':
          instance.confirmButtonLoading = false;
          instance.close();
          break;
        default:
          break;
        }
      }
    }).catch(() => {
      // no need to handle
    });
  }
  public removeUser(e: Event, id: string) {
    e.preventDefault();
    e.stopPropagation();
    MessageBox({
      title: 'Do you really want to delete\n this account from your device?',
      showCancelButton: true,
      confirmButtonText: 'YES',
      cancelButtonText: 'NO',
      customClass: 'subscription-popup',
      beforeClose: (action, instance: any, done) => {
        switch (action) {
        case 'confirm':
          instance.confirmButtonLoading = true;
          let logUser = cloneDeep(this.logUser);
          logUser = logUser.filter((user) => user.id !== id);
          localStorage.setItem(LocalStorageType.LogUser, JSON.stringify(logUser));
          this.logUser = logUser;
          done();
          instance.confirmButtonLoading = false;
          break;
        case 'cancel':
          instance.confirmButtonLoading = false;
          instance.close();
          break;
        case 'close':
          instance.confirmButtonLoading = false;
          instance.close();
          break;
        default:
          break;
        }
      }
    }).catch(() => {
      // no need to handle
    });
  }

  public loginSuccess() {
    let logUser = <any> localStorage.getItem(LocalStorageType.LogUser);
    logUser = logUser ? JSON.parse(logUser) : [];
    this.logUser = logUser;
  }
  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.BreakcumbChange, [{
        label: this.$t('setting'),
        url: '/setting'
      }, {
        label: this.$t('switch_account'),
        url: '',
        currently: true
      }]);
      let logUser = <any> localStorage.getItem(LocalStorageType.LogUser);
      logUser = logUser ? JSON.parse(logUser) : [];
      this.logUser = logUser;
    });
  }
}
