import Vue from 'vue';
import Vuex from 'vuex';

import { actions } from './actions';
import { getters } from './getters';
import { globalState, mutations } from './mutations';

// module
import * as article from 'root/pages/Article/Store';
import * as assignment from 'root/pages/Assignment/Store';
import * as board from 'root/pages/Board/Store';
import * as contact from 'root/pages/ContactUs/Store';
import * as game from 'root/pages/Game/Store';
import * as home from 'root/pages/Home/Store';
import * as notification from 'root/pages/Notification/Store';
import * as product from 'root/pages/Product/Store';
import * as profile from 'root/pages/Profile/Store';
import * as redemption from 'root/pages/Redeemed/Store';
import * as subscription from 'root/pages/Subcription/Store';

export * from './types';

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  getters,
  mutations,
  state: {
    global: globalState
  },
  modules: {
    home,
    profile,
    article,
    game,
    assignment,
    redemption,
    product,
    board,
    contact,
    notification,
    subscription
  }
});

export * from './types';
