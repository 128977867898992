// import { cmaHttpClient } from 'root/api/http';
import { Message } from 'element-ui';
import { cmaHttpClient } from 'root/api/http';
import { IUserRegisterForm } from 'root/models';
import { ActionType, IState, MutationType } from 'root/store/types';
import { ActionContext } from 'vuex';
import { IProfileState } from './mutations';
import { ActionTypeProfile, MutationTypeProfile } from './types';

const { updateProfile, getPointsHistory, getPointsRedemption } = cmaHttpClient.profile;

export const actions = {
  [ActionTypeProfile.Register](
    {}: ActionContext<IProfileState, IState>, form: IUserRegisterForm
  ) {
    const { postalCode } = form;
    if (postalCode) {
      form = {
        ...form,
        postalCode: Number(postalCode)
      };
    }

    return cmaHttpClient.profile.register(form);
  },
  async [ActionTypeProfile.ProfileUpdate](
    { dispatch, commit }: ActionContext<IProfileState, IState>, form: IUserRegisterForm
  ) {
    commit(MutationTypeProfile.ProfileLoading);
    try {
      const data = await updateProfile(form);
      commit(MutationType.Authenticated, data);
      Message.success('Update profile successfully!');

    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }

    commit(MutationTypeProfile.ProfileLoaded);
  },
  async [ActionTypeProfile.GetPointHistory](
    { dispatch, commit }: ActionContext<IProfileState, IState>, payload
  ) {
    if (payload.page && payload.page === 1) {
      commit(MutationTypeProfile.SetPointHistoryLoading);
    } else {
      commit(MutationTypeProfile.SetPointHistoryLoadingMore);
    }

    try {
      const data = await getPointsHistory(payload.nextPageToken);
      if (payload.page && payload.page === 1) {
        commit(MutationTypeProfile.SetPointHistory, data);
        commit(MutationTypeProfile.SetPointHistoryLoaded);
      } else {
        commit(MutationTypeProfile.UpdatePointHistory, data);
        commit(MutationTypeProfile.SetPointHistoryLoadedMore);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      commit(MutationTypeProfile.SetPointHistoryLoaded);
      commit(MutationTypeProfile.SetPointHistoryLoadedMore);
    }
  },
  async [ActionTypeProfile.GetPointRedemption](
    { dispatch, commit }: ActionContext<IProfileState, IState>, payload
  ) {
    if (payload.page && payload.page === 1) {
      commit(MutationTypeProfile.SetPointRedemptionLoading);
    } else {
      commit(MutationTypeProfile.SetPointRedemptionLoadingMore);
    }

    try {
      const data = await getPointsRedemption(payload.nextPageToken);
      if (payload.page && payload.page === 1) {
        commit(MutationTypeProfile.SetPointRedemption, data);
        commit(MutationTypeProfile.SetPointRedemptionLoaded);
      } else {
        commit(MutationTypeProfile.UpdatePointRedemption, data);
        commit(MutationTypeProfile.SetPointRedemptionLoadedMore);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      commit(MutationTypeProfile.SetPointRedemptionLoaded);
      commit(MutationTypeProfile.SetPointRedemptionLoadedMore);
    }
  }
};
