import * as moment from 'moment';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeArticle } from './Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.article.detail,
      loading: (state: IState) => state.article.detailLoading
    })
  }
})

export class ArticleContainer extends Vue {
  public formatterTiming(model: string) {
    return moment(model).format('DD MMM YYYY');
  }

  public mounted() {
    const id = <any> this.$route.params.id;
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeArticle.GetArticleDetail, id);
    });
  }
}
