import { cloneDeep, findIndex } from 'lodash';
import * as moment from 'moment';
import { CMAAvatar, CMABreakcrumb } from 'root/components';
import { boardFilterDefault, IBoard, IBoardFilter, IUser, MethodType } from 'root/models';
import { IState } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Route } from 'vue-router';
import { Scrolly, ScrollyBar, ScrollyViewport } from 'vue-scrolly';
import { mapGetters, mapState } from 'vuex';
import { BoardItem } from '../Components/BoardItem';
import { MyPosition } from '../Components/MyPosition';
import { ActionTypeBoard, MutationTypeBoard } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    Scrolly,
    ScrollyViewport,
    ScrollyBar,
    'cma-breakcumb': CMABreakcrumb,
    'cma-avatar': CMAAvatar,
    'board-item': BoardItem,
    'my-position': MyPosition
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      pagination: (state: IState) => state.board.pagination,
      loading: (state: IState) => state.board.loading,
      loadingMore: (state: IState) => state.board.loadingMore,
      data: (state: IState) => state.board.data,
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      coolestKid: (state: IState) => state.board.user.coolest,
      currentUser: (state: IState) => state.board.user.currentUser,
      coolestKidLoading: (state: IState) => state.board.coolestLoading
    }),
    ...mapGetters(['ageOptions', 'genderOptions', 'methodOptions', 'gradeOptions']),
    clonecCentreList() {
      const listCentre = cloneDeep(this.listCentre);

      return [
        {
          name: 'All Centre',
          value: null
        },
        ...listCentre
      ];
    },
    cloneAgeOptions() {
      const ageOptions = cloneDeep(this.ageOptions);

      ageOptions[0].label = 'All Ages';

      return ageOptions;

    },
    cloneGenderOptions() {
      const genderOptions = cloneDeep(this.genderOptions);

      return [
        {
          label: 'All Gender',
          value: null
        },
        ...genderOptions
      ];

    },
    cloneMethodOptions() {
      const methodOptions = cloneDeep(this.methodOptions);

      return methodOptions.filter((e) => e.value === MethodType.MA || e.value === MethodType.AB || e.value === '');
    },
    cloneGradeOptions() {
      const gradeOptions = cloneDeep(this.gradeOptions);

      return [
        {
          value: null,
          label: 'All grade'
        },
        ...gradeOptions
      ];
    }
  }
})

export class BoardList extends Vue {
  public $refs: {
    leftFilter: HTMLDivElement
  };
  public pagination: IPaginationResult;
  public loading: boolean;
  public loadingMore: boolean;

  public boardFilter: string = '';
  public searchText: string = '';
  public clonecCentreList: any[];
  public ageOptions: any[];
  public genderOptions: any[];
  public currentUser: IUser;
  public coolestKid: IBoard;
  public data: IBoard[];
  public visible: boolean = false;
  public leaderBoardDetail: IBoard = null;

  public paramsFilter: IBoardFilter = boardFilterDefault();

  public async changeParamsFilter(newValue: any, key: string) {
    if (key === 'startDate' || key === 'endDate') {
      newValue = newValue ? moment(newValue).format('YYYY-MM-DD') : null;
    }
    this.paramsFilter[key] = newValue;
    const route: Route = this.$route,
      query = {
        ...route.query,
        [key]: newValue
      };
    this.$router.push({query});
    await this.$store.commit(MutationTypeBoard.clearBoardList);
    this.fetchData(true);
  }
  public enterLevel() {
    const route: Route = this.$route,
      query = {
        ...route.query,
        level: this.paramsFilter.level.toString()
      };
    this.$router.push({query});
    this.$store.commit(MutationTypeBoard.clearBoardList);
    this.fetchData(true);
  }
  public getSearchText(route: Route): any {
    const query = route.query,
      search = query.search;

    return search ? search.toString() : '';
  }

  public handleSearch() {
    this.changeParamsFilter(this.paramsFilter.fullName, 'fullName');
  }

  public boardItemClick(model: IBoard, isCoolest: boolean) {
    const _model = cloneDeep(model),
      index = findIndex(this.data, (e) => e.id === _model.id);

    this.leaderBoardDetail = <any> {
      ..._model,
      isCoolest,
      index
    };
    this.visible = true;
  }

  public beforeOpen() {
    //
  }
  public handleClickCloseDialog() {
    this.visible = false;
    this.leaderBoardDetail = null;
  }
  public currentUserIsCoolest(id: number): boolean {
    const currentUserId = this.currentUser && this.currentUser.id,
      coolestKidId = this.coolestKid && this.coolestKid.id,
      currentUserIndex = findIndex(this.data, (e) => e.id === currentUserId);

    return id && currentUserId === id && coolestKidId === id && currentUserIndex === 0;
  }
  public isCoolestKid(id: number): boolean {
    const currentUserId = this.currentUser && this.currentUser.id,
      coolestKidId = this.coolestKid && this.coolestKid.id;

    return currentUserId === id && coolestKidId === id;
  }
  public isCoolestKidAndTop(id: number): boolean {
    const currentUserId = this.currentUser && this.currentUser.id,
      currentUserIndex = findIndex(this.data, (e) => e.id === currentUserId);

    return this.isCoolestKid(id) && currentUserIndex === 0;
  }
  protected mounted() {
    this.$nextTick(() => {
      const route: Route = this.$route,
        { query } = route;
      this.paramsFilter = <any> {
        ...boardFilterDefault(),
        ...query
      };
      this.fetchData(true);
      this.$store.dispatch(ActionTypeBoard.GetCoolestKid);
      this.searchText = this.getSearchText(this.$route);

      // this.handleHoldLeftFilter();
      // window.addEventListener('scroll', this.handleHoldLeftFilter, false);
      window.addEventListener('scroll', this.handleWindowScroll, false);
    });
  }

  protected beforeDestroy() {
    // window.removeEventListener('scroll', this.handleHoldLeftFilter, false);
    window.removeEventListener('scroll', this.handleWindowScroll, false);
  }

  private handleWindowScroll() {
    const bottomOfWindow =
      (window.scrollY + window.innerHeight) >= document.body.scrollHeight - 500;

    if (bottomOfWindow &&
        (!this.loading && !this.loadingMore) &&
        this.pagination.nextPageToken) {
      const query = this.$route.query;

      this.$store.dispatch(ActionTypeBoard.FilterBoard, {
        ...query,
        nextPageToken: this.pagination.nextPageToken
      });

    }

    return;
  }

  // private handleHoldLeftFilter() {
  //   const leftFilter = this.$refs.leftFilter,
  //     bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

  //   if (bodyScrollTop >= 78) {
  //     leftFilter.classList.add('fixed');
  //   } else {
  //     leftFilter.classList.remove('fixed');
  //   }
  // }

  private fetchData(first?: boolean) {
    const query = this.$route.query,
      { startDate, endDate } = query;

    if (typeof startDate !== typeof endDate) {
      return;
    }

    window.scrollTo(0, 0);

    if (first && !query.page) {
      this.$store.dispatch(ActionTypeBoard.FilterBoard, {
        ...query,
        page: 1
      });
    } else {
      this.$store.dispatch(ActionTypeBoard.FilterBoard, {
        ...query
      });
    }
  }
}
