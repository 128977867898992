import { IPagingWordpressParams } from 'root/models';
import { IArticles } from 'root/pages/Home/Store/mutations';
import { IHttpServiceClientOptions } from '../Core';

export interface IWordpressHttpService {
  getAboutUs(): Promise<any>;
  getTestimonials(): Promise<any>;
  getArticles(type: string, pagination: IPagingWordpressParams): Promise<any>;
  getContactUs(): Promise<any>;
  getArticleDetail(id: number): Promise<IArticles>;
}

export function WordpressHttpService(options: IHttpServiceClientOptions): IWordpressHttpService {

  const baseUrl = '/word-press';

  function getAboutUs(): Promise<any> {
    const aboutUrl = options.httpService.getUrl(`${baseUrl}/about-us`, options.mock);

    return options.httpService.get(aboutUrl);
  }
  function getTestimonials(): Promise<any> {
    const aboutUrl = options.httpService.getUrl(`${baseUrl}/testimonials`, options.mock);

    return options.httpService.get(aboutUrl);
  }

  function getArticles(type: string, pagination: IPagingWordpressParams): Promise<any> {
    const articleUrl = options.httpService.getUrl(`${baseUrl}/${type}`, options.mock);

    return options.httpService.get(articleUrl, {
      params: pagination
    });
  }
  function getContactUs(): Promise<any> {
    const articleUrl = options.httpService.getUrl(`${baseUrl}/contact-us`, options.mock);

    return options.httpService.get(articleUrl);
  }

  function getArticleDetail(id: number): Promise<IArticles> {
    const articleUrl = options.httpService.getUrl(`${baseUrl}/posts/${id}`, options.mock);

    return options.httpService.get(articleUrl);
  }

  return {
    getAboutUs,
    getTestimonials,
    getArticles,
    getArticleDetail,
    getContactUs
  };
}
