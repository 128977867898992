import { cmaHttpClient } from 'root/api/http';
import { ActionType, IState } from 'root/store';
import { ActionContext } from 'vuex';
import { IArticleState } from '.';
import { ActionTypeArticle, MutationTypeArticle } from './types';

export const actions = {
  async [ActionTypeArticle.GetArticleDetail](
    {dispatch, commit }: ActionContext<IArticleState, IState>, id: number
  ) {
    commit(MutationTypeArticle.SetArticleDetailLoading);
    try {
      const data = await cmaHttpClient.wordpress.getArticleDetail(id);
      commit(MutationTypeArticle.SetArticleDetail, data);
      commit(MutationTypeArticle.SetArticleDetailLoaded);
    } catch (error) {
      commit(MutationTypeArticle.SetArticleDetailLoaded);
      dispatch(ActionType.CatchException, error);
    }
  }
};
