import { CMABreakcrumb } from 'root/components';
import { LocalStorageType } from 'root/models';
import { ActionType, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-breakcumb': CMABreakcrumb,
  }
})

export class Setting extends Vue {
  public async logout() {
    localStorage.removeItem(LocalStorageType.CurrentToken);
    this.$store.dispatch(ActionType.Unauthenticated, this.$router);
  }
  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.BreakcumbChange, [{
        label: this.$t('home'),
        url: '/'
      }, {
        label: this.$t('setting'),
        url: '/setting',
        currently: true
      }]);
    });
  }
}
