// import { Notification } from 'element-ui';
import { Dispatch } from 'vuex';

// import { IProfile } from 'root/models';
import { INotification } from 'root/models';
import { Logger as log } from 'root/services';
const OneSignal = window['OneSignal'] || [];

export interface IOptionNotification {
  user?: {
    id: number;
    centre: string;
  };
  dispatch?: Dispatch;
  onReceiveNotification?: (data: INotification) => void;
  onClickNotification?: (data: INotification) => void;
}

export const KeyUserIDForOneSignal = 'UserIdForOneSignal';

export function initNotification(appId: string, safariWebId: string, options?: IOptionNotification) {

  try {

    log.debug('Initial onesignal with appId: ' + safariWebId);
    OneSignal.push(() => {
      OneSignal.init({
        appId,
        safari_web_id: safariWebId,
        autoResubscribe: true,
      });
    });

    OneSignal.push(() => {
      OneSignal.isPushNotificationsEnabled().then((enabled) => {
        log.debug('Notification enabled init: ' + enabled);
      });
    });

    OneSignal.push(() => {
      OneSignal.registerForPushNotifications();
    });

    OneSignal.push(() => {
      OneSignal.on('subscriptionChange', (isSubscribed) => {
        log.debug('The user\'s subscription state is now:', isSubscribed);
        if (isSubscribed) {
          OneSignal.getUserId().then((userId) => {
            if (userId) {
              handleUserForSubscribed(options);
            }

            return;
          });
        }

        return;
      });

    });

    OneSignal.push(() => {
      OneSignal.on('notificationPermissionChange', (permissionChange) => {
        const currentPermission = permissionChange.to;
        log.debug('New permission state:', currentPermission);
      });
    });

    OneSignal.push(() => {
      OneSignal.on('notificationDisplay', (notification) => {
        onReceiveNotification(notification, options);
      });
    });

  } catch (error) {
    console.error('init notification failed: ', error);
  }

}

export async function checkPermission(method, args) {
  try {
    if (!OneSignal) { return; }
    const isEnabled: boolean = await OneSignal.isPushNotificationsEnabled();
    console.log('checkPermission enabled', isEnabled);

    if (isEnabled) {
      const userId = await OneSignal.getUserId();
      if (userId) {
        OneSignal[method](...args);
      }

      return Promise.resolve();
    }

  } catch (error) {
    log.debug('checkPermission', error);
  }
}
export async function _checkPermission(method, args) {
  return new Promise(async (resolve, reject) => {
    try {
      if (!OneSignal) {
        reject('OneSignal is undefined!');
      }

      const userId = await OneSignal.getUserId();

      if (userId) {
        const sendTagStatus = await OneSignal[method](...args);
        // log.debug('sendTagStatus', sendTagStatus);

        return resolve(sendTagStatus);
      }

      return reject('userId is undefined');
    } catch (error) {
      reject(error);
    }
  });
}

export async function sendTagForNotification(data: IOptionNotification) {
  log.debug('Onesignal set tags: ', `${data.user.id} ${data.user.centre}`);
  // log.debug('Onesignal set tag: userId = ' + data.user.id);
  await _checkPermission('sendTag', ['userId', data.user.id]);
  await _checkPermission('sendTag', ['centre', data.user.centre]);
  // await checkPermission('sendTags', {
  //   centre: data.user.centre,
  //   userId: data.user.id
  // });
  log.debug('Onesignal set tags successfully');
}

export async function deleteTagForNotification() {
  log.debug('Onesignal delete tag: userId');
  await _checkPermission('deleteTag', ['userId']);
  log.debug('Onesignal delete tag: centre');
  await _checkPermission('deleteTag', ['centre']);
  // log.debug('Onesignal logout email');
  // OneSignal.logoutEmail();
}

export function handleUserForSubscribed(options: IOptionNotification) {
  log.debug('Handle subscribed', options.user);
  // const userId = localStorage.getItem(KeyUserIDForOneSignal);
  if (options.user && options.user.id) {
    sendTagForNotification({
      user: options.user
    });
  }

  return;
}

export function onReceiveNotification(notify: INotification, options: IOptionNotification) {
  if (notify && notify.id) {
    options.onReceiveNotification(notify);

    // const endClass = '';
    // switch (notify.data.type) {
    // default:
    //   break;
    // }

    // const instanceNotification: any = Notification({
    //   duration: 5000,
    //   title: notify.heading,
    //   customClass: `cma-notification-top cma-notification-top--${endClass}`,
    //   dangerouslyUseHTMLString: true,
    //   // message: `<p class="text"><span class="cma-icon cma-icon__${endClass}"></span>${data.content}</p>`,
    //   message: `<p class="text">${notify.content}</p>`,
    //   offset: 35,
    //   showClose: true,
    //   onClick: () => {
    //     options.onClickNotification(notify);
    //     instanceNotification.close();
    //   }
    // });
  }

  return;
}
