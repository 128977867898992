export enum GameStage {
  FirstScreen = 'first-screen',
  ProgressiveGame = 'progressive-game'
}

export enum gameConfigLocal {
  flashConfigLocal = 'flashConfigLocal',
  speedConfigLocal = 'speedConfigLocal',
  progressiveConfigLocal = 'progressiveConfigLocal',
  auralMentalConfigLocal = 'auralMentalConfigLocal'
}

export interface IProgressiveConfig {
  numbers?: number;
  digit?: number;
  allowDecimal?: boolean;
  allowNegative?: boolean;
  configDigit?: IConfigDigit;
  operators?: Operator[];
  numberQuestion?: number;
  operatorTab?: OperatorTab;
  enableCurrency?: boolean;
}

export interface IFlashConfig extends IProgressiveConfig {
  onScreen?: number;
  answerTime?: number;
  delayNumber?: number;
  delayQuestion?: number;
}

export interface ISpeedConfig {
  numbers?: number;
  digit?: number;
  speed: number;
  operators: Operator[];
  allowNegative: boolean;
  allowDecimal: boolean;
}

export interface IAuralConfig {
  numbers?: number;
  digit?: number;
  operators: Operator[];
  numberQuestion: number;
  speed: number;
  operatorTab?: OperatorTab;
}
export enum SpeedType {
  Low = 'low',
  Medium = 'medium',
  High = 'high'
}

export interface IConfigDigit {
  first: number;
  second: number;
}

export enum Operator {
  Plus = '+',
  Sub = '-',
  Multi = '*',
  Div = '/'
}

export interface IProgressive {
  expression: string;
  result: number;
  point?: number;
  isCurrency: boolean;
}

export interface IGameLevel {
  questionConfig?: IFlashSmarterGameConfig;
  typeDisplayGame?: string;
  questions: IProgressive[];
  star: number[];
  totalTime: number;
  type: MethodType;
  description?: string;
}

export interface IQuestions extends IProgressive {
  score: number;
}

export interface IBonusGame {
  questions: IQuestions[];
  totalTime: number;
}

export enum BonusTypes {
  Mental = 'mental',
  Abacus = 'abacus',
  Flash = 'flash'
}

export const bonusStoreDefault = (): IBonusGame => {
  return {
    questions: [],
    totalTime: 0
  };
};

export const speedConfigDefault = (): ISpeedConfig => {
  return {
    digit: 2,
    numbers: 2,
    speed: 50,
    operators: [
      Operator.Plus,
      Operator.Sub
    ],
    allowNegative: false,
    allowDecimal: false
  };
};

export const flashConfigDefault = (tab?: OperatorTab): IFlashConfig => {
  if (tab === OperatorTab.Addition) {
    return {
      operatorTab: OperatorTab.Addition,
      numberQuestion: 2,
      numbers: 2,
      digit: 2,
      onScreen: 2,
      answerTime: 2,
      delayNumber: 2,
      delayQuestion: 2,
      operators: [
        Operator.Plus
      ],
      allowNegative: false,
      allowDecimal: false
    };
  }

  return {
    operatorTab: OperatorTab.AddtionAndSubtraction,
    numberQuestion: 2,
    numbers: 2,
    digit: 2,
    onScreen: 2,
    answerTime: 2,
    delayNumber: 2,
    delayQuestion: 2,
    operators: [
      Operator.Plus,
      Operator.Sub
    ],
    allowNegative: false,
    allowDecimal: false
  };
};

export const auralConfigDefault = (tab?: OperatorTab): IAuralConfig => {
  switch (tab) {
  case OperatorTab.AddtionAndSubtraction:
    return {
      operatorTab: OperatorTab.AddtionAndSubtraction,
      digit: 2,
      numbers: 2,
      operators: [
        Operator.Plus,
        Operator.Sub
      ],
      numberQuestion: 2,
      speed: 1
    };
  default:
    return {
      operatorTab: OperatorTab.Addition,
      digit: 2,
      numbers: 2,
      operators: [
        Operator.Plus
      ],
      numberQuestion: 2,
      speed: 1
    };
  }
};

export const progressiveConfigDefault = (tab?: OperatorTab): IProgressiveConfig => {
  switch (tab) {
  case OperatorTab.Addition:
    return {
      allowNegative: false,
      enableCurrency: false,
      digit: 1,
      numbers: 2,
      operators: [
        Operator.Plus
      ],
      numberQuestion: 4,
      operatorTab: OperatorTab.Addition
    };
  case OperatorTab.AddtionAndSubtraction:
    return {
      allowNegative: false,
      enableCurrency: false,
      digit: 1,
      numbers: 2,
      operators: [
        Operator.Plus,
        Operator.Sub
      ],
      numberQuestion: 4,
      operatorTab: OperatorTab.AddtionAndSubtraction
    };
  case OperatorTab.Multiplication:
    return {
      numberQuestion: 4,
      configDigit: {
        first: 1,
        second: 1
      },
      operators: [
        Operator.Multi
      ],
      operatorTab: OperatorTab.Multiplication
    };
  case OperatorTab.Division:
    return {
      numberQuestion: 4,
      configDigit: {
        first: 1,
        second: 1
      },
      operators: [
        Operator.Div
      ],
      operatorTab: OperatorTab.Division
    };
  default:
    return {
      allowNegative: false,
      enableCurrency: false,
      digit: 1,
      numbers: 2,
      operators: [
        Operator.Plus
      ],
      numberQuestion: 4,
      operatorTab: OperatorTab.Addition
    };
  }
};

export interface IProgressiveForm {
  result: string;
}

export enum MethodType {
  Progressive = 'progressive',
  Abacus = 'abacus',
  Flash = 'flash',
  FM = 'fm',
  SpeedChallenge = 'speed_challenge',
  // AuralMental
  DailyBonus = 'daily_bonus',
  AbacusArithmetic = 'ab',
  MentalArithmetic = 'ma',
  AuralMental = 'ama',
  AMA = 'ama',
  FlashBonus = 'flash_bonus',
  Empty = '',
  MA = 'ma',
  AB = 'ab'
}

export enum OperatorTab {
  Addition = 'Addition',
  AddtionAndSubtraction = 'AddtionAndSubtraction',
  Multiplication = 'Multiplication',
  Division = 'Division'
}

export interface IGameResultInfo {
  correct: number;
  totalTime: number;
  totalPoint?: number;
  results: any[];
}

export interface IGameHistoryMetadata {
  totalQuestion: number;
  correctQuestion: number;
  totalTime: number;
  star?: number;
  level?: number;
}

export interface IGameHistory {
  typeMethod: MethodType;
  metadata: IGameHistoryMetadata;
  typeGame: GameType;
  id?: number;
  createdAt?: Date;
  score?: number;
  createdBy?: number;
}

export enum GameType {
  Practices = 'practices',
  DailyBonus = 'daily_bonus',
  ExtraBonus = 'extra_bonus',
  SmarterGame = 'smarter_game',
  Assignment = 'assignment'
}

export const gameHistoryForm = (): IGameHistory => {
  return {
    metadata: {
      correctQuestion: 0,
      totalQuestion: 0,
      totalTime: 0
    },
    typeGame: null,
    typeMethod: null,
  };
};

export interface IFlashGameConfigGlobal {
  onScreen: number;
  timeBetweenQuestion: number;
  timeBetweenNumber: number;
  answerDelay: number;
  numbers?: number;
}
export interface IAudioConfigGlobal {
  answersDelay: number;
  questionDelay: number;
  numberDelay: number;
}
export interface IFlashSmarterGameConfig {
  answersDelay: number;
  flashDuration: number;
  questionDelay: number;
}

export interface IGameStarsConfigGlobal {
  countStar: number;
  percent: number;
}

export interface IGameMap {
  id: number;
  level: number;
  star: number;
}

export interface IGameMapResult {
  data: IGameMap[];
  currentLevel: number;
}

export const showAnswerTimeDefault: number = 2000; // 2s
