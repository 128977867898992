import { CMABreakcrumb } from 'root/components';
import { subscriptionDefaut, ISubscription, PlanType, ProcessingSubscription } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { CreditCard } from '../Components/CreditCard';
import { CurrentlySubscription } from '../Components/CurrentlySubscription';
import { SubcriptionAddCard } from '../Components/SubcriptionAddCard';
import { SubscriptionOption } from '../Components/SubscriptionOption';
import { ActionTypeSubscription } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-breakcumb': CMABreakcrumb,
    'subscription-add-card': SubcriptionAddCard,
    'currently-subscription': CurrentlySubscription,
    'subscription-option': SubscriptionOption,
    'credit-card': CreditCard
  },
  computed: {
    ...mapState({
      current: (state: IState) => state.subscription.current,
      authUser: (state: IState) => state.global.authUser
    }),
    currentSubscription(): ISubscription {
      return this.current.subscription ? this.current.subscription : subscriptionDefaut();
    },
    scheduledPlan(): ISubscription {
      return this.current.scheduleSubscription ? this.current.scheduleSubscription : subscriptionDefaut();
    },
    allowCancelPlan() {
      return (this.currentSubscription.processing === ProcessingSubscription.Success
        && this.currentSubscription.plan.type !== PlanType.Free)
        || this.scheduledPlan.id;
    }
  },
  watch: {
    addCardVisible(newValue) {
      if (newValue === false) {
        this.isUpdateCard = false;
      }
    }
  }
})

export class Subscription extends Vue {
  public addCardVisible: boolean = false;
  public isUpdateCard: boolean = false;

  public editCreditCard() {
    this.addCardVisible = true;
    this.isUpdateCard = true;
  }
  public showAddCreditCard() {
    this.addCardVisible = true;
  }
  public cancelPlan() {
    this.$store.dispatch(ActionTypeSubscription.ShowPopupCancelMember);
  }
  protected mounted() {
    this.$nextTick(async () => {
      this.$store.commit(MutationType.BreakcumbChange, [{
        label: this.$t('account'),
        url: '/'
      }, {
        label: this.$t('subscription'),
        url: '',
        currently: true
      }]);
      this.$store.dispatch(ActionTypeSubscription.GetStripeCardList);
    });
  }
}
