import { CMAHeader } from 'root/components';
import { FooterGuest } from 'root/components/FooterGuest';
import { RouteDictionary } from 'root/router';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'footer-guest': FooterGuest
  },
})

export class LoginLayout extends Vue {
  public get isShowHeader(): boolean {
    const path = this.$route.path;

    return path !== RouteDictionary.Login
      && path !== RouteDictionary.Register
      && path !== RouteDictionary.ForgotPassword;
  }
}
