export enum MutationTypeRedeemed {
  RedeemedLoading = 'RedeemedLoading',
  RedeemedLoaded = 'RedeemedLoaded',
  RedeemedLoadingMore = 'RedeemedLoadingMore',
  RedeemedLoadedMore = 'RedeemedLoadedMore',
  SetRedeemedList = 'setRedeemedList',
  clearRedeemedList = 'clearRedeemedList',
  UpdateRedeemedList = 'updateRedeemedList',
  SetRedeemedGame = 'SetRedeemedGame',
  ResetRedeemedGame = 'ResetRedeemedGame',

  RedeemProductLoading = 'RedeemProductLoading',
  RedeemProductLoaded = 'RedeemProductLoaded'
}

export enum ActionTypeRedeemed {
  UpdateRedeemed = 'updateRedeemed',
  LoadingRedeemed = 'loadingRedeemed',
  RedeemedFilterNoCache = 'RedeemedFilterNoCache',
  FilterRedeemed = 'FilterRedeemed',
  GetRedeemedGame = 'GetRedeemedGame',

  RedeemProduct = 'RedeemProduct',
  RedeemGetById = 'RedeemGetById'
}

export enum GetterTypeRedeemed {
  GetRedeemedDetail = 'redeemedDetail'
}
