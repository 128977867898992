export enum ActionTypeNotification {
  FilterNotification = 'filterNotification',
  NotificationFindById = 'notificationFindById'
}

export enum MutationTypeNotification {
  NotificationLoading = 'notificationLoading',
  NotificationLoaded = 'notificationLoaded',
  NotificationLoadingMore = 'notificationLoadingMore',
  NotificationLoadedMore = 'notificationLoadedMore',
  SetNotificationList = 'setNotificationList',
  UpdateNotificationList = 'updateNotificationList',
  ClearNotificationList = 'clearNotificationList'
}

export enum GetterTypeNotification {
  GetNotificationById = 'GetNotificationById'
}
