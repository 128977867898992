import { cloneDeep, findIndex, map } from 'lodash';
import { boardFilterDefault, userDefault, IBoard, IUser } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeBoard } from './types';

export interface IBoardState extends ICRUDState<IBoard> {
  loading: boolean;
  loadingMore: boolean;
  user: {
    coolest: IBoard;
    currentUser: IUser;
  };
  coolestLoading: boolean;
}

export const defaultState: IBoardState = {
  ...CRUDState(),
  loading: false,
  loadingMore: false,
  user: {
    coolest: boardFilterDefault(),
    currentUser: userDefault()
  },
  coolestLoading: false,
  filterParams: {
    method: '',
    status: ''
  }
};

export const mutations = {
  ...crudMutations('Board'),
  [MutationTypeBoard.BoardLoading](state: IBoardState) {
    state.loading = true;
  },
  [MutationTypeBoard.BoardLoaded](state: IBoardState) {
    state.loading = false;
  },
  [MutationTypeBoard.BoardLoadingMore](state: IBoardState) {
    state.loadingMore = true;
  },
  [MutationTypeBoard.BoardLoadedMore](state: IBoardState) {
    state.loadingMore = false;
  },
  [MutationTypeBoard.SetCoolestKidLoading](state: IBoardState) {
    state.coolestLoading = true;
  },
  [MutationTypeBoard.SetCoolestKidLoaded](state: IBoardState) {
    state.coolestLoading = false;
  },
  [MutationTypeBoard.SetBoardList](state: IBoardState, payload) {
    state.data = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeBoard.clearBoardList](state: IBoardState) {
    state.data = [];
    state.pagination = {
      page: 1
    };
  },
  [MutationTypeBoard.UpdateBoardList](state: IBoardState, payload) {
    let nextData: IBoard[] = payload.data;
    const data = cloneDeep(state.data);
    nextData = nextData.filter((e) => e);

    map(nextData, (_nextData) => {
      if (findIndex(data, (e) => e.id === _nextData.id) < 0) {
        data.push(_nextData);
      }

      return;
    });

    state.data = data;
    state.pagination = payload.pagination;
  },
  [MutationTypeBoard.SetCoolestKid](state: IBoardState, payload) {
    state.user = payload;
  }
};
