import { cloneDeep, isNull, omit } from 'lodash';
import { auralConfigDefault, flashConfigDefault,
  gameConfigLocal, progressiveConfigDefault, speedConfigDefault, IAuralConfig,
  IBonusGame, IFlashConfig, IFlashSmarterGameConfig,
  IGameLevel, IGameResultInfo, IProgressive, IProgressiveConfig,
  ISpeedConfig, MethodType, OperatorTab } from 'root/models';
import { MutationTypeGame } from './types';

export interface IGameState {
  data: IProgressive[];
  progressiveLoading: boolean;
  progressiveConfig: IProgressiveConfig;
  progressiveQuestion: number;
  timing: number;
  result: IGameResultInfo;
  speedConfig: ISpeedConfig;
  flashConfig: IFlashConfig;
  auralConfig: IAuralConfig;
  bonusLoading: boolean;
  totalTime: number;
  methodType: MethodType;
  stars: number[];
  gameLevelPlaying: number;
  currentMapLevel: number;
  isGameRouter: boolean;
  description: string;
  flashSmarterGameConfig: IFlashSmarterGameConfig;
  typeDisplayGame: string;
  allowMusic: boolean;
}

export const defaultState: IGameState = {
  data: [],
  progressiveLoading: false,
  progressiveConfig: progressiveConfigDefault(),
  speedConfig: speedConfigDefault(),
  progressiveQuestion: 1,
  timing: 0,
  result: {
    correct: 0,
    totalTime: 0,
    totalPoint: 0,
    results: []
  },
  flashConfig: flashConfigDefault(),
  auralConfig: auralConfigDefault(),
  bonusLoading: false,
  totalTime: 0,
  methodType: MethodType.Empty,
  stars: [],
  gameLevelPlaying: 0,
  currentMapLevel: 0,
  isGameRouter: false,
  description: '',
  flashSmarterGameConfig: null,
  typeDisplayGame: '',
  allowMusic: true
};

export const mutations = {
  [MutationTypeGame.SetProgressive](state: IGameState, data: IProgressive[]) {
    state.data = data;
  },
  [MutationTypeGame.SetAbacus](state: IGameState, data: IGameLevel) {
    const _progressiveConfig = cloneDeep(state.progressiveConfig);

    state.data = data.questions;
    state.progressiveConfig = {
      ..._progressiveConfig,
      numberQuestion: data.questions.length
    };
    state.timing = data.totalTime;
    state.totalTime = data.totalTime;
    state.methodType = data.type;
    state.typeDisplayGame = data.typeDisplayGame;
    state.stars = data.star;
    state.description = data.description || '';
    if (data.typeDisplayGame === 'flash') {
      state.flashSmarterGameConfig = data.questionConfig;
    }
  },
  [MutationTypeGame.SetProgressiveLoading](state: IGameState) {
    state.progressiveLoading = true;
  },
  [MutationTypeGame.SetProgressiveLoaded](state: IGameState) {
    state.progressiveLoading = false;
  },
  [MutationTypeGame.SetProgressiveNextQuestion](state: IGameState) {
    state.progressiveQuestion = isNull(state.progressiveQuestion) ? 2 : state.progressiveQuestion + 1;
  },
  [MutationTypeGame.StartTiming](state: IGameState, countDown?: boolean) {
    state.timing = countDown ? state.timing - 1 : state.timing + 1;
  },
  [MutationTypeGame.StopTiming](state: IGameState) {
    state.timing = 0;
  },
  [MutationTypeGame.ProgressiveConfigSetTab](state: IGameState, data: OperatorTab) {
    const _progressiveConfig = cloneDeep(state.progressiveConfig);

    state.progressiveConfig = {
      ..._progressiveConfig,
      operatorTab: data
    };
  },
  [MutationTypeGame.ProgressiveSetConfig](state: IGameState, data: IProgressiveConfig) {
    state.progressiveConfig = data;
    state.progressiveQuestion = 1;
    localStorage.setItem(gameConfigLocal.progressiveConfigLocal, JSON.stringify(data));
  },
  [MutationTypeGame.SpeedSetConfig](state: IGameState, data: ISpeedConfig) {
    state.speedConfig = data;
    state.progressiveQuestion = 1;
    localStorage.setItem(gameConfigLocal.speedConfigLocal, JSON.stringify(data));
  },
  [MutationTypeGame.AuralSetConfig](state: IGameState, data: IAuralConfig) {
    state.auralConfig = data;
    state.progressiveQuestion = 1;
    localStorage.setItem(gameConfigLocal.auralMentalConfigLocal, JSON.stringify(data));
  },
  [MutationTypeGame.AuralConfigSetTab](state: IGameState, payload: OperatorTab) {
    state.auralConfig.operatorTab = payload;
  },
  [MutationTypeGame.ResetGameState](state: IGameState) {
    state.progressiveQuestion = 1;
    state.timing = 0 ;
    state.data = [];
    state.result = {
      correct: 0,
      totalTime: 0,
      totalPoint: 0,
      results: []
    };
    state.totalTime = 0;
    state.methodType = MethodType.Empty;
    state.stars = [];
    state.description = '';
  },
  [MutationTypeGame.AddCorrect](state: IGameState, point?: number) {
    const result = state.result;
    state.result = {
      ...result,
      correct: result.correct + 1,
      totalTime: state.timing,
      totalPoint: result.totalPoint + point
    };
  },
  [MutationTypeGame.UpdateGameResult](state: IGameState, payload: {question: any, answer: string}) {
    const result = cloneDeep(state.result),
      { results } = result;
    let {totalPoint, correct} = result;
    payload.question.result = payload.question.result.toString();
    payload.question.expression = payload.question.expression.replace(/\,/g, '');

    if (payload.question.result === payload.answer.replace(/\,/g, '')) {
      totalPoint = totalPoint + payload.question.point;
      correct = correct + 1;
    }

    const question = <any> {
      ...omit(payload.question, 'point'),
      answer: payload.answer.replace(/\,/g, '')
    };

    results.push(question);

    state.result = {
      ...result,
      results,
      correct,
      totalPoint,
      totalTime: state.timing,
    };

  },
  [MutationTypeGame.SetResultTime](state: IGameState, {time, methodType}) {
    if (methodType === MethodType.DailyBonus
      || methodType === MethodType.FlashBonus
      || methodType === MethodType.AuralMental
      || methodType === MethodType.SpeedChallenge
      || methodType === MethodType.MA
      || methodType === MethodType.AMA
      || methodType === MethodType.AB
      || methodType === MethodType.FM) {
      state.result.totalTime = state.totalTime - time;

      return;
    }
    state.result.totalTime = time;
  },
  [MutationTypeGame.FlashSetConfig](state: IGameState, data: IFlashConfig) {
    state.flashConfig = data;
    state.progressiveQuestion = 1;
    localStorage.setItem(gameConfigLocal.flashConfigLocal, JSON.stringify(data));
  },
  [MutationTypeGame.FlashConfigSetTab](state: IGameState, data: OperatorTab) {
    const flashConfig = cloneDeep(state.flashConfig);

    state.flashConfig = {
      ...flashConfig,
      operatorTab: data
    };
  },
  [MutationTypeGame.SetBonusGame](state: IGameState, data: IBonusGame) {
    state.data = data.questions;
    state.timing = data.totalTime;
    state.totalTime = data.totalTime;
  },
  [MutationTypeGame.SetBonusLoading](state: IGameState) {
    state.bonusLoading = true;
  },
  [MutationTypeGame.SetBonusLoaded](state: IGameState) {
    state.bonusLoading = false;
  },
  [MutationTypeGame.SetGameLevelPlaying](state: IGameState, level: number) {
    state.gameLevelPlaying = level;
  },
  [MutationTypeGame.SetTotalTime](state: IGameState, time: number) {
    state.totalTime = time;
    state.timing = time;
  },
  [MutationTypeGame.SetCurrentMapLevel](state: IGameState, level: number) {
    state.currentMapLevel = level;
  },
  [MutationTypeGame.SetGameRouter](state: IGameState, payload: boolean) {
    state.isGameRouter = payload;
  },
  [MutationTypeGame.AllowMusic](state: IGameState, payload: boolean) {
    state.allowMusic = payload;
  }
};
