import * as moment from 'moment';
import { stripHtml } from 'root/helpers';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ArticlesType } from '../../Store/mutations';
import { ActionTypeHome } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.home.articles,
      loading: (state: IState) => state.home.articlesLoading
    })
  }
})
export class HomeArticle extends Vue {
  public tabs: string[] = [
    ArticlesType.News,
    ArticlesType.Events,
    ArticlesType.Promotion,
    ArticlesType.Blog
  ];
  public activeTab: ArticlesType = ArticlesType.News;

  public changeTab(tab: ArticlesType) {
    this.activeTab = tab;
    this.$store.dispatch(ActionTypeHome.GetArticles, {
      type: tab
    });
  }

  public formatterTiming(model: string) {
    return moment(model).format('DD MMM YYYY');
  }

  public getpostExcerpt(excerpt: string, content: string) {
    if (excerpt) {
      return excerpt;
    }
    const text = stripHtml(content);
    let textArr = text.split(' ');
    textArr.length = 55;
    textArr = textArr.filter((e) => e);

    return textArr.length === 55 ? `${textArr.join(' ')}...` : textArr.join(' ');
  }
  public onTouch(el: Element) {
    const id = el.getAttribute('data-id');
    this.$router.push(`/article/post/${id}`);
  }
  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeHome.GetArticles, {
        type: ArticlesType.News,
        pagination: {
          page: 1
        }
      });
    });
  }
}
