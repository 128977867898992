import { CMABreakcrumb } from 'root/components';
import { IState, MutationType } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { NotificationItem } from '../Components/NotificationItem';
import { ActionTypeNotification } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-breakcumb': CMABreakcrumb,
    'notification-item': NotificationItem
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.notification.data,
      loading: (state: IState) => state.notification.loading,
      pagination: (state: IState) => state.notification.pagination,
      loadingMore: (state: IState) => state.notification.loadingMore,
    })
  }
})

export class NotificationList extends Vue {
  public loading: boolean;
  public loadingMore: boolean;
  public pagination: IPaginationResult;

  protected mounted() {
    this.$nextTick(() => {
      this.setBreakcumb();
      this.fetchData(true);
      window.addEventListener('scroll', this.handleWindowScroll, false);
    });
  }
  protected beforeDestroy() {
    window.removeEventListener('scroll', this.handleWindowScroll, false);
  }

  private handleWindowScroll() {
    const bottomOfWindow =
      (window.scrollY + window.innerHeight) >= document.body.scrollHeight - 500;

    if (bottomOfWindow &&
        (!this.loading && !this.loadingMore) &&
        this.pagination.nextPageToken) {

      this.$store.dispatch(ActionTypeNotification.FilterNotification, {
        nextPageToken: this.pagination.nextPageToken
      });

    }

    return;
  }

  private setBreakcumb() {
    this.$store.commit(MutationType.BreakcumbChange, [{
      label: this.$t('account'),
      url: '/account'
    }, {
      label: this.$t('notification'),
      url: '',
      currently: true
    }]);
  }

  private fetchData(first: boolean = false) {
    const query = this.$route.query;

    if (first && !query.page) {
      this.$store.dispatch(ActionTypeNotification.FilterNotification, {
        ...query,
        page: 1
      });
    } else {
      this.$store.dispatch(ActionTypeNotification.FilterNotification, {
        ...query
      });
    }
  }
}
