import { cloneDeep, findIndex, map } from 'lodash';
import { IRedeemed } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeRedeemed } from './types';

export interface IRedeemedState extends ICRUDState<IRedeemed> {
  loading: boolean;
  loadingMore: boolean;
  redeemLoading: boolean;
}

export const defaultState: IRedeemedState = {
  ...CRUDState(),
  loading: false,
  loadingMore: false,
  filterParams: {
    method: '',
    status: ''
  },
  redeemLoading: false
};

export const mutations = {
  ...crudMutations('redemption'),
  [MutationTypeRedeemed.RedeemedLoading](state: IRedeemedState) {
    state.loading = true;
  },
  [MutationTypeRedeemed.RedeemedLoaded](state: IRedeemedState) {
    state.loading = false;
  },
  [MutationTypeRedeemed.RedeemedLoadingMore](state: IRedeemedState) {
    state.loadingMore = true;
  },
  [MutationTypeRedeemed.RedeemedLoadedMore](state: IRedeemedState) {
    state.loadingMore = false;
  },
  [MutationTypeRedeemed.SetRedeemedList](state: IRedeemedState, payload) {
    state.data = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeRedeemed.clearRedeemedList](state: IRedeemedState) {
    state.data = [];
    state.pagination = {
      page: 1
    };
  },
  [MutationTypeRedeemed.UpdateRedeemedList](state: IRedeemedState, payload) {
    let nextData: IRedeemed[] = payload.data;
    const data = cloneDeep(state.data);
    nextData = nextData.filter((e) => e);

    map(nextData, (_nextData) => {
      if (findIndex(data, (e) => e.id === _nextData.id) < 0) {
        data.push(_nextData);
      }

      return;
    });

    state.data = data;
    state.pagination = payload.pagination;
  },
  [MutationTypeRedeemed.RedeemProductLoading](state: IRedeemedState) {
    state.redeemLoading = true;
  },
  [MutationTypeRedeemed.RedeemProductLoaded](state: IRedeemedState) {
    state.redeemLoading = false;
  }
};
