import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      cards: (state: IState) => state.subscription.cards
    }),
    card() {
      const cards = this.cards;

      return cards && cards.length > 0 ? cards[0] : {};
    }
  }
})

export class CreditCard extends Vue {
  public card: any;
  public get icon(): string {
    switch (this.card.brand) {
    case 'Diners Club':
      return 'icon-diners-club-brands';
    case 'Visa':
    case 'Visa (debit)':
      return 'cc-visa';
    case 'MasterCard':
      return 'cc-mastercard';
    case 'Discover':
      return 'icon-discover-brands';
    case 'JCB':
      return 'icon-jcb';
    case 'American Express':
      return 'icon-amex-brands';
    default:
      break;
    }
  }
  public click() {
    this.$emit('click');
  }
}
