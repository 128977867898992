import { formatterTimeBySeconds } from 'root/helpers';
import { GameType, IGameResultInfo, MethodType } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { MutationTypeGame } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    dialogVisible: Boolean,
    methodType: String,
    gameType: String,
    star: Number
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.game.result,
      progressiveQuestion: (state: IState) => state.game.progressiveQuestion,
      length: (state: IState) => state.game.data.length,
      gameLevelPlaying: (state: IState) => state.game.gameLevelPlaying,
      currentMapLevel: (state: IState) => state.game.currentMapLevel
    })
  }
})
export class GameResultInfo extends Vue {
  public data: IGameResultInfo;
  public methodType: MethodType;
  public methodTypeProgressive = MethodType.Progressive;
  public progressiveQuestion: number;
  public length: number;
  public gameType: GameType;
  public star: number;
  public gameLevelPlaying: number;
  public currentMapLevel: number;

  public get formatterTiming() {
    const seconds = this.data ? this.data.totalTime : 0;

    return formatterTimeBySeconds(seconds);
  }
  public get totalQuestion(): number {
    if (this.methodType === MethodType.SpeedChallenge) {
      return this.progressiveQuestion;
    }

    return this.length;
  }

  public get isShowStars(): boolean {
    return this.gameType === GameType.SmarterGame;
  }

  public get isShowButtonNext(): boolean {
    return this.gameType === GameType.SmarterGame && this.star >= 1;
  }

  public get getTitle(): string {
    if (this.gameType === GameType.SmarterGame) {
      return 'Level complete';
    }
    switch (this.methodType) {
    case MethodType.DailyBonus:
      return 'Bonus Complete';
    default:
      return 'Complete';
    }
  }

  public get isShowTotalPoint(): boolean {
    return this.methodType === MethodType.DailyBonus
      || this.gameType === GameType.SmarterGame
      || this.gameType === GameType.ExtraBonus
      || this.gameType === GameType.Assignment;
  }

  public get isShowPlayAgain(): boolean {
    return this.methodType !== MethodType.DailyBonus
      && this.gameType !== GameType.ExtraBonus
      && this.methodType !== MethodType.AuralMental
      && this.gameType !== GameType.Assignment;
  }

  public get correctOfAMAPractice(): number {
    if (this.gameType === GameType.Practices && this.methodType === MethodType.AuralMental) {
      return this.totalQuestion;
    }

    return null;
  }

  public get isLevelComplete(): boolean {
    return !!(this.gameType === GameType.SmarterGame && this.gameLevelPlaying === this.currentMapLevel);
  }

  public handlePrimaryClick() {
    this.$emit('handlePrimaryClick');
  }
  public handleSecondaryClick() {
    this.$emit('handleSecondaryClick');
  }
  public handleClickGoNext() {
    if (this.isLevelComplete && this.gameLevelPlaying === 480) {
      this.$alert(`You've completed all the game level.`, 'Congratulation!', {
        confirmButtonText: 'OK',
        customClass: 'game-alert-completed',
        showClose: false,
        confirmButtonClass: 'game-alert-completed__btn',
        callback: (action) => {
          if (action === 'confirm') {
            this.handleSecondaryClick();

          }

          return;
        }
      });

      return;
    }
    if (this.isLevelComplete && this.isShowButtonNext) {
      this.$store.commit(MutationTypeGame.SetCurrentMapLevel, this.currentMapLevel + 1);
    }
    this.$emit('handleClickGoNext');
  }
}
