import { StatusCode } from './Common';
import { MethodType } from './Game';
import { IUser } from './User';

export interface IRedeemed {
  id: number;
  owner?: IUser;
  centre: string;
  product: IProduct;
  qty: number;
  progress: RedemptionStatus;
  createdAt: Date;
}

export interface IRedemption {
  id: number;

}

export interface IRedeemedLibrary {
  id: number;
  method: MethodType;
  grage: number;
}

export interface IRedeemedOwner {
  id: number;
  fullName: string;
  centre: string;
}

export enum RedeemedStatus {
  New = 'new',
  Completed = 'completed',
  Overdue = 'overdue',
  Default = ''
}

export interface IRedeemedFilter {
  method: MethodType;
  status: RedeemedStatus;
  page?: number;
  nextPageToken?: string;
}

export interface IRedeemedGame {
  type: MethodType;
  id: number;
}

export enum RedeemedFilter {
  EarnedPoints = 'earned',
  RedeemedPoints = 'redeemed'
}

export interface IProduct {
  id: number;
  status?: StatusCode;
  level: number | string;
  point: number | string;
  title: string;
  desc: string;
  images: IProductAttchment[];
}
export interface IProductAttchment {
  src: string;
  isPrimary?: boolean;
}

export enum RedemptionStatus {
  Processing = 'processing',
  Completed = 'completed',
  Rejected = 'rejected'
}
