// register the plugin
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// import page
import { HomeContainer } from '../pages/Home';

import { ArticleContainer } from 'root/pages/Article/ArticleContainer';
import { Blog } from 'root/pages/Blog/component';
import { BoardRouter } from 'root/pages/Board';
import { ContactUsRouter } from 'root/pages/ContactUs';
import { ForgotPassword } from 'root/pages/ForgotPassword';
import { Game } from 'root/pages/Game';
import { FirstScreen } from 'root/pages/Game/Components/FirstScreen';
import { GameAbacus } from 'root/pages/Game/Layouts/GameAbacus';
import { GameDailyBonus } from 'root/pages/Game/Layouts/GameDailyBonus';
import { GameExtraBonus } from 'root/pages/Game/Layouts/GameExtraBonus';
import { GamePractices } from 'root/pages/Game/Layouts/Practices';
import { LoginContainer } from 'root/pages/Login';
import { NotificationRouter } from 'root/pages/Notification';
import { ProfileRouter } from 'root/pages/Profile';
// import { ProfileContainer } from 'root/pages/Profile/ProfileContainer';
import { Register } from 'root/pages/Register';
import { SubscriptionRouter } from 'root/pages/Subcription';
import { CMAAuth } from 'root/services';
import { RouteDictionary } from './types';
// import { UserRoutes } from 'root/pages/User';

// export * from "./link";
export * from './types';

// register the plugin
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: RouteDictionary.Home,
      component: HomeContainer,
      meta: {
        isGuest: true
      }
    },
    {
      path: '/article/:type',
      component: Blog,
      meta: {
        isGuest: true
      }
    },
    {
      path: RouteDictionary.Login,
      component: LoginContainer,
      meta: {
        isGuest: true
      }
    },
    {
      path: RouteDictionary.Register,
      component: Register,
      meta: {
        isGuest: true
      }
    },
    {
      path: RouteDictionary.ForgotPassword,
      component: ForgotPassword,
      meta: {
        isGuest: true
      }
    },
    ProfileRouter,
    {
      path: RouteDictionary.Article,
      component: ArticleContainer
    },
    {
      path: RouteDictionary.Game,
      component: Game,
      children: [
        {
          path: '',
          component: FirstScreen
        },
        {
          path: 'practices',
          component: GamePractices
        },
        {
          path: 'smarter-game',
          component: GameAbacus
        },
        {
          path: 'daily-bonus',
          component: GameDailyBonus
        },
        {
          path: 'extra-bonus',
          component: GameExtraBonus
        }
      ],
      beforeEnter: async (_, __, next) => {
        const user = await CMAAuth.currentUser();
        if (user) {
          return next();
        }

        next(RouteDictionary.Login);
      }
    },
    BoardRouter,
    ContactUsRouter,
    NotificationRouter,
    SubscriptionRouter
  ]
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
