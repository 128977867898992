import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: require('./view.html')
})

export class ProfileContainer extends Vue {
  // public logout() {
  //   this.$store.dispatch(ActionType.Unauthenticated, this.$router);
  // }
}
