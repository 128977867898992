import { CMABreakcrumb } from 'root/components';
import { cmaFormatDateTime } from 'root/helpers';
import { IPagingParams, IRedeemed, RedeemedFilter } from 'root/models';
import { ProductDetailView } from 'root/pages/Product/ProductDetailView';
import { RedeemedList } from 'root/pages/Redeemed/Components/RedeemedList';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Route } from 'vue-router';
import { mapState } from 'vuex';
import { ActionTypeProfile } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'redeemed-list': RedeemedList,
    'product-detail-view': ProductDetailView,
    'cma-breadcumb': CMABreakcrumb
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.profile.pointHistory.data,
      redemptionData: (state: IState) => state.profile.pointRedemption.data,
      loading: (state: IState) => state.profile.pointHistory.loading,
      loadingMore: (state: IState) => state.profile.pointHistory.loadingMore,

      redemptionLoading: (state: IState) => state.profile.pointRedemption.loading,
      redemptionLoadingMore: (state: IState) => state.profile.pointRedemption.loadingMore,
      pagination: (state: IState) => state.profile.pointHistory.pagination,
    })
  },
  watch: {
    redeemedFilter(newValue) {
      if (newValue) {
        this.fetchData();
      }
    }
  }
})

export class PointsHistory extends Vue {
  public $refs: {
    leftFilter: HTMLDivElement
  };
  public redeemedFilter: RedeemedFilter = RedeemedFilter.EarnedPoints;
  public cmaFormatDateTime = cmaFormatDateTime;
  public loading: boolean;
  public loadingMore: boolean;
  public redemptionLoading: boolean;
  public redemptionLoadingMore: boolean;
  public pagination: IPagingParams;
  public productId: any = null;
  public redemeedId: any = null;
  public visibleProduct: boolean = false;
  public redeemedDetail: IRedeemed = null;
  public redemptionData: IRedeemed[];

  public changeStatusFilter(newValue: RedeemedFilter) {
    this.redeemedFilter = newValue;
    const route: Route = this.$route,
      query = {
        ...route.query,
        status: newValue
      };
    this.$router.push({query});
    window.scrollTo({
      top: 0
    });
    // this.$store.commit(MutationTypeRedeemed.clearRedeemedList);
    // this.fetchData();
  }

  public getStatusFilter(route: Route) {
    const query = route.query,
      status = query.status;

    return status ? status.toString() : RedeemedFilter.EarnedPoints;
  }

  public handleHoldLeftFilter() {
    const leftFilter = this.$refs.leftFilter,
      bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    if (bodyScrollTop >= 128) {
      leftFilter.classList.add('fixed');
    } else {
      leftFilter.classList.remove('fixed');
    }
  }
  public showDetail({productId, redemeedId}) {
    this.productId = productId;
    this.redemeedId = redemeedId;
    this.visibleProduct = true;
  }
  public beforeOpen() {
    this.redeemedDetail = this.redemptionData.find((e) => e.id === this.redemeedId);
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.BreakcumbChange, [{
        label: this.$t('account'),
        url: '/account'
      }, {
        label: this.$t('points.history'),
        url: '',
        currently: true
      }]);
    });
    this.redeemedFilter = <any> this.getStatusFilter(this.$route);
    this.fetchData(true);
    this.handleHoldLeftFilter();
    window.addEventListener('scroll', this.handleHoldLeftFilter, false);
    window.addEventListener('scroll', this.handleWindowScroll, false);
  }

  protected beforeDestroy() {
    window.removeEventListener('scroll', this.handleHoldLeftFilter, false);
    window.removeEventListener('scroll', this.handleWindowScroll, false);
  }

  private handleWindowScroll() {
    const bottomOfWindow =
      (window.scrollY + window.innerHeight) >= document.body.scrollHeight - 500;

    let _loading: boolean = false;

    if (this.redeemedFilter === RedeemedFilter.EarnedPoints) {
      _loading = this.loading || this.loadingMore;
    } else if (this.redeemedFilter === RedeemedFilter.RedeemedPoints) {
      _loading = this.redemptionLoading || this.redemptionLoadingMore;
    }

    if (bottomOfWindow && !_loading &&
        this.pagination.nextPageToken) {
      this.fetchData();
    }

    return;
  }

  private fetchData(firstTime?: boolean) {
    if (this.redeemedFilter === RedeemedFilter.EarnedPoints) {
      this.$store.dispatch(ActionTypeProfile.GetPointHistory, firstTime ? {page: 1} : {
        nextPageToken: this.pagination.nextPageToken
      });
    } else if (this.redeemedFilter === RedeemedFilter.RedeemedPoints) {
      this.$store.dispatch(ActionTypeProfile.GetPointRedemption, firstTime ? {page: 1} : {
        nextPageToken: this.pagination.nextPageToken
      });
    }

    return;

  }
}
