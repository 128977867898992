import { boardFilterDefault, IBoard, IBoardFilter } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IBoardHttpService
  extends ICRUDHttpService<IBoard> {
  getBoardList(filter: IBoardFilter): Promise<any>;
  getCoolestKid(): Promise<IBoard>;
  // getBoardGame(id: number): Promise<any>;
}

export function BoardHttpService(options: IHttpServiceClientOptions): IBoardHttpService {
  const basePath = '/boards';
  const baseUrl = options.httpService.getUrl(basePath);

  function getBoardList(filter: IBoardFilter = boardFilterDefault()) {
    return options.httpService.get(baseUrl, {
      params: filter
    });
  }

  function getCoolestKid() {
    const BoardUrl = options.httpService.getUrl(`${basePath}/coolest`);

    return options.httpService.get(BoardUrl);
  }

  return {
    ...CRUDHttpService<IBoard>({
      ...options,
      basePath
    }),
    getBoardList,
    getCoolestKid
  };
}
