import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeHome } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.home.aboutUs,
      loading: (state: IState) => state.home.aboutUsLoading
    })
  }
})

export class HomeAbout extends Vue {
  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeHome.GetAboutUs);
    });
  }
}
