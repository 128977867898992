import { planDefault, ICurrentSubscription, IPlan } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeSubscription } from './types';

export interface ISubscriptionState extends ICRUDState<any> {
  loading: boolean;
  cardLoading: boolean;
  cards: any[];
  current: ICurrentSubscription;
  planSelectedLocal: IPlan;
}

export const defaultState: ISubscriptionState = {
  ...CRUDState(),
  loading: false,
  cardLoading: false,
  cards: [],
  planSelectedLocal: planDefault(),
  current: {
    scheduleSubscription: {
      id: null,
      nextDateBilling: null,
      plan: planDefault()
    },
    subscription: {
      id: null,
      nextDateBilling: null,
      plan: {
        id: null,
        duration: null,
        name: '',
        price: null
      }
    }
  }
};

export const mutations = {
  ...crudMutations('plan'),
  [MutationTypeSubscription.CardLoading](state: ISubscriptionState) {
    state.cardLoading = true;
  },
  [MutationTypeSubscription.CardLoaded](state: ISubscriptionState) {
    state.cardLoading = false;
  },
  [MutationTypeSubscription.PlanLoading](state: ISubscriptionState) {
    state.loading = true;
  },
  [MutationTypeSubscription.PlanLoaded](state: ISubscriptionState) {
    state.loading = false;
  },
  [MutationTypeSubscription.SetStripeCardList](state: ISubscriptionState, data) {
    state.cards = data;
  },
  [MutationTypeSubscription.SetPlansList](state: ISubscriptionState, data) {
    state.data = data;
  },
  [MutationTypeSubscription.SetCurrentSubscription](state: ISubscriptionState, data) {
    state.current = data;
  },
  [MutationTypeSubscription.SetPlanSelectedLocal](state: ISubscriptionState, data: IPlan) {
    state.planSelectedLocal = data;
  },
  [MutationTypeSubscription.ClearPlanSelectedLocal](state: ISubscriptionState) {
    state.planSelectedLocal = planDefault();
  }

};
