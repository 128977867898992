import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    src: String
  }
})

export class CMAAvatar extends Vue {

}
