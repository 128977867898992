import { AssignmentStatus, IAssignment, INotification, NotificationType } from 'root/models';
import { ActionTypeAssignment, GetterTypeAssignment, MutationTypeAssignment } from 'root/pages/Assignment/Store/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { NotificationRouterName } from '../..';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          isRead: true,
          title: '',
          type: null
        };
      }
    }
  }
})

export class NotificationItem extends Vue {
  public data: INotification;
  public loading: boolean = false;

  public get title(): string {
    switch (this.data.type) {
    case NotificationType.CompletedAssignment:
    case NotificationType.NewAssignment:
    case NotificationType.RemindAssignment:
    case NotificationType.Redemption:
    case NotificationType.RedemptionProcessing:
    case NotificationType.RedemptionReady:
    case NotificationType.RedemptionCompleted:
    case NotificationType.redemptionRejected:
    case NotificationType.Game:
      return this.data.content.toString();
    default:
      return this.data.title;
    }
  }

  public get isDisabledClick() {
    const type = this.data.type;

    return type === NotificationType.Game;
  }

  public async click() {
    const data = this.data,

      { referenceId, type} = data;

    switch (type) {
    case NotificationType.NewAssignment:
    case NotificationType.RemindAssignment:
    case NotificationType.CompletedAssignment:
      await this.$store.dispatch(ActionTypeAssignment.AssignmentFindById, {
        id: referenceId
      });
      const assignment: IAssignment = this.$store.getters[GetterTypeAssignment.GetAssignmentById](referenceId);
      if (assignment.statusAssignment === AssignmentStatus.New) {
        this.$store.commit(MutationTypeAssignment.SetAssignmentGame, {
          type: assignment.library.method,
          id: assignment.id
        });
        this.$router.push('/game/extra-bonus');

        return;
      }
      this.$router.push(`/account/assignment?assignmentId=${assignment.id}&showPopup=true`);

      return;
    case NotificationType.Announcement:
      this.$router.push({
        name:  NotificationRouterName.View,
        params: {
          notifyId: data.id
        }
      });
      break;
    case NotificationType.Redemption:
    case NotificationType.RedemptionProcessing:
    case NotificationType.RedemptionReady:
    case NotificationType.redemptionRejected:
    case NotificationType.RedemptionCompleted:
      this.$router.push(`/account/redeemed?type=redeemed&redeemedId=${referenceId}&showPopup=${true}`);
      break;
    default:
      break;
    }
  }
}
