import { RouteConfig } from 'vue-router';
import { SubcriptionContainer } from './SubcriptionContainer';
import { Subscription } from './Subscription';

export enum ExamplateRouterPath {}

export const SubscriptionRouter: RouteConfig = {
  path: '/subscription',
  component: SubcriptionContainer,
  children: [
    {
      path: '',
      component: Subscription
    }
  ]
};
