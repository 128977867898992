// import PIXI from 'pixi.js';
import { GameStage } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { FirstScreen } from './Components/FirstScreen';
import { GamePlay } from './Components/GamePlay';
import { MutationTypeGame } from './Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'first-screen': FirstScreen,
    'game-play': GamePlay
  }
})

export class Game extends Vue {
  public gameState = {
    FirstScreen: GameStage.FirstScreen,
    ProgressiveGame: GameStage.ProgressiveGame
  };
  public stage: GameStage = GameStage.FirstScreen;

  protected async mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationTypeGame.SetGameRouter, true);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeGame.SetGameRouter, false);
  }

}
