import { stripObject } from '@hgiasac/helper';
import { omit } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { INotificationFilter } from 'root/models';
import { MutationTypeProfile } from 'root/pages/Profile/Store/types';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { INotificationState } from './mutations';
import { ActionTypeNotification, MutationTypeNotification } from './types';

const { getNotificationList, getById } = cmaHttpClient.notification;

export const actions = {
  ...crudActions('notification', {
    getById
  }),
  async [ActionTypeNotification.FilterNotification](
    {commit, dispatch }: ActionContext<INotificationState, IState>, dataFilter: INotificationFilter
  ) {
    dataFilter = <any> stripObject(dataFilter);

    if (dataFilter.page && dataFilter.page === 1) {
      commit(MutationTypeNotification.NotificationLoading);
    } else {
      commit(MutationTypeNotification.NotificationLoadingMore);
    }

    try {
      const filter: any = omit(dataFilter, 'page');
      const data = await getNotificationList(filter);

      if (dataFilter.page === 1) {
        commit(MutationTypeNotification.SetNotificationList, data);
        commit(MutationTypeNotification.NotificationLoaded);
      } else {
        commit(MutationTypeNotification.UpdateNotificationList, data);
        commit(MutationTypeNotification.NotificationLoadedMore);
      }
      commit(MutationTypeProfile.SetNewNotify, false);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      commit(MutationTypeNotification.NotificationLoadedMore);
      commit(MutationTypeNotification.NotificationLoaded);
    }
  },
};
