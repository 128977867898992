import { GameType, MethodType } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { GamePlay } from '../../Components/GamePlay';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'game-play': GamePlay
  }
})
export class GamePractices extends Vue {
  public methodType: MethodType = MethodType.Empty;
  public methodTypeProgressive = MethodType.Progressive;
  public methodTypeSpeed = MethodType.SpeedChallenge;
  public methodTypeFlash = MethodType.Flash;
  public methodTypeAural = MethodType.AuralMental;
  public gameType = GameType.Practices;

  public clickBtnBack() {
    this.$router.push('/game');
  }

  public click(model: MethodType) {
    this.methodType = model;
  }

  public handleEndGame() {
    this.methodType = MethodType.Empty;
  }
  public closeGame() {
    this.methodType = MethodType.Empty;
  }
}
