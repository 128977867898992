import { cloneDeep, unionBy } from 'lodash';
import { MutationTypeHome } from './types';

export interface IAboutUs {
  whoWeAre: string;
  whatWeDo: string;
  international: string;
  mainOffice: string;
  email: string;
  openingHours: string;
}

export interface ITestimonialsMetadata {
  motherName: string;
  childrenName: string;
  avatar: string;
}
export interface ITestimonials {
  post_content: string;
  metadata: ITestimonialsMetadata;
}

export interface IArticles {
  ID: number;
  post_content: string;
  post_title: string;
  author: IArticelsAuthor;
}

export interface IArticelsAuthor {
  ID: number;
  user_email: string;
  display_name: string;
  avatar: string;
  post_modified: string;
}

export enum ArticlesType {
  News = 'news',
  Events = 'events',
  Promotion = 'promotion',
  Note = 'note',
  Blog = 'blog'
}

export interface IHomeState {
  loading: boolean;
  aboutUsLoading: boolean;
  aboutUs: IAboutUs;
  testimonials: ITestimonials[];
  testimonialsLoading: boolean;
  articles: IArticles[];
  articlesLoading: boolean;
}

export const defaultState: IHomeState = {
  loading: false,
  aboutUs: {
    email: '',
    international: '',
    mainOffice: '',
    openingHours: '',
    whatWeDo: '',
    whoWeAre: ''
  },
  aboutUsLoading: false,
  testimonials: [],
  testimonialsLoading: false,
  articles: [],
  articlesLoading: false
};

export const mutations = {
  [MutationTypeHome.SetAboutUsLoading](state: IHomeState) {
    state.aboutUsLoading = true;
  },
  [MutationTypeHome.SetAboutUsLoaded](state: IHomeState) {
    state.aboutUsLoading = false;
  },
  [MutationTypeHome.SetAboutUs](state: IHomeState, data: IAboutUs) {
    state.aboutUs = data;
  },
  [MutationTypeHome.SetTestimonialsLoading](state: IHomeState) {
    state.testimonialsLoading = true;
  },
  [MutationTypeHome.SetTestimonialsLoaded](state: IHomeState) {
    state.testimonialsLoading = false;
  },
  [MutationTypeHome.SetTestimonials](state: IHomeState, data: ITestimonials[]) {
    state.testimonials = data;
  },
  [MutationTypeHome.SetArticlesLoading](state: IHomeState) {
    state.articlesLoading = true;
  },
  [MutationTypeHome.SetArticlesLoaded](state: IHomeState) {
    state.articlesLoading = false;
  },
  [MutationTypeHome.SetArticles](state: IHomeState, data: IArticles[]) {
    state.articles = data;
  },
  [MutationTypeHome.SetArticlesMore](state: IHomeState, data: IArticles[]) {
    let articles = cloneDeep(state).articles;
    articles = articles.concat(data);

    state.articles = unionBy(articles, 'ID');
  }
};
