import { cloneDeep, omit } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { GameType, IUser, MethodType } from 'root/models';
import { RouteDictionary } from 'root/router';
import { ActionType, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Scrolly, ScrollyBar, ScrollyViewport } from 'vue-scrolly';
import { mapState } from 'vuex';
import { GamePlay } from '../../Components/GamePlay';
import { MutationTypeGame } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    Scrolly,
    ScrollyViewport,
    ScrollyBar,
    'game-play': GamePlay
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      gameLevelPlaying: (state: IState) => state.game.gameLevelPlaying
    })
  },
  watch: {
    // currentMap(newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     this.fetchData();
    //   }
    // }
  }
  // beforeRouteLeave(_, __, next) {
  //   const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
  //   if (answer) {
  //     next();
  //   } else {
  //     next(false);
  //   }
  // }
})
export class GameAbacus extends Vue {
  public authUser: IUser;
  public showMap: boolean = true;
  public level: number = 1;
  public methodType: MethodType = MethodType.Empty;
  public gameType: GameType = GameType.SmarterGame;
  public loading: boolean = false;
  public gameLevelPlaying: number;
  public mapGame: any[] = [];
  public allPosition: any[] = [
    {
      x: 0.25,
      y: 0.94
    },
    {
      x: 0.525,
      y: 0.94
    },
    {
      x: 0.75,
      y: 0.87
    },
    {
      x: 0.5,
      y: 0.865
    },
    {
      x: 0.25,
      y: 0.8275
    },
    {
      x: 0.67,
      y: 0.8
    },
    {
      x: 0.45,
      y: 0.735
    },
    {
      x: 0.27,
      y: 0.67
    },
    {
      x: 0.65,
      y: 0.575
    },
    {
      x: 0.32,
      y: 0.6
    },
    {
      x: 0.2,
      y: 0.532
    },
    {
      x: 0.66,
      y: 0.505
    },
    {
      x: 0.4,
      y: 0.48
    },
    {
      x: 0.125,
      y: 0.462
    },
    {
      x: 0.405,
      y: 0.42
    },
    {
      x: 0.7,
      y: 0.375
    },
    {
      x: 0.325,
      y: 0.35
    },
    {
      x: 0.09,
      y: 0.3125
    },
    {
      x: 0.4,
      y: 0.29
    },
    {
      x: 0.7,
      y: 0.25
    },
    {
      x: 0.37,
      y: 0.2
    },
    {
      x: 0.6,
      y: 0.17
    },
    {
      x: 0.58,
      y: 0.11
    },
    {
      x: 0.3,
      y: 0.11
    },
    {
      x: 0.2,
      y: 0.05
    },
  ];
  public currentMap: number = 0;

  private levelPerMap: number = 25;

  // public get currentMap(): number {
  //   return Math.floor((this.currentLevel - 1) / this.levelPerMap);
  // }
  public get currentIndex(): number {
    // return (this.currentLevel - 1) % this.levelPerMap;
    return this.currentLevel;
  }

  public get currentLevel(): number {
    return this.authUser.level;
  }
  public get maximumMap(): number {
    return Math.floor((this.currentLevel - 1) / this.levelPerMap);
  }
  public displayLevel(index: number) {
    return this.levelPerMap * (this.currentMap) + index + 1;
  }
  public clickBtnBack() {
    this.$router.push(RouteDictionary.Game);
  }
  public clickLevel(level: number) {
    this.showMap = false;
    // this.level = level;
    this.$store.commit(MutationTypeGame.SetGameLevelPlaying, level);
  }

  public closeGame(confirm: false) {
    // this.$router.push('/game');
    if (confirm) {
      this.showMap = true;
      setTimeout(this.fetchData, 0);

      return;
    }
    const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
    if (answer) {
      this.showMap = true;
      setTimeout(this.fetchData, 0);
    }
    // setTimeout(this.handleMapScrollBottom, 0);
  }

  public openDialog() {
    //
  }

  public goNextLevel(level: number, star: number) {
    const authUser = cloneDeep(this.authUser);

    if (authUser.level > level) {
      return;
    }
    const _authUser: IUser = {
      ...authUser,
      level: authUser.level + 1
    };
    this.$store.commit(MutationType.Authenticated, _authUser);
    // const levelIndex = findIndex(this.allPosition, (e) => e.level === level);
    let passedLevel = this.allPosition[level - 1];
    passedLevel = {
      ...passedLevel,
      star
    };

    this.allPosition[level - 1] = passedLevel;
  }

  public handleMapScrollBottom() {
    const el = this.$el,
      _scrolly = <any> el.querySelector('#game-map-scroll .el-scrollbar__wrap'),
      // _scrolly = <any> el.querySelector('#game-map-abacus'),
      current = <any> el.querySelector('.cma-icon__games-level-current');
    if (_scrolly && current) {
      _scrolly.scrollTop = current.offsetTop - _scrolly.offsetHeight / 2;
    }

    return;
  }

  public nextMap() {
    this.currentMap = this.currentMap + 1;
    this.loading = true;
    this.renderMap();
  }
  public previousMap() {
    this.currentMap = this.currentMap - 1;
    this.loading = true;
    this.renderMap();
  }

  public mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }

  private fetchData() {
    this.loading = true;
    cmaHttpClient.games.GetGameMap()
      .then((result) => {
        const authUser = cloneDeep(this.authUser);
        const _authUser: IUser = {
          ...authUser,
          level: result.currentLevel
        };
        this.mapGame = result.data;

        this.$store.commit(MutationTypeGame.SetCurrentMapLevel, result.currentLevel);
        this.$store.commit(MutationType.Authenticated, _authUser);
        const _currentLevel = this.gameLevelPlaying
        ? this.gameLevelPlaying : (this.currentLevel - 1);
        this.currentMap = Math.floor(_currentLevel / this.levelPerMap);
        this.renderMap();
      })
      .catch((error) => {
        this.loading = false;
        this.$store.dispatch(ActionType.CatchException, error);
      });
  }

  private renderMap() {
    this.allPosition = this.allPosition.map((position, index) => {
      position = omit(position, ['id', 'level', 'star']);
      const level = this.currentMap * this.levelPerMap + index;
      // const map = this.mapGame[this.currentMap * this.levelPerMap + index];
      const map = this.mapGame.find((m) => m.level === level + 1);
      console.log(map, level + 1);

      return {
        ...position,
        ...map
      };
    });
    setTimeout(() => {
      this.handleMapScrollBottom();
      this.loading = false;
    }, 0);
  }
}
