import { cloneDeep, omit } from 'lodash';
import { IProduct, IRedeemed, MethodType, RedeemedStatus } from 'root/models';
import { ProductDetailView } from 'root/pages/Product/ProductDetailView';
import { ActionTypeProduct, GetterTypeProduct } from 'root/pages/Product/Store/types';
import { IState, MutationType } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Route } from 'vue-router';
import { mapGetters, mapState } from 'vuex';
import { RedeemedList } from '../Components/RedeemedList';
import { ActionTypeRedeemed, GetterTypeRedeemed, MutationTypeRedeemed } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'redeemed-list': RedeemedList,
    'product-detail-view': ProductDetailView
  },
  computed: {
    ...mapState({
      pagination: (state: IState) => state.redemption.pagination,
      loading: (state: IState) => state.redemption.loading,
      loadingMore: (state: IState) => state.redemption.loadingMore,
      data: (state: IState) => state.redemption.data,

      productPagination: (state: IState) => state.product.pagination,
      productLoading: (state: IState) => state.product.loading,
      productLoadingMore: (state: IState) => state.product.loadingMore,
      productData: (state: IState) => state.product.data,
    }),
    ...mapGetters(['methodOptions']),
    cloneData() {
      return this.redeemedFilter === 'redeemed' ? this.data : this.productData.map((e) => {
        return {
          product: e
        };
      });
    },
    cloneLoadingMore() {
      return this.redeemedFilter === 'redeemed' ? this.loadingMore : this.productLoadingMore;
    },
    cloneLoading() {
      return this.redeemedFilter === 'redeemed' ? this.loading : this.productLoading;
    }
  }
})

export class Redemption extends Vue {
  public $refs: {
    leftFilter: HTMLDivElement
  };
  public pagination: IPaginationResult;
  public loading: boolean;
  public loadingMore: boolean;

  public redeemedFilter: string = '';
  public searchText: string = '';
  public methodSeleted: MethodType = MethodType.Empty;
  public visibleProduct: boolean = false;
  public productDetail: IProduct = null;
  public productId: number | string = null;
  public redeemedId: number | string = null;

  public handleHoldLeftFilter() {
    const leftFilter = this.$refs.leftFilter,
      bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    if (bodyScrollTop >= 108) {
      leftFilter.classList.add('fixed');
    } else {
      leftFilter.classList.remove('fixed');
    }
  }

  public formatterName(model: IRedeemed) {
    return model.owner ? `Redeemed from ${model.owner.fullName} • ${model.owner.centre}` : '';
  }

  public handleWindowScroll() {
    const bottomOfWindow =
      (window.scrollY + window.innerHeight) >= document.body.scrollHeight - 500;

    if (bottomOfWindow &&
        (!this.loading && !this.loadingMore) &&
        this.pagination.nextPageToken) {
      this.fetchData();
    }

    return;
  }

  public changeStatusFilter(newValue: RedeemedStatus) {
    this.redeemedFilter = newValue;
    this.searchText = '';
    const query = {
      type: newValue
    };
    this.$router.push({query});
    window.scrollTo({
      top: 0
    });
    this.$store.commit(MutationTypeRedeemed.clearRedeemedList);
    this.fetchData();
  }

  public getStatusFilter(route: Route) {
    const query = route.query,
      type = query.type;

    return type ? type.toString() : '';
  }

  public getSearchText(route: Route): any {
    const query = route.query,
      text = query.text;

    return text ? text.toString() : '';
  }

  public handleSearch() {
    const text = cloneDeep(this.searchText);

    const route: Route = this.$route,
      query = {
        ...route.query,
        text
      };
    this.$router.push({query});
    window.scrollTo({
      top: 0
    });
    this.$store.commit(MutationTypeRedeemed.clearRedeemedList);
    this.fetchData(true);
  }
  public showDetail({productId, redeemedId}) {
    this.productId = productId;
    this.redeemedId = redeemedId;
    this.visibleProduct = true;
  }
  public async beforeOpen() {
    const query = this.$route.query;
    if (query.type === RedemptionPageType.Product) {
      this.productDetail = this.$store.getters[GetterTypeProduct.GetProductDetail](this.productId);
    } else if (query.type === RedemptionPageType.Redeemed) {
      // this.productDetail = this.$store.getters[GetterTypeRedeemed.GetRedeemedDetail](this.redeemedId);
      const redeemedDetail = this.$store.getters[GetterTypeRedeemed.GetRedeemedDetail](this.redeemedId);
      if (redeemedDetail) {
        this.productDetail = redeemedDetail;

        return;
      }
      const newRedeemedDetail = await this.$store.dispatch(ActionTypeRedeemed.RedeemGetById, this.redeemedId);

      this.productDetail = newRedeemedDetail;
    }
  }
  public beforeClose() {
    const route: Route = this.$route,
      query = {
        ...omit(route.query, ['redeemedId', 'showPopup']),
      };
    this.$router.push({ query });
  }

  public get getRedeemedId() {
    return this.$route.query.redeemedId;
  }

  protected mounted() {
    this.$nextTick(async () => {
      this.setBreakcumb();
      await this.fetchData(true);
      this.redeemedFilter = this.getStatusFilter(this.$route);
      this.searchText = this.getSearchText(this.$route);
      if (this.getRedeemedId && this.$route.query.showPopup === 'true') {
        // const assignment = this.$store.getters[GetterTypeAssignment.GetAssignmentById](this.assignmentId);

        this.showDetail({productId: null, redeemedId: this.getRedeemedId});
      }

      this.handleHoldLeftFilter();
      window.addEventListener('scroll', this.handleHoldLeftFilter, false);
      window.addEventListener('scroll', this.handleWindowScroll, false);
    });
  }

  protected beforeDestroy() {
    window.removeEventListener('scroll', this.handleHoldLeftFilter, false);
    window.removeEventListener('scroll', this.handleWindowScroll, false);
  }

  private setBreakcumb() {
    this.$store.commit(MutationType.BreakcumbChange, [{
      label: this.$t('account'),
      url: '/account'
    }, {
      label: this.$t('redemption'),
      url: '',
      currently: true
    }]);
  }

  private fetchData(first: boolean = false) {
    const query = this.$route.query;

    return new Promise(async (resolve) => {
      if (query.type === RedemptionPageType.Redeemed) {
        if (first) {
          await this.$store.dispatch(ActionTypeRedeemed.FilterRedeemed, {
            ...omit(query, 'type'),
            page: 1
          });
        } else {
          await this.$store.dispatch(ActionTypeRedeemed.FilterRedeemed, {
            ...omit(query, 'type'),
            nextPageToken: this.pagination.nextPageToken
          });
        }
      } else if (query.type === RedemptionPageType.Product) {
        if (first) {
          await this.$store.dispatch(ActionTypeProduct.FilterProduct, {
            ...omit(query, 'type'),
            page: 1
          });
        } else {
          await this.$store.dispatch(ActionTypeProduct.FilterProduct, {
            ...omit(query, 'type'),
            nextPageToken: this.pagination.nextPageToken
          });
        }
      }

      resolve();

    });
  }
}

const enum RedemptionPageType {
  Redeemed = 'redeemed',
  Product = 'product'
}
