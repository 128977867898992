import { cloneDeep, findIndex, map } from 'lodash';
import { INotification } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeNotification } from './types';

export interface INotificationState extends ICRUDState<INotification> {
  loadingMore: boolean;
}

export const defaultState: INotificationState = {
  ...CRUDState(),
  loadingMore: false
};

export const mutations = {
  ...crudMutations('notification'),
  [MutationTypeNotification.NotificationLoading](state: INotificationState) {
    state.loading = true;
  },
  [MutationTypeNotification.NotificationLoaded](state: INotificationState) {
    state.loading = false;
  },
  [MutationTypeNotification.NotificationLoadingMore](state: INotificationState) {
    state.loadingMore = true;
  },
  [MutationTypeNotification.NotificationLoadedMore](state: INotificationState) {
    state.loadingMore = false;
  },
  [MutationTypeNotification.SetNotificationList](state: INotificationState, payload) {
    state.data = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeNotification.ClearNotificationList](state: INotificationState) {
    state.data = [];
    state.pagination = {
      page: 1
    };
  },
  [MutationTypeNotification.UpdateNotificationList](state: INotificationState, payload) {
    let nextData: INotification[] = payload.data;
    const data = cloneDeep(state.data);
    nextData = nextData.filter((e) => e);

    map(nextData, (_nextData) => {
      if (findIndex(data, (e) => e.id === _nextData.id) < 0) {
        data.push(_nextData);
      }

      return;
    });

    state.data = data;
    state.pagination = payload.pagination;
  },
};
