import { MethodType } from './Game';

export interface IAssignment {
  id: number;
  countQuestion: number;
  totalPoint: number;
  library: IAssignmentLibrary;
  owner: IAssignmentOwner;
  statusAssignment?: AssignmentStatus;
}

export interface IAssignmentLibrary {
  id: number;
  method: MethodType;
  grage: number;
}

export interface IAssignmentOwner {
  id: number;
  fullName: string;
  centre: string;
}

export enum AssignmentStatus {
  New = 'new',
  Completed = 'completed',
  Overdue = 'overdue',
  Default = ''
}

export interface IAssignmentFilter {
  method: MethodType;
  status: AssignmentStatus;
  page?: number;
  nextPageToken?: string;
}

export interface IAssignmentGame {
  type: MethodType;
  id: number;
}
