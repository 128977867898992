export enum MutationTypeProduct {
  ProductLoading = 'ProductLoading',
  ProductLoaded = 'ProductLoaded',
  ProductLoadingMore = 'ProductLoadingMore',
  ProductLoadedMore = 'ProductLoadedMore',
  SetProductList = 'setProductList',
  clearProductList = 'clearProductList',
  UpdateProductList = 'updateProductList',
  SetProductGame = 'SetProductGame',
  ResetProductGame = 'ResetProductGame'
}

export enum ActionTypeProduct {
  UpdateProduct = 'updateProduct',
  LoadingProduct = 'loadingProduct',
  ProductFilterNoCache = 'ProductFilterNoCache',
  FilterProduct = 'FilterProduct',
  GetProductGame = 'GetProductGame'
}

export enum GetterTypeProduct {
  GetProductDetail = 'GetProductDetail'
}
