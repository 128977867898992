import { RouteConfig } from 'vue-router';
import { Assignment } from '../Assignment';
// import { Course } from '../Course';
import { RedeemedRouter } from '../Redeemed';
import { Feedback } from './Feedback';
import { InformationProfile } from './Information';
import { PointsHistory } from './PointsHistory';
import { ProfileContainer } from './ProfileContainer';
import { ProfileEdit } from './ProfileEdit';
import { Setting } from './Setting';
import { SwitchAccount } from './SwitchAccount';

export const ProfileRouter: RouteConfig = {
  path: '/account',
  component: ProfileContainer,
  children: [
    {
      path: '',
      component: InformationProfile
    },
    {
      path: 'assignment',
      component: Assignment
    },
    // {
    //   path: 'courses',
    //   component: Course
    // },
    RedeemedRouter,
    {
      path: 'points-history',
      component: PointsHistory
    },
    {
      path: 'profile',
      component: ProfileEdit
    },
    {
      path: 'feedback',
      component: Feedback,
      meta: {
        isGuest: true
      }
    },
    {
      path: '/setting/switch-account',
      component: SwitchAccount,
    },
    {
      path: '/setting',
      component: Setting
    },
  ]
};
