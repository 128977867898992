import { cloneDeep, find, isNull } from 'lodash';
import { IProduct } from 'root/models';
import { ActionTypeRedeemed } from 'root/pages/Redeemed/Store/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    productId: [Number, String],
    productDetail: Object,
    redeemed: Boolean,
    redeemedNumber: Number,
    progress: String
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      loading: (state: IState) => state.redemption.redeemLoading
    }),
    maximumQty() {
      if (this.$props.redeemed) {
        return Infinity;
      }
      const authUser = cloneDeep(this.authUser),
        productDetail: IProduct = cloneDeep(this.productDetail),
        point: number = authUser.point;

      return Math.max(Math.floor(point / Number(productDetail.point)), 1);
    }
  },
})

export class ProductDetailView extends Vue {
  public qty: number = 1;

  private priviewIndex: number = null;

  public get primaryImageURL(): string {
    const productDetail = cloneDeep(this._productDetail),
      images = productDetail ? productDetail.images : null;

    if (!images) {
      return '';
    }

    return find(images, (e, index) => {
      if (isNull(this.priviewIndex)) {
        return e.isPrimary;
      }

      return index === this.priviewIndex;
    }).src;
  }

  public get _productDetail(): IProduct {
    return this.$props.productDetail;
  }

  public get getPoints() {
    if (this.$props.redeemed) {
      return Number(this.$props.redeemedNumber) * Number(this._productDetail.point);
    }

    return this._productDetail ? Number(this._productDetail.point) * this.qty : 0;
  }

  public isPrimaryImage(index: number) {
    if (isNull(this.priviewIndex)) {
      return this.isHaveImgage(index) && this._productDetail.images[index].isPrimary;
    }

    return this.priviewIndex === index;
  }
  public isHaveImgage(index: number): boolean {
    const productDetail = cloneDeep(this._productDetail),
      images = productDetail ? productDetail.images : null;

    if (!images) {
      return false;
    }

    return !!images[index];
  }

  public changePreview(index: number) {
    this.priviewIndex = index;
  }

  public redeem() {
    this.$store.dispatch(ActionTypeRedeemed.RedeemProduct, {
      id: this.$props.productId,
      qty: this.qty
    });
  }

  protected beforeDestroy() {
    this.qty = 0;
  }
}
