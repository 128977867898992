import { cmaHttpClient } from 'root/api/http';
import { BonusTypes, IGameHistory } from 'root/models';
import { ActionType, IState } from 'root/store';
import { ActionContext } from 'vuex';
import { IGameState } from './mutations';
import { ActionTypeGame, MutationTypeGame } from './types';

export const actions = {
  async [ActionTypeGame.GetProgressive](
    { dispatch, commit }: ActionContext<IGameState, IState>, {
      numberQuestion,
      config
    }
  ) {
    commit(MutationTypeGame.SetProgressiveLoading);
    try {
      const data = await cmaHttpClient.games.GetProgressiveGame(numberQuestion, config);
      commit(MutationTypeGame.SetProgressive, data);
      commit(MutationTypeGame.SetProgressiveLoaded);
    } catch (error) {
      commit(MutationTypeGame.SetProgressiveLoaded);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeGame.GetAbacus](
    { dispatch, commit }: ActionContext<IGameState, IState>, {
      level,
      onSuccess
    }
  ) {
    commit(MutationTypeGame.SetProgressiveLoading);
    try {
      const data = await cmaHttpClient.games.GetGameByLevel(level);
      commit(MutationTypeGame.SetAbacus, data);
      commit(MutationTypeGame.SetProgressiveLoaded);
      onSuccess();
    } catch (error) {
      commit(MutationTypeGame.SetProgressiveLoaded);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeGame.GetBonusGame](
    { dispatch, commit }: ActionContext<IGameState, IState>, type: BonusTypes
  ) {
    commit(MutationTypeGame.SetBonusLoading);
    try {
      commit(MutationTypeGame.SetBonusLoaded);
      const data = await cmaHttpClient.games.GetBonusGame(type);
      commit(MutationTypeGame.SetBonusGame, data);
    } catch (error) {
      commit(MutationTypeGame.SetBonusLoaded);
      dispatch(ActionType.CatchException, error);
    }
  },
  [ActionTypeGame.CreateGameHistory](
    { }: ActionContext<IGameState, IState>, form: IGameHistory
  ) {
    return cmaHttpClient.games.CreateGameHistory(form);
  }
};
