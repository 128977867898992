import { RouteConfig } from 'vue-router';
import { BoardContainer } from './BoardContainer';
import { BoardList } from './BoardList';

export const BoardRouter: RouteConfig = {
  path: '/board',
  component: BoardContainer,
  children: [
    {
      path: '',
      component: BoardList
    },
    // {
    //   path: 'new',
    //   component: AssignmentCreate
    // },
    // {
    //   path: ':assignmentId',
    //   component: AssignmentDetail
    // },
    // {
    //   path: ':assignmentId/edit',
    //   component: AssignmentCreate,
    //   name: AssignmentRouterName.AssignmentEdit
    // }
  ]
};
