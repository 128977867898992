import { CMAAvatar } from 'root/components';
import { IBoard } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-avatar': CMAAvatar
  },
  props: {
    coolest: Boolean,
    index: Number,
    model: {
      type: Object,
      default: () => ({
        fullName: '',
        point: 0,
        pointDaily: 0,
        gender: '',
        level: 0,
        centre: '',
        avatart: '',
        isCoolest: false
      })
    }
  },
  computed: {
    ...mapState({
      currentUser: (state: IState) => state.board.user.currentUser,
    }),
    isCoolestKid() {
      return this.$props.coolest || this.$props.model.isCoolest;
    },
    _index() {
      if (this.$props.model.position) {
        return this.$props.model.position - 1;
      }
      if (this.$props.index >= 0) {
        return this.$props.index;
      }

      if (this.isCurrentUser) {
        return this.currentUser.position;
      }

      return this.$props.model.index;
    },
    isCurrentUser() {
      const currentUserId = this.currentUser && this.currentUser.id,
        modelId = this.$props.model && this.$props.model.id;

      return currentUserId === modelId;
    },
    isShowIndex() {
      if (this.isCoolestKid) {
        return this._index === 0;
      }

      return this._index >= 0;
    }
  }
})

export class BoardItem extends Vue {
  private model: IBoard;
  public click() {
    this.$emit('click', this.model);
  }
}
