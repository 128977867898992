export enum MutationTypeHome {
  SetAboutUsLoading = 'SetAboutUsLoading',
  SetAboutUsLoaded = 'SetAboutUsLoaded',
  SetAboutUs = 'SetAboutUs',
  SetTestimonials = 'SetTestimonials',
  SetTestimonialsLoading = 'SetTestimonialsLoading',
  SetTestimonialsLoaded = 'SetTestimonialsLoaded',
  SetArticles = 'SetArticles',
  SetArticlesMore = 'SetArticlesMore',
  SetArticlesLoading = 'SetArticlesLoading',
  SetArticlesLoaded = 'SetArticlesLoaded'
}

export enum ActionTypeHome {
  GetAboutUs = 'GetAboutUs',
  GetTestimonials = 'getTestimonials',
  GetArticles = 'GetArticles'
}
