import { planDefault, IPlan } from './Plan';

export interface ISubscription {
  id?: number;
  plan: IPlan;
  nextDateBilling: Date;
  processing?: ProcessingSubscription;
}

export interface ICurrentSubscription {
  subscription: ISubscription;
  scheduleSubscription?: ISubscription;
}

export enum ProcessingSubscription {
  Processing = 'processing',
  Success = 'success',
  Cancel = 'cancel',
  CancelPeriodEndAt = 'cancel_at_period_end',
  Fail = 'fail'
 }

export const subscriptionDefaut = (): ISubscription => {
  return {
    id: null,
    nextDateBilling: null,
    plan: planDefault(),
    processing: null
  };
};
