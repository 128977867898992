import { ICurrentSubscription, ISubscription } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface ISubscriptionHttpService
  extends ICRUDHttpService<ISubscription> {
  // getSubscriptionList(filter: ISubscriptionFilter): Promise<any>;
  addCard(tokenCard: string): Promise<any>;
  updateCard(tokenCard: string): Promise<any>;
  getCardList(): Promise<any>;
  subscribePlan(id: number): Promise<any>;
  getCurrentSubscription(): Promise<ICurrentSubscription>;
  cancelPlan(): Promise<any>;
}

export function SubscriptionHttpService(options: IHttpServiceClientOptions): ISubscriptionHttpService {
  const basePath = '/plans';
  // const baseUrl = options.httpService.getUrl(basePath);
  const cardUrl = options.httpService.getUrl('/cards');

  function addCard(tokenCard: string): Promise<any> {
    return options.httpService.post(cardUrl, {
      tokenCard
    });
  }
  function getCardList(): Promise<any> {
    return options.httpService.get(cardUrl);
  }
  function updateCard(tokenCard: string): Promise<any> {
    return options.httpService.put(cardUrl, {
      tokenCard
    });
  }
  function subscribePlan(id: number): Promise<any> {
    const subscribePlanUrl = options.httpService.getUrl(`/subscriptions/plans/${id}`);

    return options.httpService.post(subscribePlanUrl, {});
  }

  function getCurrentSubscription(): Promise<ICurrentSubscription> {
    const currentSubUrl = options.httpService.getUrl('/subscriptions');

    return options.httpService.get(currentSubUrl);
  }

  function cancelPlan(): Promise<any> {
    const cancelSubUrl = options.httpService.getUrl('/subscriptions/cancel');

    return options.httpService.post(cancelSubUrl, {});
  }

  return {
    ...CRUDHttpService<ISubscription>({
      ...options,
      basePath
    }),
    addCard,
    getCardList,
    updateCard,
    subscribePlan,
    getCurrentSubscription,
    cancelPlan
  };
}
