import { cloneDeep, find } from 'lodash';
import { CMABreakcrumb } from 'root/components';
import { formatTimeDuration } from 'root/helpers';
import { IProduct } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-breakcumb': CMABreakcrumb
  },
  props: {
    redeemed: Boolean,
    showSearch: {
      type: Boolean,
      default: true
    },
    searchText: String,
    loading: Boolean,
    loadingMore: Boolean,
    data: {
      type: Array,
      default: () => []
    },
    showProcess: {
      type: Boolean,
      default: true
    }
  }
})

export class RedeemedList extends Vue {
  public formatTimeDuration = formatTimeDuration;
  public handleSearch() {
    this.$emit('search');
  }
  public changeSearchText(newValue) {
    this.$emit('update:searchText', newValue);
  }

  public getThumbnail(model: IProduct) {
    const images = cloneDeep(model).images;

    return find(images, (e) => e.isPrimary).src;
  }

  public onTouch(el: Element) {
    const productId = el.getAttribute('data-productId'),
      id = el.getAttribute('data-id');

    this.clickTitle(productId, id);
  }

  public clickTitle(productId: number | string, redeemedId: number | string) {
    this.$emit('click', {
      productId,
      redeemedId
    });
  }
}
