import { cloneDeep, findIndex, map } from 'lodash';
import { IProduct } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeProduct } from './types';

export interface IProductState extends ICRUDState<IProduct> {
  loading: boolean;
  loadingMore: boolean;
}

export const defaultState: IProductState = {
  ...CRUDState(),
  loading: false,
  loadingMore: false,
  filterParams: {
    method: '',
    status: ''
  }
};

export const mutations = {
  ...crudMutations('redemption'),
  [MutationTypeProduct.ProductLoading](state: IProductState) {
    state.loading = true;
  },
  [MutationTypeProduct.ProductLoaded](state: IProductState) {
    state.loading = false;
  },
  [MutationTypeProduct.ProductLoadingMore](state: IProductState) {
    state.loadingMore = true;
  },
  [MutationTypeProduct.ProductLoadedMore](state: IProductState) {
    state.loadingMore = false;
  },
  [MutationTypeProduct.SetProductList](state: IProductState, payload) {
    state.data = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeProduct.clearProductList](state: IProductState) {
    state.data = [];
    state.pagination = {
      page: 1
    };
  },
  [MutationTypeProduct.UpdateProductList](state: IProductState, payload) {
    let nextData: IProduct[] = payload.data;
    const data = cloneDeep(state.data);
    nextData = nextData.filter((e) => e);

    map(nextData, (_nextData) => {
      if (findIndex(data, (e) => e.id === _nextData.id) < 0) {
        data.push(_nextData);
      }

      return;
    });

    state.data = data;
    state.pagination = payload.pagination;
  }
};
