export enum ActionTypeSubscription {
  ShowPopupFreePlan = 'ShowPopupFreePlan',
  ShowPopupSubcribeMember = 'ShowPopupSubcribeMember',
  ShowPopupCancelMember = 'ShowPopupCancelMember',
  ShowPopupCancelFreeTrial = 'ShowPopupCancelFreeTrial',
  ShowPopupSwitchMember = 'ShowPopupSwitchMember',

  AddStripeCard = 'AddStripeCard',
  UpdateStripeCard = 'UpdateStripeCard',
  GetStripeCardList = 'GetStripeCardList',

  GetPlansList = 'GetPlansList',
  SubscribePlan = 'SubscribePlan',

  GetCurrentSubscription = 'GetCurrentSubscription',
  CancelPlan = 'cancelPlan'
}

export enum MutationTypeSubscription {
  CardLoading = 'CardLoading',
  CardLoaded = 'CardLoaded',
  SetStripeCardList = 'SetStripeCardList',

  SetPlansList = 'setPlansList',
  PlanLoading = 'planLoading',
  PlanLoaded = 'planLoaded',

  SetCurrentSubscription = 'setCurrentSubscription',
  SetPlanSelectedLocal = 'SetPlanSelectedLocal',
  ClearPlanSelectedLocal = 'ClearPlanSelectedLocal'
}
