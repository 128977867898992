import { cloneDeep, findIndex, map } from 'lodash';
import { IAssignment, IAssignmentGame, MethodType } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeAssignment } from './types';

export interface IAssignmentState extends ICRUDState<IAssignment> {
  loading: boolean;
  loadingMore: boolean;
  game: IAssignmentGame;
}

export const defaultState: IAssignmentState = {
  ...CRUDState(),
  loading: false,
  loadingMore: false,
  filterParams: {
    method: '',
    status: ''
  },
  game: {
    id: null,
    type: MethodType.Empty
  }
};

export const mutations = {
  ...crudMutations('assignment'),
  [MutationTypeAssignment.AssignmentLoading](state: IAssignmentState) {
    state.loading = true;
  },
  [MutationTypeAssignment.AssignmentLoaded](state: IAssignmentState) {
    state.loading = false;
  },
  [MutationTypeAssignment.AssignmentLoadingMore](state: IAssignmentState) {
    state.loadingMore = true;
  },
  [MutationTypeAssignment.AssignmentLoadedMore](state: IAssignmentState) {
    state.loadingMore = false;
  },
  [MutationTypeAssignment.SetAssignmentList](state: IAssignmentState, payload) {
    state.data = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeAssignment.clearAssignmentList](state: IAssignmentState) {
    state.data = [];
    state.pagination = {
      page: 1
    };
  },
  [MutationTypeAssignment.UpdateAssignmentList](state: IAssignmentState, payload) {
    let nextData: IAssignment[] = payload.data;
    const data = cloneDeep(state.data);
    nextData = nextData.filter((e) => e);

    map(nextData, (_nextData) => {
      if (findIndex(data, (e) => e.id === _nextData.id) < 0) {
        data.push(_nextData);
      }

      return;
    });

    state.data = data;
    state.pagination = payload.pagination;
  },
  [MutationTypeAssignment.SetAssignmentGame](state: IAssignmentState, payload: IAssignmentGame) {
    state.game = payload;
  },
  [MutationTypeAssignment.ResetAssignmentGame](state: IAssignmentState) {
    state.game = {
      id: null,
      type: MethodType.Empty
    };
  }
};
