import { find } from 'lodash';
import { IProductState } from './mutations';
import { GetterTypeProduct } from './types';

export const getters = {
  [GetterTypeProduct.GetProductDetail](state: IProductState) {
    return (id: number) => {
      return find(state.data, (e) => e.id.toString() === id.toString());
    };
  }
};
