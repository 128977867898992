import { Gender } from './Common';
import { MethodType } from './Game';

export interface IBoard {
  id?: number;
  centre: ICentre;
  method: MethodType;
  age: number;
  gender: Gender;
  startDate: Date;
  endDate: Date;
  fullName: string;
}

export interface IBoardFilter {
  centre: ICentre;
  method: MethodType;
  age: number;
  gender: Gender;
  startDate: Date;
  endDate: Date;
  fullName: string;
  page?: number;
  nextPageToken?: string;
  level?: number;
  grade?: number;
}

export enum BoardFilter {
  EarnedPoints = 'earned',
  BoardPoints = 'Board'
}

export interface ICentre {
  value: string;
  name: string;
}

export const boardFilterDefault = (): IBoardFilter => {
  return {
    fullName: '',
    centre: null,
    method: MethodType.Empty,
    age: null,
    page: 1,
    gender: null,
    startDate: null,
    endDate: null,
    level: null,
    grade: null
  };
};
