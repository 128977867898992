import { getLocaleString, ILanguageDictionary } from '@hgiasac/helper';
import { find } from 'lodash';
import { i18n } from 'root/locales';
import { Gender, MethodType } from 'root/models';
// import { cloneDeep } from 'lodash';
// import { listFileIcon } from 'root/helpers';
import { GetterTypeAuthUser, GetterTypeGlobal, IState } from './types';

export const getters = {
  getLocaleString(state: IState) {
    return (val: string | ILanguageDictionary): string => {
      return getLocaleString(val, state.global.currentLocale);
    };
  },
  methodOptions: () => [
    {
      value: MethodType.Empty,
      label: i18n().t('all.methods')
    },
    {
      value: MethodType.AB,
      label: i18n().t(MethodType.Abacus)
    },
    {
      value: MethodType.AuralMental,
      label: i18n().t(MethodType.AuralMental)
    },
    {
      value: MethodType.FM,
      label: i18n().t(MethodType.FM)
    },
    {
      value: MethodType.MA,
      label: i18n().t(MethodType.MA)
    }
  ],
  ageOptions() {
    const result: any = [{
      value: null,
      label: 'Select'
    }];
    for (let index = 6; index < 20; index++) {
      result.push({
        value: index,
        label: index
      });
    }

    return result;
  },
  genderOptions() {
    return [
      { value: Gender.Male, label: i18n().t(Gender.Male).toString() },
      { value: Gender.Female, label: i18n().t(Gender.Female).toString() },
    ];
  },
  [GetterTypeAuthUser.UserCurrentLevel](state: IState) {
    const authUser = state.global.authUser;

    return authUser ? authUser.level : 0;
  },
  [GetterTypeGlobal.GetCentreValue](state: IState) {
    return (name) => {
      const current = find(state.global.globalConfig.listCentre, (e) => e.name === name);

      return current ? current.value : '';
    };
  },
  gradeOptions: () => {
    return ['Foundation', 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 'DAN'].map((e) => ({
      label: e,
      value: e === 'DAN' ? 0 : e === 'Foundation' ? 14 : e
    }));
  },
  isLimitedAccess: (state: IState) => {
    const authUser = state.global.authUser,
      { subscription, isFullAccess }: any = authUser ? authUser : {};
    const {processing}: any = subscription ? subscription : {};

    if (isFullAccess) {
      return false;
    }

    return !subscription || processing === 'fail'
      || processing === 'processing'
      || processing === 'cancel';
  }
};
