export interface IStripCardForm {
  number: boolean;
  expiry: boolean;
  cvc: boolean;
  id?: number;
}

export const stripeCardFormDefault = (): IStripCardForm => {
  return {
    number: false,
    expiry: false,
    cvc: false
  };
};

export interface IPlan {
  id: number;
  name: string;
  duration: number;
  price: number;
  type?: PlanType;
}

export const planDefault = (): IPlan => {
  return {
    id: null,
    name: '',
    duration: null,
    price: null
  };
};

export enum PlanType {
  Free = 'free',
  Paid = 'paid'
}
