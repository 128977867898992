import { IAssignmentState } from './mutations';
import { GetterTypeAssignment } from './types';

export const getters = {
  levelOptions() {
    const result = [];
    for (let index = 1; index < 14; index++) {
      result.push({
        value: index,
        label: index
      });
    }

    return result;
  },
  [GetterTypeAssignment.GetAssignmentById](state: IAssignmentState) {
    return (id: number | string) => {
      return state.data.find((e) => e.id.toString() === id.toString());
    };
  }
};
