export enum MutationTypeProfile {
  LoadingProfile = 'loadingProfile',
  ProfileLoading = 'profileLoading',
  ProfileLoaded = 'profileLoaded',
  ProfileUpdate = 'ProfileUpdate',
  SetPointHistory = 'setPointHistory',
  UpdatePointHistory = 'updatePointHistory',
  SetPointHistoryLoading = 'setPointHistoryLoading',
  SetPointHistoryLoadingMore = 'setPointHistoryLoadingMore',
  SetPointHistoryLoaded = 'setPointHistoryLoaded',
  SetPointHistoryLoadedMore = 'setPointHistoryLoadedMore',

  SetPointRedemption = 'setPointRedemption',
  UpdatePointRedemption = 'updatePointRedemption',
  SetPointRedemptionLoading = 'setPointRedemptionLoading',
  SetPointRedemptionLoadingMore = 'setPointRedemptionLoadingMore',
  SetPointRedemptionLoaded = 'setPointRedemptionLoaded',
  SetPointRedemptionLoadedMore = 'setPointRedemptionLoadedMore',
  SetNewNotify = 'SetNewNotify'
}

export enum ActionTypeProfile {
  ProfileUpdate = 'profileUpdate',
  LoadingProfile = 'loadingProfile',
  Register = 'register',
  GetPointHistory = 'GetPointHistory',
  GetPointRedemption = 'GetPointRedemption'
}
