export interface IPhoneInput {
  code?: string;
  number: string;
}

export enum UpdatedByType {
  Customer = 'customer',
  Staff = 'staff',
  Admin = 'admin',
  System = 'system'
}

export enum StatusCode {
  Active = 'active',
  Inactive = 'inactive',
  Disabled = 'disabled',
  Deleted = 'deleted',
  Deactivate = 'deactivate'
}

export enum CRUDAction {
  Create = 'create',
  Update = 'update',
  Delete = 'delete'
}

export interface IPagingParams {
  page: number;
  size: number;
  order?: string;
  descending?: boolean;
  nextPageToken?: string;
}

export interface IPagingWordpressParams {
  page: number;
  per_page: number;
}

export interface IPagingMeta {
  page: number;
  size: number;
  totalPages: number;
  total: number;
}

export interface IPagingResult<T> {
  data: T[];
  pagination: IPagingMeta;
}

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export interface IFileInfo {
  url: string;
  fullPath: string;
  bucketName?: string;
}

export interface IImageUrl {
  url: string;
  thumbnailUrl: string;
}

export const phoneCodeDefault = '+65';

export interface IBreakcumb {
  label: string;
  url: string;
  currently: boolean;
}

export interface IListCountry {
  countryName: string;
  phoneCode: number;
}

export enum LocalStorageType {
  CurrentToken = 'currentToken',
  LogUser = 'logUser'
}
