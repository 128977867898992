import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

import { CMAAvatar } from 'root/components/CMAAvatar';
import { formatCurrency } from 'root/helpers';
import { IUser } from 'root/models';
import './style.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      newNotify: (state: IState) => state.profile.newNotify,
      // loading: (state: IState) => state.profile.loading
    }),
    isNewNotification() {
      return this.authUser.isNewNotification;
    }
  },
  components: {
    'cma-avatar': CMAAvatar
  },
  watch: {
    newNotify(newValue) {
      this.currentNewNotify = newValue;
    },
    isNewNotification(newValue) {
      if (newValue) {
        this.currentNewNotify = newValue;
      }
    }
  }
})

export class InformationProfile extends Vue {
  public firstTime: boolean = true;
  public currentNewNotify: boolean = false;
  public isNewNotification: boolean;
  public authUser: IUser;

  public getScore(score: number) {
    return formatCurrency(score);
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionType.ReloadProfile, {
        onSuccess: () => {
          if (this.firstTime) {
            this.currentNewNotify = !!localStorage.getItem('newNotify') || this.isNewNotification;
            this.firstTime = false;
          }
        }
      });
    });
  }

}
