import { RouteConfig } from 'vue-router';
import { ContactUs } from './component';

export const ContactUsRouter: RouteConfig = {
  path: '/contact-us',
  component: ContactUs,
  meta: {
    isGuest: true
  }
};
