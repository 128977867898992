import { cmaHttpClient } from 'root/api/http';
import { ActionType, IState } from 'root/store';
import { ActionContext } from 'vuex';
import { IContactState } from './mutations';
import { ActionTypeContact, MutationTypeContact } from './types';

const { getContactUs } = cmaHttpClient.wordpress;

export const actions = {
  async [ActionTypeContact.GetCentre](
    { dispatch, commit }: ActionContext<IContactState, IState>
  ) {
    commit(MutationTypeContact.contactLoading);

    try {
      const data = await getContactUs();
      commit(MutationTypeContact.SetCentreList, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeContact.contactLoaded);
  }
};
