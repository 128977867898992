import { stripObject } from '@hgiasac/helper';
import { omit } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IProductState } from './mutations';
import { ActionTypeProduct, MutationTypeProduct } from './types';

const { getProductList } = cmaHttpClient.product;

export const actions = {
  ...crudActions('redemption', {}),
  async [ActionTypeProduct.FilterProduct](
    {commit, dispatch }: ActionContext<IProductState, IState>, dataFilter: any
  ) {
    dataFilter = <any> stripObject(dataFilter);

    if (dataFilter.page && dataFilter.page === 1) {
      commit(MutationTypeProduct.ProductLoading);
    } else {
      commit(MutationTypeProduct.ProductLoadingMore);
    }

    try {
      const filter: any = omit(dataFilter, 'page');
      const data = await getProductList(filter);

      if (dataFilter.page === 1) {
        commit(MutationTypeProduct.SetProductList, data);
        commit(MutationTypeProduct.ProductLoaded);
      } else {
        commit(MutationTypeProduct.UpdateProductList, data);
        commit(MutationTypeProduct.ProductLoadedMore);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  }
};
