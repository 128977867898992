import { stripObject } from '@hgiasac/helper';
import { omit } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { IAssignmentFilter } from 'root/models';
import { MutationTypeGame } from 'root/pages/Game/Store/types';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IAssignmentState } from './mutations';
import { ActionTypeAssignment, MutationTypeAssignment } from './types';

const { getAssignmentList, getAssignmentGame, getById } = cmaHttpClient.assignment;

export const actions = {
  ...crudActions('assignment', {
    getById
  }),
  async [ActionTypeAssignment.FilterAssignment](
    {commit, dispatch }: ActionContext<IAssignmentState, IState>, dataFilter: IAssignmentFilter
  ) {
    dataFilter = <any> stripObject(dataFilter);

    if (dataFilter.page && dataFilter.page === 1) {
      commit(MutationTypeAssignment.AssignmentLoading);
    } else {
      commit(MutationTypeAssignment.AssignmentLoadingMore);
    }

    try {
      const filter: any = omit(dataFilter, 'page');
      const data = await getAssignmentList(filter);

      if (dataFilter.page === 1) {
        commit(MutationTypeAssignment.SetAssignmentList, data);
        commit(MutationTypeAssignment.AssignmentLoaded);
      } else {
        commit(MutationTypeAssignment.UpdateAssignmentList, data);
        commit(MutationTypeAssignment.AssignmentLoadedMore);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      commit(MutationTypeAssignment.AssignmentLoadedMore);
      commit(MutationTypeAssignment.AssignmentLoaded);
    }
  },
  async [ActionTypeAssignment.GetAssignmentGame](
    { commit, dispatch}: ActionContext<IAssignmentState, IState>, id: number
  ) {
    commit(MutationTypeGame.SetBonusLoading);

    try {
      const data = await getAssignmentGame(id);

      commit(MutationTypeGame.SetBonusGame, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeGame.SetBonusLoaded);
  }
};
