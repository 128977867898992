import { Message, MessageBox } from 'element-ui';
import { ElMessageBoxComponent } from 'element-ui/types/message-box';
import { cloneDeep, isFunction } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { cmaFormatDateTime } from 'root/helpers';
import { i18n } from 'root/locales';
import { planDefault, IPlan } from 'root/models';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { ISubscriptionState } from './mutations';
import { ActionTypeSubscription, MutationTypeSubscription } from './types';

const { addCard, getCardList, updateCard,
  filter, subscribePlan,
  getCurrentSubscription, cancelPlan } = cmaHttpClient.subscription;

export const actions = {
  ...crudActions('plan', {
    // 
  }),
  [ActionTypeSubscription.ShowPopupFreePlan](
    { commit }: ActionContext<ISubscriptionState, IState>, plan: IPlan
  ) {
    showPopupSubscription({
      showCancelButton: false,
      title: i18n().t('subscription.free_trial.title',
      {days: `${plan.duration} ${plan.duration > 1 ? 'weeks' : 'week'}`}).toString(),
      message: i18n().t('subscription.free_trial.message',
        {days: `${plan.duration} ${plan.duration > 1 ? 'weeks' : 'week'}`}).toString(),
      confirmButtonText: i18n().t('ok').toString(),
      cancelButtonText: i18n().t('later').toString()
    });
    commit(MutationType.ClearFirstLogin);
  },
  [ActionTypeSubscription.ShowPopupSubcribeMember](
    { dispatch, state }: ActionContext<ISubscriptionState, IState>, {
      plan,
      onSuccess,
      onFailure,
      onCancel,
      showAddCreditCard
    }
  ) {
    const cards = state.cards,
      card = cards && cards.length > 0 ? cards[0] : {};

    showPopupSubscription({
      // title: i18n().t('subscription.subscribe_member.title', {months: 6}).toString(),
      title: plan.name,
      message: i18n().t('subscription.subscribe_member.message',
        {months: `${plan.duration} ${plan.duration > 1 ? 'months' : 'month'}`}).toString(),
      confirmButtonText: i18n().t('confirm').toString(),
      cancelButtonText: i18n().t('cancel').toString(),
      handelClose: () => {
        onCancel();
      },
      handler: (done: any) => {
        /**
         * if user already had saved card before
         * @TODO: handle subscribe plan
         */
        if (card.id) {
          dispatch(ActionTypeSubscription.SubscribePlan, {
            id: plan.id,
            onSuccess: () => {
              Message.success('Subscribe the plan successfully!');
              dispatch(ActionTypeSubscription.GetCurrentSubscription);
              if (isFunction(onSuccess)) {
                onSuccess();
              }
              done();
            },
            onFailure: () => {
              done();
              onFailure();
            }
          });

          return;
        }
        /**
         * user haven't saved card yet
         * @todo: show input card form
         * make sure have FUNCTION to show add credit card
         */
        if (isFunction(showAddCreditCard)) {
          showAddCreditCard();
          done();
        } else {
          console.error('Need to add function to show add credit card');
        }
      }
    });
  },
  [ActionTypeSubscription.ShowPopupCancelMember](
    { dispatch, commit, state }: ActionContext<ISubscriptionState, IState>
  ) {
    const current = cloneDeep(state.current),
      { subscription } = current;
    showPopupSubscription({
      title: i18n().t('subscription.cancel_member.title', {name: subscription.plan.name}).toString(),
      message: i18n().t('subscription.cancel_member.message',
        {date: cmaFormatDateTime(subscription.nextDateBilling)}).toString(),
      confirmButtonText: i18n().t('confirm').toString(),
      cancelButtonText: i18n().t('cancel').toString(),
      handler: (done: any) => {
        dispatch(ActionTypeSubscription.CancelPlan, {
          onSuccess: () => {
            commit(MutationTypeSubscription.ClearPlanSelectedLocal);
            done();
          }
        });
      }
    });
  },
  [ActionTypeSubscription.ShowPopupCancelFreeTrial](
    {}: ActionContext<ISubscriptionState, IState>
  ) {
    showPopupSubscription({
      title: i18n().t('subscription.cancel_free_trial.title').toString(),
      message: i18n().t('subscription.cancel_free_trial.message', {date: '12/01/2020'}).toString(),
      confirmButtonText: i18n().t('confirm').toString(),
      cancelButtonText: i18n().t('cancel').toString()
    });
  },
  [ActionTypeSubscription.ShowPopupSwitchMember](
    { dispatch, state }: ActionContext<ISubscriptionState, IState>, {
      onSuccess,
      onFailure,
      onCancel
    }
  ) {
    const plan = state.planSelectedLocal;
    showPopupSubscription({
      title: i18n().t('subscription.switch_member.title', {name: plan.name}).toString(),
      message: i18n().t('subscription.switch_member.message').toString(),
      confirmButtonText: i18n().t('confirm').toString(),
      cancelButtonText: i18n().t('cancel').toString(),
      handelClose: () => {
        onCancel();
      },
      handler: (done: any) => {
        dispatch(ActionTypeSubscription.SubscribePlan, {
          id: plan.id,
          onSuccess: () => {
            Message.success('Switched the plan successfully!');
            onSuccess();
            done();
          },
          onFailure: () => {
            onFailure();
            done();
          }
        });
      }
    });
  },
  async [ActionTypeSubscription.AddStripeCard](
    { commit, dispatch }: ActionContext<ISubscriptionState, IState>, {token, onSuccess, onFailure}
  ) {
    commit(MutationTypeSubscription.CardLoading);
    try {
      await addCard(token);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      onFailure();
    }
    commit(MutationTypeSubscription.CardLoaded);
  },
  async [ActionTypeSubscription.UpdateStripeCard](
    { commit, dispatch }: ActionContext<ISubscriptionState, IState>, {token, onSuccess, onFailure}
  ) {
    commit(MutationTypeSubscription.CardLoading);
    try {
      await updateCard(token);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      onFailure();
    }
    commit(MutationTypeSubscription.CardLoaded);
  },
  async [ActionTypeSubscription.GetStripeCardList](
    { commit, dispatch }: ActionContext<ISubscriptionState, IState>
  ) {
    commit(MutationTypeSubscription.CardLoading);
    try {
      const data = await getCardList();
      commit(MutationTypeSubscription.SetStripeCardList, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeSubscription.CardLoaded);
  },
  async [ActionTypeSubscription.GetPlansList](
    { commit, dispatch }: ActionContext<ISubscriptionState, IState>
  ) {
    commit(MutationTypeSubscription.PlanLoading);
    try {
      const data = await filter();
      commit(MutationTypeSubscription.SetPlansList, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeSubscription.PlanLoaded);
  },
  async [ActionTypeSubscription.SubscribePlan](
    { commit, dispatch }: ActionContext<ISubscriptionState, IState>, {
      id,
      onSuccess,
      onFailure
    }
  ) {
    commit(MutationTypeSubscription.PlanLoading);
    try {
      await subscribePlan(id);
      onSuccess();
      dispatch(ActionTypeSubscription.GetCurrentSubscription);
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSubscription.GetCurrentSubscription](
    { commit, dispatch, rootState }: ActionContext<ISubscriptionState, IState>
  ) {
    try {
      const data = await getCurrentSubscription();
      const plan = data.subscription ? data.subscription.plan : planDefault();

      if (rootState.global.firstLogin) {
        dispatch(ActionTypeSubscription.ShowPopupFreePlan, plan);
      }
      // if (!plan.id) {
      //   dispatch(ActionTypeSubscription.ShowPopupFreePlan);
      // }
      commit(MutationTypeSubscription.SetCurrentSubscription, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSubscription.CancelPlan](
    { commit, dispatch }: ActionContext<ISubscriptionState, IState>, {
      onSuccess
    }
  ) {
    commit(MutationTypeSubscription.PlanLoading);
    try {
      await cancelPlan();
      onSuccess();
      Message.success('Cancel Plan successfully!');
      dispatch(ActionTypeSubscription.GetCurrentSubscription);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeSubscription.PlanLoaded);
  }
};

interface IMessageBox extends ElMessageBoxComponent {
  close: () => {};
}

interface IMessageBoxOption {
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
  showCancelButton?: boolean;
  // tslint:disable-next-line:ban-types
  handelClose?: Function;
  // tslint:disable-next-line:ban-types
  handler?: Function;
}

function showPopupSubscription(option: IMessageBoxOption) {
  MessageBox({
    title: option.title,
    message: option.message,
    showCancelButton: 'showCancelButton' in option ? option.showCancelButton : true,
    confirmButtonText: option.confirmButtonText,
    cancelButtonText: option.cancelButtonText,
    customClass: 'subscription-popup',
    beforeClose: (action, instance: IMessageBox, done) => {
      switch (action) {
      case 'confirm':
        instance.confirmButtonLoading = true;
        if (typeof(option.handler) === 'function') {
          option.handler(() => {
            instance.confirmButtonLoading = false;
            done();
          });
        } else {
          instance.confirmButtonLoading = false;
          done();
        }
        break;
      case 'cancel':
        instance.close();
        if (isFunction(option.handelClose)) {
          option.handelClose();
        }
        break;
      case 'close':
        instance.close();
        if (isFunction(option.handelClose)) {
          option.handelClose();
        }
        break;
      default:
        break;
      }
    }
  }).catch(() => {
    // no need to handle
  });
}
