import { AssignmentStatus, IAssignment, IAssignmentFilter, MethodType } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IAssignmentHttpService
  extends ICRUDHttpService<IAssignment> {
  getAssignmentList(filter: IAssignmentFilter): Promise<any>;
  getAssignmentGame(id: number): Promise<any>;
}

export function AssignmentHttpService(options: IHttpServiceClientOptions): IAssignmentHttpService {
  const basePath = '/assignments';
  const baseUrl = options.httpService.getUrl(basePath);

  function getAssignmentList(filter: IAssignmentFilter = {
    method: MethodType.Empty,
    status: AssignmentStatus.Default
  }) {
    return options.httpService.get(baseUrl, {
      params: filter
    });
  }

  function getAssignmentGame(id: number) {
    const assignmentUrl = options.httpService.getUrl(`${basePath}/${id}/games`);

    return options.httpService.get(assignmentUrl);
  }

  return {
    ...CRUDHttpService<IAssignment>({
      ...options,
      basePath
    }),
    getAssignmentList,
    getAssignmentGame
  };
}
