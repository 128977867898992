import { cloneDeep, findIndex, map } from 'lodash';
import { IUser } from 'root/models';
import { CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeProfile } from './types';

export interface IPointHistory extends ICRUDState<any> {
  loadingMore: boolean;
}
export interface IPointRedemption extends ICRUDState<any> {
  loadingMore: boolean;
}
export interface IProfileState extends ICRUDState<IUser> {
  loading: boolean;
  pointHistory: IPointHistory;
  pointRedemption: IPointRedemption;
  newNotify: boolean;
}

export const defaultState: IProfileState = {
  ...CRUDState(),
  loading: false,
  pointHistory: {
    ...CRUDState(),
    loadingMore: false
  },
  pointRedemption: {
    ...CRUDState(),
    loadingMore: false
  },
  newNotify: false
};

export const mutations = {
  [MutationTypeProfile.LoadingProfile](state: IProfileState, loading: boolean) {
    state.loading = loading;
  },
  [MutationTypeProfile.ProfileLoading](state: IProfileState) {
    state.loading = true;
  },
  [MutationTypeProfile.ProfileLoaded](state: IProfileState) {
    state.loading = false;
  },
  [MutationTypeProfile.SetPointHistoryLoading](state: IProfileState) {
    state.pointHistory.loading = true;
  },
  [MutationTypeProfile.SetPointHistoryLoaded](state: IProfileState) {
    state.pointHistory.loading = false;
  },
  [MutationTypeProfile.SetPointHistoryLoadingMore](state: IProfileState) {
    state.pointHistory.loadingMore = true;
  },
  [MutationTypeProfile.SetPointHistoryLoadedMore](state: IProfileState) {
    state.pointHistory.loadingMore = false;
  },
  [MutationTypeProfile.SetPointHistory](state: IProfileState, payload) {
    state.pointHistory.data = payload.data;
    state.pointHistory.pagination = payload.pagination;
  },
  [MutationTypeProfile.UpdatePointHistory](state: IProfileState, payload) {
    let nextData: any[] = payload.data;
    const data = cloneDeep(state.pointHistory.data);
    nextData = nextData.filter((e) => e);

    map(nextData, (_nextData) => {
      if (findIndex(data, (e) => e.id === _nextData.id) < 0) {
        data.push(_nextData);
      }

      return;
    });

    state.pointHistory.data = data;
    state.pointHistory.pagination = payload.pagination;
  },
  [MutationTypeProfile.SetPointRedemptionLoading](state: IProfileState) {
    state.pointRedemption.loading = true;
  },
  [MutationTypeProfile.SetPointRedemptionLoaded](state: IProfileState) {
    state.pointRedemption.loading = false;
  },
  [MutationTypeProfile.SetPointRedemptionLoadingMore](state: IProfileState) {
    state.pointRedemption.loadingMore = true;
  },
  [MutationTypeProfile.SetPointRedemptionLoadedMore](state: IProfileState) {
    state.pointRedemption.loadingMore = false;
  },
  [MutationTypeProfile.SetPointRedemption](state: IProfileState, payload) {
    state.pointRedemption.data = payload.data;
    state.pointRedemption.pagination = payload.pagination;
  },
  [MutationTypeProfile.UpdatePointRedemption](state: IProfileState, payload) {
    let nextData: any[] = payload.data;
    const data = cloneDeep(state.pointRedemption.data);
    nextData = nextData.filter((e) => e);

    map(nextData, (_nextData) => {
      if (findIndex(data, (e) => e.id === _nextData.id) < 0) {
        data.push(_nextData);
      }

      return;
    });

    state.pointRedemption.data = data;
    state.pointRedemption.pagination = payload.pagination;
  },
  [MutationTypeProfile.SetNewNotify](state: IProfileState, payload) {
    if (payload) {
      localStorage.setItem('newNotify', payload);
    } else {
      localStorage.removeItem('newNotify');
    }
    state.newNotify = payload;
  }
};
