import { RouteConfig } from 'vue-router';
import { NotificationContainer } from './NotificationContainer';
import { NotificationList } from './NotificationList';
import { NotificationView } from './NotificationView';

export enum NotificationRouterPath {
  Default = '/notifications',
  View = '/notifications/:notifyId/view'
}
export enum NotificationRouterName {
  View = 'notificationsView'
}

export const NotificationRouter: RouteConfig = {
  path: NotificationRouterPath.Default,
  component: NotificationContainer,
  children: [
    {
      path: '',
      component: NotificationList
    },
    {
      path: NotificationRouterPath.View,
      component: NotificationView,
      name: NotificationRouterName.View
    },
    {
      path: '*',
      redirect: NotificationRouterPath.Default
    }
  ]
};
