import { cloneDeep, find } from 'lodash';
import { planDefault, IPlan, IStripCardForm, ProcessingSubscription } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeSubscription, MutationTypeSubscription } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      plans: (state: IState) => state.subscription.data,
      cards: (state: IState) => state.subscription.cards,
      current: (state: IState) => state.subscription.current,
      planSelectedLocal: (state: IState) => state.subscription.planSelectedLocal
    }),
    scheduledPlan() {
      return this.current.scheduleSubscription ? this.current.scheduleSubscription.plan : planDefault();
    },
    currentPlan() {
      return this.current.subscription ? this.current.subscription.plan : planDefault();
    },
    card() {
      const cards = this.cards;

      return cards && cards.length > 0 ? cards[0] : {};
    },
    currentPlanId: {
      get() {
        if (this.planSelectedLocal.id) {
          return this.planSelectedLocal.id;
        }
        if (this.scheduledPlan.id) {
          return this.scheduledPlan.id;
        }
        // if (this.currentPlan.id &&
        //     (this.current.subscription.processing === ProcessingSubscription.Success
        //       || this.current.subscription.processing === ProcessingSubscription.CancelPeriodEndAt)) {
        //   return this.currentPlan.id;
        // }
        if (this.currentPlan.id && this.current.subscription.processing !== ProcessingSubscription.CancelPeriodEndAt) {
          return this.currentPlan.id;
        }
      },
      set(value: any) {
        const planSelected = find(cloneDeep(this.plans), (e) => e.id === value);
        this.$store.commit(MutationTypeSubscription.SetPlanSelectedLocal, planSelected);
      }
    }
  }
})

export class SubscriptionOption extends Vue {
  public card: IStripCardForm;
  public plans: IPlan[];
  public showSwitchBtn: boolean = false;
  public planSelectedLocal: IPlan;

  public scheduledPlan: IPlan;
  public currentPlan: IPlan;

  public change(value: number) {
    const planSelected = find(cloneDeep(this.plans), (e) => e.id === value);
    this.$store.commit(MutationTypeSubscription.SetPlanSelectedLocal, planSelected);
    /**
     * when have scheduled one plan
     */
    if (this.scheduledPlan.id) {
      this.showSwitchBtn = true;

      return;
    }
    this.$store.dispatch(ActionTypeSubscription.ShowPopupSubcribeMember, {
      plan: planSelected,
      onSuccess: () => {
        this.$store.commit(MutationTypeSubscription.ClearPlanSelectedLocal);
      },
      onFailure: () => {
        this.$store.commit(MutationTypeSubscription.ClearPlanSelectedLocal);
      },
      onCancel: () => {
        this.$store.commit(MutationTypeSubscription.ClearPlanSelectedLocal);
      },
      showAddCreditCard: () => {
        this.$emit('showAddCreditCard');
      }
    });
  }

  public switchPlan() {
    this.$store.dispatch(ActionTypeSubscription.ShowPopupSwitchMember, {
      onSuccess: () => {
        this.$store.commit(MutationTypeSubscription.ClearPlanSelectedLocal);
        this.showSwitchBtn = false;
      },
      onFailure: () => {
        this.showSwitchBtn = false;
        this.$store.commit(MutationTypeSubscription.ClearPlanSelectedLocal);
      },
      onCancel: () => {
        this.showSwitchBtn = false;
        this.$store.commit(MutationTypeSubscription.ClearPlanSelectedLocal);
      },
    });
  }
  public cancelSwitch() {
    this.showSwitchBtn = false;
    this.$store.commit(MutationTypeSubscription.ClearPlanSelectedLocal);
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeSubscription.GetPlansList);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeSubscription.ClearPlanSelectedLocal);
  }
}
