import { RouteConfig } from 'vue-router';
import { RedeeemedContainer } from './RedeemedContainer';
import { Redemption } from './Redemption';

export const RedeemedRouter: RouteConfig = {
  path: 'redeemed',
  component: RedeeemedContainer,
  children: [
    {
      path: '',
      component: Redemption
    },
    // {
    //   path: 'new',
    //   component: AssignmentCreate
    // },
    // {
    //   path: ':assignmentId',
    //   component: AssignmentDetail
    // },
    // {
    //   path: ':assignmentId/edit',
    //   component: AssignmentCreate,
    //   name: AssignmentRouterName.AssignmentEdit
    // }
  ]
};
