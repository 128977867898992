import * as auth from '@aws-amplify/auth';
import { IAuthService, IResetPasswordForm } from './types';

const amplifyAuth = new auth.AuthClass({
  userPoolId: process.env.USER_POOL_ID,
  userPoolWebClientId: process.env.CLIENT_ID
});
// const poolRegion = process.env.POOL_REGION;

export function AmplifyAuthService(): IAuthService {
  return {
    signUpWithEmailAndPassword: ({userName, email, password}) => {
      return amplifyAuth.signUp(userName, password, email);
    },
    currentSession: () => amplifyAuth.currentSession(),
    currentUser: async () => {
      try {
        return await amplifyAuth.currentUserPoolUser();
      } catch (error) {
        return null;
      }

    },
    signOut: () => amplifyAuth.signOut(),
    getAccessToken: async () => {
      try {
        const currentUser = await amplifyAuth.currentSession();

        return currentUser.getAccessToken().getJwtToken();
      } catch (error) {
        return null;
      }
    },
    signIn: ({email, password}) => {
      return amplifyAuth.signIn(email, password);
    },
    logOut: () => {
      return amplifyAuth.signOut();
    },
    forgotPassword: (email: string) => amplifyAuth.forgotPassword(email),
    resetPassword: (form: IResetPasswordForm) => amplifyAuth.forgotPasswordSubmit(
      form.email,
      form.code,
      form.newPassword
    )
  };
}
