import { cloneDeep } from 'lodash';
import { stripeCardFormDefault, IPlan, IStripCardForm } from 'root/models';
import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { createToken, CardCvc, CardExpiry, CardNumber } from 'vue-stripe-elements-plus';
import { mapState } from 'vuex';
import { ActionTypeSubscription, MutationTypeSubscription } from '../../Store/types';
import './styles.scss';
// const _stripe = Stripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    isUpdating: Boolean
  },
  components: {
    CardNumber,
    CardExpiry,
    CardCvc
  },
  computed: {
    ...mapState({
      cardLoading: (state: IState) => state.subscription.cardLoading,
      planSelectedLocal: (state: IState) => state.subscription.planSelectedLocal,
    })
  },
  watch: {
    'form.number'() {
      this.update();
    },
    'form.expiry'() {
      this.update();
    },
    'form.cvc'() {
      this.update();
    }
  }

})

export class SubcriptionAddCard extends Vue {
  public $refs: {
    cardExpiry: CardExpiry,
    cardCvc: CardCvc,
    cardNumber: CardNumber
  };
  public stripe = process.env.STRIPE_KEY;
  public form: IStripCardForm = stripeCardFormDefault();
  public complete: boolean = false;
  public options: any = {
    style: {
      base: {
        color: '#354052',
        // fontFamily: 'Nunito-Regular',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#aab7c4'
        }
      }
    }
  };
  public planSelectedLocal: IPlan;

  public beforeOpen() {
    //
  }
  public handleClickCloseDialog() {
    this.form = stripeCardFormDefault();
    this.$emit('update:visible', false);
    this.$store.commit(MutationTypeSubscription.ClearPlanSelectedLocal);
  }

  public update() {
    this.complete = !!(this.form.number && this.form.expiry && this.form.cvc);

    // field completed, find field to focus next
    if (this.form.number) {
      if (!this.form.expiry) {
        this.$refs.cardExpiry.focus();
      } else if (!this.form.cvc) {
        this.$refs.cardCvc.focus();
      }
    } else if (this.form.expiry) {
      if (!this.form.cvc) {
        this.$refs.cardCvc.focus();
      } else if (!this.form.number) {
        this.$refs.cardNumber.focus();
      }
    }
    // no focus magic for the CVC field as it gets complete with three
    // numbers, but can also have four
  }

  public async submit() {
    this.$store.commit(MutationTypeSubscription.CardLoading);
    try {
      const data = await createToken();
      if (data && data.token) {
        if (this.$props.isUpdating) { // update card
          this.$store.dispatch(ActionTypeSubscription.UpdateStripeCard, {
            token: data.token.id,
            onSuccess: () => {
              this.$message.success('Add card sucessfully!');
              this.$store.dispatch(ActionTypeSubscription.GetStripeCardList);
              this.handleClickCloseDialog();
            },
            onFailure: () => {
              //
            }
          });
        } else {
          /**
           * Add new credit card
           */
          this.$store.dispatch(ActionTypeSubscription.AddStripeCard, {
            token: data.token.id,
            onSuccess: async () => {
              this.$message.success('Add card sucessfully!');
              this.$store.dispatch(ActionTypeSubscription.GetStripeCardList);
              /**
               * @When having planSelectedLocal
               * @Mean popup is showed when user is subscribing one plan
               * @And user did not save credit card yet
               * @Result after added credit card successfully, @need to subscribe this plan
               */
              if (this.planSelectedLocal && this.planSelectedLocal.id) {
                const plan = cloneDeep(this.planSelectedLocal);
                this.$store.dispatch(ActionTypeSubscription.SubscribePlan, {
                  id: plan.id,
                  onSuccess: () => {
                    this.$store.commit(MutationTypeSubscription.ClearPlanSelectedLocal);
                    this.handleClickCloseDialog();
                  },
                  onFailure: () => {
                    this.handleClickCloseDialog();
                  }
                });

                return;
              }
              this.handleClickCloseDialog();
            },
            onFailure: () => {
              //
            }
          });
        }
      } else {
        this.$store.dispatch(ActionType.CatchException, data.error);
      }
    } catch (error) {
      this.$store.dispatch(ActionType.CatchException, error);
    }
    this.$store.commit(MutationTypeSubscription.CardLoaded);
  }
}
