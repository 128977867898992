import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import { CMABreakcrumb } from 'root/components';
import { ruleRequired } from 'root/helpers';
import { feedbackFormDefault, IFeedbackForm, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-breakcumb': CMABreakcrumb
  },
  computed: ({
    ...mapState({
      authUser: (state: IState) => state.global.authUser
    })
  })
})

export class Feedback extends Vue {
  public $refs: {
    form: Form
  };
  public form: IFeedbackForm = feedbackFormDefault();
  private authUser: IUser;

  public get rules() {
    return {
      required: ruleRequired()
    };
  }
  public submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        const form = cloneDeep(this.form),
          { subject, message } = form;
        window.location.href = `mailto:elearning@cma.edu.sg?subject=${subject}&body=${message}`;
      }

      return;
    });
  }
  public cancel() {
    if (this.authUser && this.authUser.id) {
      this.$router.push('/account');

      return;
    }

    this.$router.push('/login');
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.BreakcumbChange, [{
        label: this.$t('account'),
        url: '/account'
      }, {
        label: this.$t('feedback'),
        url: '',
        currently: true
      }]);
    });
  }
}
