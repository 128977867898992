export enum ActionTypeGame {
  GetProgressive = 'GetProgressive',
  GetAbacus = 'GetAbacus',
  GetBonusGame = 'GetBonusGame',
  CreateGameHistory = 'CreateGameHistory'
}

export enum MutationTypeGame {
  SetProgressive = 'SetProgressive',
  SetAbacus = 'SetAbacus',
  SetProgressiveLoading = 'SetProgressiveLoading',
  SetProgressiveLoaded = 'SetProgressiveLoaded',
  SetProgressiveNextQuestion = 'SetProgressiveNextQuestion',
  StartTiming = 'StartTiming',
  PauseTiming = 'PauseTiming',
  StopTiming = 'StopTiming',
  ProgressiveConfigSetTab = 'ProgressiveConfigSetTab',
  ProgressiveSetConfig = 'ProgressiveSetConfig',
  ResetGameState = 'ResetGameState',
  AddPoint = 'AddPoint',
  AddCorrect = 'AddCorrect',
  UpdateGameResult = 'UpdateGameResult',
  SetResultTime = 'SetResultTime',
  SpeedSetConfig = 'SpeedSetConfig',
  FlashSetConfig = 'FlashSetConfig',
  FlashConfigSetTab = 'FlashConfigSetTab',
  AuralSetConfig = 'auralSetConfig',
  AuralConfigSetTab = 'AuralConfigSetTab',

  SetBonusGame = 'SetBonusGame',
  SetBonusLoading = 'SetBonusLoading',
  SetBonusLoaded = 'SetBonusLoaded',
  SetGameLevelPlaying = 'SetGameLevelPlaying',
  SetTotalTime = 'SetTotalTime',

  SetCurrentMapLevel = 'SetCurrentMapLevel',

  SetGameRouter = 'setGameRouter',
  AllowMusic = 'allowMusic'

}
