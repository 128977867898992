import { CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeContact } from './types';

export interface IContactState extends ICRUDState<any> {
  loadingMore: boolean;
}

export const defaultState: IContactState = {
  ...CRUDState(),
  loadingMore: false,
};

export const mutations = {
  [MutationTypeContact.SetCentreList](state: IContactState, data) {
    state.data = data;
  },
  [MutationTypeContact.contactLoading](state: IContactState) {
    state.loading = true;
  },
  [MutationTypeContact.contactLoaded](state: IContactState) {
    state.loading = false;
  },
  [MutationTypeContact.contactLoadingMore](state: IContactState) {
    state.loadingMore = true;
  },
  [MutationTypeContact.contactLoadedMore](state: IContactState) {
    state.loadingMore = false;
  }
};
