import { omit } from 'lodash';
import { defaultValue } from 'root/helpers';
import { i18n } from 'root/locales';
import { flashConfigDefault, progressiveConfigDefault, speedConfigDefault, IBreakcumb } from 'root/models';
import {
  IAlertState, IGlobalConfiguration,
  IGlobalState, IState, Layout, MutationType, TypeAlert
} from './types';

export function AlertState(): IAlertState {
  return {
    opened: false,
    type: TypeAlert.Info,
    message: 'Text message',
    icon: 'info'
  };
}

export const globalState: IGlobalState = {
  authUser: null,
  currentLocale: null,
  drawer: true,
  globalConfig: {
    game: null,
    listCentre: [],
    listCountry: []
  },
  isLoading: true,
  isRegisting: false,
  lastRoutePath: null,
  layout: Layout.Home,
  signInErrorMessage: null,
  topAlert: AlertState(),
  breakcumb: [],
  firstLogin: false
};

export const mutations = {
  // init
  [MutationType.SetCurrentLocale](state: IState, locale: string) {
    state.global.currentLocale = locale;
    i18n().locale = locale;
  },
  [MutationType.ClosePlashScreen](state: IState) {
    state.global.isLoading = false;
  },
  [MutationType.OpenPlashScreen](state: IState) {
    state.global.isLoading = true;
  },
  [MutationType.SetLayout](state: IState, layout: Layout) {
    state.global.layout = layout;
  },
  [MutationType.SetGlobalConfig](state: IState, globalConfig: IGlobalConfiguration) {
    state.global.globalConfig = globalConfig;
  },
  [MutationType.OpenTopAlert](state: IState, topAlert: IAlertState) {
    state.global.topAlert = {
      ...topAlert,
      opened: true
    };
  },
  [MutationType.CloseTopAlert](state: IState) {
    state.global.topAlert = AlertState();
  },
  // login
  [MutationType.Authenticated](state: IState, user) {
    state.global.authUser = user;
  },
  [MutationType.Unauthenticated](state: IState) {
    state.global.authUser = null;
    state.global.lastRoutePath = state.route.path;
  },
  [MutationType.SetFirstLogin](state: IState) {
    state.global.firstLogin = true;
  },
  [MutationType.ClearFirstLogin](state: IState) {
    state.global.firstLogin = false;
  },
  // [MutationType.SetProfile](state: IState, user) {
    // state.global.authUser = user;
  // },
  [MutationType.SetRegisting](state: IState) {
    state.global.isRegisting = true;
  },
  [MutationType.SetRegisted](state: IState) {
    state.global.isRegisting = false;
  },
  [MutationType.BreakcumbChange](state: IState, payload: IBreakcumb[]) {
    state.global.breakcumb = payload;
  },

  [MutationType.ClearStore](state: IState) {
    const _state = omit(defaultValue(state), ['global', 'route']);
    Object.keys(_state).forEach((key) => {
      state[key] = {
        ..._state[key],
        pagination: {
          size: 20,
          page: 1
        }
      };
    });
    state.game = {
      ...state.game,
      progressiveConfig: progressiveConfigDefault(),
      speedConfig: speedConfigDefault(),
      flashConfig: flashConfigDefault(),
    };
  }
};
