import { IUser, IUserRegisterForm } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IUserHttpService
  extends ICRUDHttpService<IUser, IUserRegisterForm> {
  changePassword(id: string | number, newPassword: string): Promise<any>;
  userPicker(): Promise<IUser[]>;
  getUserDetailById(id: number): Promise<IUser>;
}

export function UserHttpService(options: IHttpServiceClientOptions): IUserHttpService {

  const basePath = '/users';
  const baseURL = options.httpService.getUrl(basePath, options.mock);
  // const baseURL = options.httpService.getUrl(basePath, true);
  const userPickerUrl = options.httpService.getUrl(`${basePath}/picker`, options.mock);

  function changePassword(id: string | number, newPassword: string): Promise<any> {
    return options.httpService.put(`${baseURL}/${id}/password`, {
      newPassword
    });
  }

  function userPicker(): Promise<IUser[]> {
    return options.httpService.get(userPickerUrl);
  }

  function getUserDetailById(id: number): Promise<IUser> {
    return options.httpService.get(`${baseURL}/${id}`);
  }

  return {
    ...CRUDHttpService<IUser, IUserRegisterForm>({
      ...options,
      basePath
    }),
    changePassword,
    userPicker,
    getUserDetailById
  };
}
