import { isFunction } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { ActionType, IState } from 'root/store';
import { ActionContext } from 'vuex';
import { IHomeState } from './mutations';
import { ActionTypeHome, MutationTypeHome } from './types';
export const actions = {
  async [ActionTypeHome.GetAboutUs](
    { dispatch, commit}: ActionContext<IHomeState, IState>
  ) {
    commit(MutationTypeHome.SetAboutUsLoading);
    try {
      const data = await cmaHttpClient.wordpress.getAboutUs();
      commit(MutationTypeHome.SetAboutUs, data.metadata);
      commit(MutationTypeHome.SetAboutUsLoaded);
    } catch (error) {
      commit(MutationTypeHome.SetAboutUsLoaded);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeHome.GetTestimonials](
    { dispatch, commit}: ActionContext<IHomeState, IState>
  ) {
    commit(MutationTypeHome.SetTestimonialsLoading);
    try {
      const data = await cmaHttpClient.wordpress.getTestimonials();
      commit(MutationTypeHome.SetTestimonials, data);
      commit(MutationTypeHome.SetTestimonialsLoaded);
    } catch (error) {
      commit(MutationTypeHome.SetTestimonialsLoaded);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeHome.GetArticles](
    { dispatch, commit}: ActionContext<IHomeState, IState>, {
      type, pagination, onSuccess, onFailure, isList
    }) {
    commit(MutationTypeHome.SetArticlesLoading);
    try {
      const data = await cmaHttpClient.wordpress.getArticles(type, pagination);
      if (isFunction(onSuccess)) {
        onSuccess(data);
      }
      commit(MutationTypeHome.SetArticlesLoaded);
      if (isList) {
        commit(MutationTypeHome.SetArticlesMore, data);
      } else {
        commit(MutationTypeHome.SetArticles, data);
      }
    } catch (error) {
      if (isFunction(onFailure)) {
        onFailure();
      }
      commit(MutationTypeHome.SetArticlesLoaded);
      dispatch(ActionType.CatchException, error);
    }
  }
};
