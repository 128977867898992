import { cloneDeep } from 'lodash';
import { auralConfigDefault, flashConfigDefault, gameConfigLocal,
  progressiveConfigDefault, IAuralConfig, IFlashConfig,
  IProgressiveConfig, ISpeedConfig, MethodType, OperatorTab } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Scrolly, ScrollyBar, ScrollyViewport } from 'vue-scrolly';
import { mapState } from 'vuex';
import { MutationTypeGame } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    Scrolly,
    ScrollyViewport,
    ScrollyBar,
  },
  props: {
    dialogVisible: Boolean,
    methodType: String,
  },
  computed: {
    ...mapState({
      config: (state: IState) => state.game.progressiveConfig,
      speedConfig: (state: IState) => state.game.speedConfig,
      flashConfig: (state: IState) => state.game.flashConfig,
      auralConfig: (state: IState) => state.game.auralConfig,
    }),
    activeTab() {
      // const config: IProgressiveConfig = this.methodType === MethodType.Progressive ? this.config : this.flashConfig;
      switch (this.methodType) {
      case MethodType.Progressive:
        return this.config.operatorTab;
      case MethodType.Flash:
        return this.flashConfig.operatorTab;
      case MethodType.AuralMental:
        return this.auralConfig.operatorTab;
      default:
        break;
      }
    }
  }
})
export class GameConfig extends Vue {

  public config: IProgressiveConfig;
  public speedConfig: ISpeedConfig;
  public flashConfig: IFlashConfig;
  public auralConfig: IAuralConfig;
  public tabs = [
    OperatorTab.Addition,
    OperatorTab.AddtionAndSubtraction,
    OperatorTab.Multiplication,
    OperatorTab.Division
  ];
  public activeTab: OperatorTab;
  public methodType: MethodType;
  public methodTypeProgressive = MethodType.Progressive;
  public methodTypeSpeed = MethodType.SpeedChallenge;
  public methodTypeFlash = MethodType.Flash;
  public methodTypeAural = MethodType.AuralMental;
  public firstTime: boolean = true;

  public form: any = progressiveConfigDefault();

  public get firstDigitRule(): boolean {
    const form = this.form;

    if (this.activeTab === this.tabs[2]) {
      return form.configDigit && form.configDigit.first === 1;
    }

    return form.configDigit && (form.configDigit.first === 1 || form.configDigit.first <= form.configDigit.second);
  }

  public get secondDigitRule(): boolean {
    const form = this.form;

    if (this.activeTab === this.tabs[2]) {
      return form.configDigit && form.configDigit.second === 10;
    }

    return form.configDigit && (form.configDigit.second >= 10 || form.configDigit.second >= form.configDigit.first);
  }

  public addQuestion() {
    const form = cloneDeep(this.form);
    let question = form.numberQuestion;

    if (question < 10) {
      question++;
    } else if (question >= 10) {
      question = question + 5;
    }
    if (question > 99) {
      question = 99;
    }

    this.form.numberQuestion = question;
  }
  public subQuestion() {
    const form = cloneDeep(this.form);
    let question = form.numberQuestion;

    if (question <= 10) {
      question--;
    } else if (question >= 96) {
      question = 95;
    } else if (question > 10) {
      question = question - 5;
    }
    if (question < 1) {
      question = 1;
    }

    this.form.numberQuestion = question;
  }

  public clickTab(model: OperatorTab) {
    if (model === this.activeTab) {
      return;
    }
    if (this.methodType === MethodType.Progressive) {
      this.$store.commit(MutationTypeGame.ProgressiveConfigSetTab, model);
      this.form = progressiveConfigDefault(model);
    } else if (this.methodType === MethodType.Flash) {
      this.$store.commit(MutationTypeGame.FlashConfigSetTab, model);
      this.form = flashConfigDefault(model);
    } else {
      this.$store.commit(MutationTypeGame.AuralConfigSetTab, model);
      this.form = auralConfigDefault(model);
    }
  }

  public closeDialog() {
    if (this.methodType === MethodType.Progressive) {
      const progressiveConfigLocal = localStorage.getItem(gameConfigLocal.progressiveConfigLocal);
      if (progressiveConfigLocal) {
        const progressiveConfig = JSON.parse(progressiveConfigLocal);
        this.$store.commit(MutationTypeGame.ProgressiveSetConfig, progressiveConfig);
      }
    } else if (this.methodType === MethodType.SpeedChallenge) {
      const speedConfigLocal = localStorage.getItem(gameConfigLocal.speedConfigLocal);
      if (speedConfigLocal) {
        const speedConfig = JSON.parse(speedConfigLocal);
        this.$store.commit(MutationTypeGame.SpeedSetConfig, speedConfig);
      }
    } else if (this.methodType === MethodType.AuralMental) {
      const auralConfigLocal = localStorage.getItem(gameConfigLocal.auralMentalConfigLocal);
      if (auralConfigLocal) {
        const auralConfig = JSON.parse(auralConfigLocal);
        this.$store.commit(MutationTypeGame.AuralSetConfig, auralConfig);
      }
    }
    if (this.firstTime) {
      this.$emit('cancle');
    }
    this.$emit('close');
  }
  public openDialog() {
    switch (this.methodType) {
    case MethodType.SpeedChallenge:
      this.form = cloneDeep(this.speedConfig);
      switch (this.form.speed) {
      case 540:
        this.form.speed = 0;
        break;
      case 360:
        this.form.speed = 50;
        break;
      case 180:
        this.form.speed = 100;
        break;
      default:
        break;
      }
      break;
    case MethodType.Flash:
      this.form = cloneDeep(this.flashConfig);
      break;
    case MethodType.AuralMental:
      this.form = cloneDeep(this.auralConfig);
      break;
    default:
      this.form = cloneDeep(this.config);
      break;
    }
  }

  public submit() {
    if (this.methodType === MethodType.SpeedChallenge) {
      switch (this.form.speed) {
      case 0:
        this.form.speed = 540;
        break;
      case 50:
        this.form.speed = 360;
        break;
      case 100:
        this.form.speed = 180;
        break;
      default:
        break;
      }
      this.$store.commit(MutationTypeGame.SpeedSetConfig, this.form);
    } else if (this.methodType === MethodType.Progressive) {
      this.$store.commit(MutationTypeGame.ProgressiveSetConfig, this.form);
    } else if (this.methodType === MethodType.Flash) {
      this.$store.commit(MutationTypeGame.FlashSetConfig, this.form);
    } else if (this.methodType === MethodType.AuralMental) {
      this.$store.commit(MutationTypeGame.AuralSetConfig, this.form);
    }
    this.$emit('apply');
    this.firstTime = false;
  }

}
