import { ICentre } from './Board';
import { phoneCodeDefault, Gender, IPhoneInput } from './Common';

export interface IUserRegisterForm {
  email: string;
  phone?: IPhoneInput;
  birthday: Date;
  gender: Gender;
  address: string;
  postalCode: number;
  password: string;
  retypePassword: string;
  avatar?: string;
  centre: ICentre;
  fullName: string;
}

export const userRegisterFormDefault = (): IUserRegisterForm => {
  return {
    email: '',
    address: '',
    birthday: undefined,
    gender: undefined,
    phone: {
      code: phoneCodeDefault,
      number: ''
    },
    postalCode: null,
    password: '',
    retypePassword: '',
    avatar: '',
    centre: null,
    fullName: ''
  };
};

export interface IUser extends IUserRegisterForm {
  id: string | number;
  level: number;
  point: number;
  subscription?: any;
}

export const userDefault = (): IUser => {
  return {
    ...userRegisterFormDefault(),
    id: null,
    level: null,
    point: null
  };
};
export interface IFeedbackForm {
  subject: string;
  message: string;
}
export function feedbackFormDefault(): IFeedbackForm {
  return {
    subject: '',
    message: ''
  };
}

export interface IUserCacheLocal {
  fullName: string;
  id: string;
  avatar: string;
  token: string;
}
