import { Carousel } from 'element-ui';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeHome } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.home.testimonials,
      loading: (state: IState) => state.home.testimonialsLoading
    })
  }
})
export class HomeQuote extends Vue {
  public activeSlider: number = 0;
  public activeTab: number = 0;
  public data: any[];
  public visible: boolean = false;
  public detail: any = null;

  public $refs: {
    carousel: Carousel
  };

  public get _posts(): any[] {
    return this.data[this.activeTab] ? this.data[this.activeTab].posts : [];
  }

  public changeSlider(index: number) {
    this.activeSlider = index;
    this.$refs.carousel.setActiveItem(index);
  }
  public changeTab(index: number) {
    this.activeSlider = 0;
    this.activeTab = index;
  }

  public readMore(model: any) {
    this.visible = true;
    this.detail = model;
  }
  public beforeOpen() {
    //
  }
  public handleClickCloseDialog() {
    this.visible = false;
    this.detail = null;
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeHome.GetTestimonials);
    });
  }
}
