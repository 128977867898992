import { BonusTypes, IBonusGame, IGameHistory, IGameMapResult, IProgressive, IProgressiveConfig } from 'root/models';
import { IHttpServiceClientOptions } from '../Core';

export interface IGameHttpService {
  GetProgressiveGame(numberQuestion: number, config: IProgressiveConfig): Promise<IProgressive[]>;
  GetGameByLevel(level: number): Promise<IProgressive[]>;
  GetBonusGame(type: BonusTypes): Promise<IBonusGame>;
  CreateGameHistory(form: IGameHistory): Promise<IGameHistory>;
  GetGameMap(): Promise<IGameMapResult>;
}

export function GameHttpService(options: IHttpServiceClientOptions): IGameHttpService {

  const baseUrl = '/games';

  function GetProgressiveGame(numberQuestion: number, config: IProgressiveConfig): Promise<IProgressive[]> {
    const progressiveUrl = options.httpService.getUrl(`${baseUrl}/progressive`, options.mock);

    return options.httpService.post(progressiveUrl, {
      numberQuestion,
      config
    });
  }
  function GetGameByLevel(level: number): Promise<IProgressive[]> {
    const abacusUrl = options.httpService.getUrl(`${baseUrl}/levels/${level}`, options.mock);

    return options.httpService.get(abacusUrl);
  }

  function GetBonusGame(type: BonusTypes): Promise<IBonusGame> {
    const bonusUrl = options.httpService.getUrl(`${baseUrl}/bonus/${type}`, options.mock);

    return options.httpService.get(bonusUrl);
  }

  function CreateGameHistory(form: IGameHistory): Promise<IGameHistory> {
    const historyUrl = options.httpService.getUrl(`${baseUrl}/histories`, options.mock);

    return options.httpService.post(historyUrl, form);
  }

  function GetGameMap(): Promise<IGameMapResult> {
    const mapUrl = options.httpService.getUrl(`${baseUrl}/levels/maps`, options.mock);

    return options.httpService.get(mapUrl);
  }

  return {
    GetProgressiveGame,
    GetGameByLevel,
    GetBonusGame,
    CreateGameHistory,
    GetGameMap
  };
}
